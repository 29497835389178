/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutingModule } from '../../app-routing.module';
import { HttpClient } from '@angular/common/http';
import { Regex } from '../../../model/Regex';
import { DataLogin } from './data-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public data_login = new DataLogin();

  constructor(
    public app: AppRoutingModule,
    public router: Router,
    public http: HttpClient
  ) {}

  ngOnInit(): void {
    this.app.check_session('home');
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }

  onSubmit(): void {
    this.app.invalid_login = false;
    if (
      this.data_login.required() &&
      Regex.regex_pwd(this.data_login.password)
    ) {
      this.app.remember_me_check = (
        document.getElementById('remember_me')! as HTMLInputElement
      ).checked;
      this.app.login(this.data_login.password, this.data_login.user, 'home');
    } else {
      this.app.invalid_login = true;
      this.data_login.user = '';
      this.data_login.password = '';
    }
  }
}
