import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ClientOptions,
  FlowControl,
  XtermComponent,
  XtermOptions,
} from '../xterm/xterm.component';
import { ITerminalOptions, ITheme } from 'xterm';
import { HomeComponent } from 'src/app/home/home.component';
import { Command } from 'src/app/dashboard-menu/command';

const clientOptions = {
  rendererType: 'webgl',
  disableLeaveAlert: false,
  disableResizeOverlay: false,
  enableZmodem: false,
  enableTrzsz: false,
  enableSixel: false,
  isWindows: false,
} as ClientOptions;

const termOptions = {
  fontSize: 13,
  fontFamily: 'Consolas,Liberation Mono,Menlo,Courier,monospace',
  theme: {
    foreground: '#d2d2d2',
    background: '#2b2b2b',
    cursor: '#adadad',
    black: '#000000',
    red: '#d81e00',
    green: '#5ea702',
    yellow: '#cfae00',
    blue: '#427ab3',
    magenta: '#89658e',
    cyan: '#00a7aa',
    white: '#dbded8',
    brightBlack: '#686a66',
    brightRed: '#f54235',
    brightGreen: '#99e343',
    brightYellow: '#fdeb61',
    brightBlue: '#84b0d8',
    brightMagenta: '#bc94b7',
    brightCyan: '#37e6e8',
    brightWhite: '#f1f1f0',
  } as ITheme,
  allowProposedApi: true,
} as ITerminalOptions;

const flowControl = {
  limit: 100000,
  highWater: 10,
  lowWater: 4,
} as FlowControl;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss'],
})
export class TerminalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() host: string = '';
  @Input() port: number = 0;
  @Input() username: string = '';
  @Input() password: string = '';
  @Input() device_sn: string = '';
  @Input() sfxPublishTopic: string = '';
  @Input() sfxSubscribeTopic: string = '';
  @Input() pfxTopic: string = '';
  @Input() enableXtea: boolean = true;

  @Input() name: string = '';
  @Input() target: string = '';

  public menu_string_option: string[] = ['upload', 'download'];
  public menu_options: boolean[] = [false, false];
  public is_active: boolean[] = [false, false];
  public menu_icons: string[] = [
    '../../../../assets/icon/advanced/upload.svg',
    '../../../../assets/icon/advanced/download.svg',
  ];
  private _options: XtermOptions = {
    host: '',
    port: 0,
    username: '',
    password: 't',
    device_sn: '',
    sfxPublishTopic: '',
    sfxSubscribeTopic: '',
    pfxTopic: '',
    enableXtea: true,
    flowControl: flowControl,
    clientOptions: clientOptions,
    termOptions: termOptions,
  };

  private _xterm?: XtermComponent;
  public get xterm() {
    return this._xterm;
  }

  @ViewChild('terminalcontainer', { static: true })
  private container?: ElementRef;

  public console_available: Boolean = true;

  constructor(public home: HomeComponent) {
    this.consoleAvailable();
  }
  ngOnInit(): void {
    this._options.host = this.host;
    this._options.port = this.port;
    this._options.username = this.username;
    this._options.password = this.password;
    this._options.device_sn = this.device_sn;
    this._options.sfxPublishTopic = this.sfxPublishTopic;
    this._options.sfxSubscribeTopic = this.sfxSubscribeTopic;
    this._options.pfxTopic = this.pfxTopic;
    this._options.enableXtea = this.enableXtea;

    this._xterm = new XtermComponent(this._options);
  }

  ngAfterViewInit(): void {
    this._xterm?.open(this.container!.nativeElement);
    this._xterm?.connect();
  }

  ngOnDestroy(): void {
    this._xterm?.dispose();
  }

  public command(command: Command = new Command('', [false, false])) {
    this.is_active = command.active;
    switch (command.command) {
      case 'upload':
        break;
      case 'download':
        break;
    }
  }

  public consoleAvailable() {
    let w = window.innerWidth;
    if (w <= 1200) {
      this.console_available = false;
    } else {
      this.console_available = true;
    }
  }
}
