<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->
<div class="menu_block">
  <div id="toggle" class="openclose">
    <a (click)="this.nav()"
      ><img
        class="icon"
        src="../../assets/icon/home-menu/toggle_menu.png"
        alt=""
        srcset=""
    /></a>
  </div>
  <div
    id="menu"
    class="openclose"
    (window:resize)="this.menu_resize_big_screen()"
  >
    <div id="logo">
      <img src="../../assets/logo-archimede-b.png" alt="" />
    </div>
    <div style="height: 100px"></div>

    <br />
    <!-- first menu -->
    <div id="first_menu" class="horizontal_content_visible" style="float: left">
      <app-home-menu-button
        [button_id]="'device_list'"
        [class]="'menu_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [is_active]="this.home.component.types[1]"
        [label]="'Device list'"
        [icon_src]="'../../assets/icon/home-menu/device_list.png'"
        (click)="this.change_menu('device_options')"
        (click)="this.component.emit('device_list')"
      ></app-home-menu-button>
      <app-home-menu-button
        [button_id]="'service'"
        [class]="'menu_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [label]="'Service'"
        [icon_src]="'../../assets/icon/home-menu/service.png'"
      ></app-home-menu-button>
      <app-home-menu-button
        [button_id]="'settings'"
        [class]="'menu_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [label]="'Settings'"
        [icon_src]="'../../assets/icon/home-menu/settings.svg'"
        (click)="this.change_menu('settings')"
        (click)="this.component.emit('settings')"
      ></app-home-menu-button>
      <app-home-menu-button
        *ngIf="this.home.is_developer"
        [button_id]="'admin_area'"
        [class]="'menu_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [label]="'Admin area'"
        [icon_src]="'../../assets/icon/home-menu/key.svg'"
        (click)="this.change_menu('admin_area')"
        (click)="this.component.emit('admin_area')"
      ></app-home-menu-button>
    </div>
    <!--  -->
    <!-- preferences options -->
    <div
      id="preferences_options"
      class="horizontal_content_collapsed"
      style="float: right"
    >
      <app-home-menu-button
        [button_id]="'back'"
        [class]="'hidden_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [label]="'Back'"
        [icon_src]="'../../assets/icon/home-menu/arrow_back.svg'"
        (click)="this.change_menu('back')"
      ></app-home-menu-button>
      <app-home-menu-button
        [button_id]="'profile'"
        [class]="'hidden_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [is_active]="this.home.component.types[4]"
        [label]="'Profile'"
        [icon_src]="'../../assets/icon/home-menu/account icon.svg'"
        (click)="this.component.emit('profile')"
      ></app-home-menu-button>

      <app-home-menu-button
        [button_id]="'change_password'"
        [class]="'hidden_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [is_active]="this.home.component.types[5]"
        [label]="'Change password'"
        [icon_src]="'../../assets/icon/home-menu/lock icon.svg'"
        (click)="this.component.emit('password')"
      ></app-home-menu-button>
    </div>
    <!--  -->
    <!-- Device list -->
    <div
      id="device_options"
      class="horizontal_content_collapsed"
      style="float: right"
    >
      <app-home-menu-button
        [button_id]="'back2'"
        [class]="'hidden_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [label]="'Back'"
        [icon_src]="'../../assets/icon/home-menu/arrow_back.svg'"
        (click)="this.change_menu('back2')"
        (click)="this.component.emit('home')"
      ></app-home-menu-button>
    </div>
    <!--  -->
    <!-- admin_area_options -->
    <div
      id="admin_area_options"
      class="horizontal_content_collapsed"
      style="float: right"
    >
      <app-home-menu-button
        [button_id]="'back4'"
        [class]="'hidden_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [label]="'Back'"
        [icon_src]="'../../assets/icon/home-menu/arrow_back.svg'"
        (click)="this.change_menu('back4')"
        (click)="this.component.emit('home')"
      ></app-home-menu-button>
      <app-home-menu-button
        [button_id]="'log_access'"
        [class]="'hidden_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [is_active]="this.home.admin_tools.types[0]"
        [label]="'Log access'"
        [icon_src]="'../../assets/icon/home-menu/log_access.svg'"
        (click)="this.component.emit('log_access')"
      ></app-home-menu-button>
      <app-home-menu-button
        [button_id]="'server_console'"
        [class]="'hidden_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [is_active]="this.home.admin_tools.types[1]"
        [label]="'Server console'"
        [icon_src]="'../../assets/icon/advanced/console_white.svg'"
        (click)="this.component.emit('server_console')"
      ></app-home-menu-button>
    </div>
    <!--  -->
    <!-- battery details -->
    <div
      id="battery_details"
      class="horizontal_content_collapsed"
      style="float: right"
    >
      <app-home-menu-button
        [button_id]="'back1'"
        [class]="'hidden_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [label]="'Back'"
        [icon_src]="'../../assets/icon/home-menu/arrow_back.svg'"
        (click)="this.change_menu('back1')"
        (click)="this.component.emit('device_list')"
      ></app-home-menu-button>
      <app-home-menu-button
        [button_id]="'b_battery_details'"
        [class]="'hidden_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [is_active]="this.home.component.types[3]"
        [label]="'Battery details'"
        [icon_src]="
          '../../assets/icon/battery-details/minus.plus.batteryblock.fill.svg'
        "
        (click)="this.component.emit('battery_details')"
      ></app-home-menu-button>
      <app-home-menu-button
        [button_id]="'advanced'"
        [class]="'hidden_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [is_active]="this.home.component.types[2]"
        [label]="'Advanced'"
        [icon_src]="'../../assets/icon/home-menu/shield.png'"
        (click)="this.component.emit('advanced')"
      ></app-home-menu-button>
    </div>
    <!--  -->
    <!-- advanced tools -->
    <div
      id="advanced_tools"
      class="horizontal_content_collapsed"
      style="float: right"
    >
      <app-home-menu-button
        [button_id]="'back3'"
        [class]="'hidden_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [label]="'Back'"
        [icon_src]="'../../assets/icon/home-menu/arrow_back.svg'"
        (click)="this.change_menu('back3')"
        (click)="this.component.emit('advanced')"
      ></app-home-menu-button>
      <app-home-menu-button
        [button_id]="'device_settings'"
        [class]="'hidden_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [is_active]="this.home.advanced_tools.types[2]"
        [label]="'Device settings'"
        [icon_src]="'../../assets/icon/advanced/parameter_white.svg'"
        (click)="this.component.emit('device_settings')"
      ></app-home-menu-button>
      <app-home-menu-button
        [button_id]="'console'"
        [class]="'hidden_button'"
        [background_color]="'#565556'"
        [hover_background_color]="'#6E6D6F'"
        [is_active]="this.home.advanced_tools.types[1]"
        [label]="'Console'"
        [icon_src]="'../../assets/icon/advanced/console_white.svg'"
        (click)="this.component.emit('console')"
      ></app-home-menu-button>
    </div>
    <!--  -->
    <table class="user_table_info">
      <tr>
        <td class="user_info">
          <div class="circle_initials">{{ this.home.user_initials }}</div>
        </td>
      </tr>
      <tr>
        <td class="user_info name_td">{{ this.home.user_name }}</td>
      </tr>
      <tr>
        <td class="user_info mail_td">{{ this.home.user_email }}</td>
      </tr>
      <tr>
        <td class="user_info">
          <div
            class="user_type"
            [ngStyle]="{ 'background-color': this.backgroundUser() }"
            (click)="this.view_user_setup = true"
          >
            {{ this.home.user_type.toUpperCase() }}
          </div>
        </td>
      </tr>
    </table>

    <button class="logout" (click)="this.app.logout()">
      <img
        class="menu_icon_logout"
        src="../../assets/icon/home-menu/logout.png"
        alt=""
      />Log out
    </button>
    <label class="version">{{ this.app.version }}</label>
  </div>
</div>
<app-advanced-user
  [view_option]="this.view_user_setup"
  (close)="this.view_user_setup = $event"
  (user)="this.home.user_type = $event"
></app-advanced-user>
