/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class Redirect {
  constructor(public router: Router, public http: HttpClient) {}

  //http://localhost:4200/email_validation
  public email_validation() {
    const url = location.href;
    if (url.includes('email_validation')) {
      return true;
    }
    return false;
  }

  //http://localhost:4200/forgot_password
  public forgot_password() {
    const url = location.href;
    if (url.includes('forgot_password')) {
      this.router.navigate(['/forgot_password']);
      return true;
    }
    return false;
  }
  //http://localhost:4200/register
  public register() {
    const url = location.href;
    if (url.includes('register')) {
      this.router.navigate(['/register']);
      return true;
    }
    return false;
  }
  //http://localhost:4200/change_password&email=email&token=token
  public change_password() {
    const url = location.href;
    if (url.includes('change_password')) {
      if (!url.includes('email=') || !url.includes('token=')) {
        return false;
      }
      var start_pos_email = url.search('email=') + 6;
      var start_pos_token = url.search('token=') + 6;

      sessionStorage.setItem(
        'change_password_email',
        url.substring(start_pos_email, start_pos_token - 7)
      );
      sessionStorage.setItem(
        'change_password_token',
        url.substring(start_pos_token)
      );
      this.router.navigate(['/change_password']);
      return true;
    }
    return false;
  }
  //http://localhost:4200/account_delete
  public account_delete() {
    const url = location.href;
    if (url.includes('account_delete')) {
      this.router.navigate(['/account_delete']);
      return true;
    }
    return false;
  }

  //http://localhost:4200/delete_end
  public delete_end() {
    const url = location.href;
    if (url.includes('delete_end')) {
      this.router.navigate(['/delete_end']);
      return true;
    }
    return false;
  }
}
