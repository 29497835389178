import { Buffer } from 'buffer';
import { uInt32 } from 'uint';

const XTDELTA = 0x9e3779b9;
const ROUNDS = 32;

function xtea_crypt(w: Buffer, v: Buffer, k: Buffer): void {
    let y: uInt32;
    let z: uInt32;
    let sum = 0;
    const delta = XTDELTA;
    let n = ROUNDS;

    y = v.readUInt32LE(0);
    z = v.readUInt32LE(4);

    while (n-- > 0) {
        y += (((z << 4) ^ (z >>> 5)) + z) ^ (sum + k.readUInt32LE((sum & 3) * 4));
        y = y >>> 0;
        sum = (sum + delta) >>> 0;
        z += (((y << 4) ^ (y >>> 5)) + y) ^ (sum + k.readUInt32LE(((sum >> 11) & 3) * 4));
        z = z >>> 0;
    }

    w.writeUInt32LE(y, 0);
    w.writeUInt32LE(z, 4);
}

function xtea_decrypt(w: Buffer, v: Buffer, k: Buffer): void {
    let y: uInt32;
    let z: uInt32;
    let sum = 0xc6ef3720;
    const delta = XTDELTA;
    let n = ROUNDS;

    y = v.readUInt32LE(0);
    z = v.readUInt32LE(4);

    while (n-- > 0) {
        z -= (((y << 4) ^ (y >>> 5)) + y) ^ (sum + k.readUInt32LE(((sum >> 11) & 3) * 4));
        z = z >>> 0;
        sum = (sum - delta) >>> 0;
        y -= (((z << 4) ^ (z >>> 5)) + z) ^ (sum + k.readUInt32LE((sum & 3) * 4));
        y = y >>> 0;
    }

    w.writeUInt32LE(y, 0);
    w.writeUInt32LE(z, 4);
}

export function xtea_normalize_buf(src: Buffer): Buffer {
    const length = src.length;
    const pad = 8 - (length & 7);

    const ptr = Buffer.alloc(length + pad);
    src.copy(ptr, 0, 0, length);

    return ptr;
}

export function xtea_crypt_buf(dst: Buffer, src: Buffer, pass: Buffer): void {
    let l: number = pass.length;
    const k: Buffer = Buffer.alloc(16);

    if (l > k.length) {
        l = k.length;
    }
    pass.copy(k, 0, 0, l);

    let i = 0;
    l = src.length;

    while (i < l) {
        const srcChunk: Buffer = src.slice(i, i + 8);
        const dstChunk: Buffer = Buffer.alloc(8);
        xtea_crypt(dstChunk, srcChunk, k);
        dstChunk.copy(dst, i, 0, 8);
        i += 8;
    }
}

export function xtea_decrypt_buf(dst: Buffer, src: Buffer, pass: Buffer): void {
    let l: number = pass.length;
    const k: Buffer = Buffer.alloc(16);

    if (l > k.length) {
        l = k.length;
    }
    pass.copy(k, 0, 0, l);
    let i = 0;
    l = src.length;
    while (i < l) {
        const dstChunk: Buffer = Buffer.alloc(8);
        const srcChunk: Buffer = Buffer.alloc(8);
        src.copy(srcChunk, 0, i, i + 8);
        xtea_decrypt(dstChunk, srcChunk, k);
        dstChunk.copy(dst, i, 0, 8);
        i += 8;
    }
}
