/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { Data_registration } from 'src/app/public_component/registration/data_registration';
import { Regex } from 'src/model/Regex';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css'],
})
export class PasswordComponent implements OnInit {
  /**
   * required: 0 current_password 1 new_password 2 repeat_new_password
   */
  /**
   * unrequired: ''
   */

  public data_registration = new Data_registration(
    ['old_password', 'new_password', 'password_c'],
    [],
    this.http,
    this.app
  );

  constructor(public app: AppRoutingModule, public http: HttpClient) {}

  ngOnInit(): void {}

  private get_message(): string[] {
    let message: string[] = [];

    message.push(`• Password lenght is min 8, max 20.
                    You can use letters, numbers and this special characters $ @ ! % * # ? & 
                    Password must contain at least 1 special characters, 1 numbers, 1 lowercase letter and 1 uppercase letter.`);

    return message;
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }

  onSubmit(): void {
    this.data_registration.warning = false;
    if (
      !this.data_registration.required() &&
      !this.data_registration.password_confirmation_bool &&
      Regex.regex_pwd(this.data_registration.data_required[1])
    ) {
      this.http
        .post<any>(this.app.server_domain + '/user_setting.php', {
          request: 'change_password',
          token: this.app.token,
          old_password: this.data_registration.data_required[0],
          new_password: this.data_registration.data_required[1],
        })
        .subscribe(
          (result) => {
            console.log(result);
            if (result.length > 1) {
              this.app.token = result[1];
              sessionStorage.setItem('token', JSON.stringify(result[1]));
              sessionStorage.setItem(
                'password',
                this.data_registration.data_required[1]
              );
              if (this.app.remember_me_check) {
                localStorage.setItem(
                  'password',
                  this.data_registration.data_required[1]
                );
              }
            }
            if (!result[0].response) {
              this.data_registration.warning_message = [result[0].message];
            } else {
              this.app.logout();
            }
            this.data_registration.warning = true;
          },
          (error) => {
            console.error(error);
          }
        );
    } else {
      this.data_registration.warning_message = this.get_message();
      this.data_registration.warning = true;
    }
  }
}
