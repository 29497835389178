<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->
<div>
  <a
    *ngFor="let icon of this.icons; let i = index"
    class="split"
    (click)="this.click(i)"
    [ngStyle]="{
      background:
        this.is_active[i] == true && this.mark_type == 'back'
          ? this.mark_color
          : this.background_color,
      margin: this.icon_margin[i],
      padding: this.icon_padding[i],
      'border-radius': this.border_radius,
      'border-bottom':
        this.is_active[i] == true && this.mark_type == 'bottom'
          ? this.mark_color
          : ''
    }"
    ><img
      class="icon"
      src="{{ icon }}"
      [ngStyle]="{ width: this.icon_width[i], height: this.icon_height[i] }"
      alt=""
      srcset=""
  /></a>
</div>
<div *ngIf="this.view_labels">
  <a *ngFor="let icon of this.icons; let i = index" class="split"
    ><label
      class="label"
      [ngStyle]="{
        width: this.labels_width[i],
        height: this.labels_height[i]
      }"
      >{{ this.view_labels ? this.labels[i] : "" }}</label
    ></a
  >
</div>
