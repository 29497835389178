<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
-->
<app-dashboard-menu
  class="dashboard_menu"
  [mark_color]="'#50a17c'"
  [button_active]="'multi'"
  [string_buttons]="this.menu_string_option"
  [view_labels]="true"
  [icon_width]="['29px']"
  [icon_height]="['29px']"
  [icon_margin]="['-2px 0px 0px 0px']"
  [icon_padding]="['0px 0px 0px 0px']"
  [buttons]="this.menu_options"
  [icons]="this.menu_icons"
  [is_active]="[this.b_list.sync]"
  (response)="this.command($event)"
></app-dashboard-menu>
<div class="fixed_table">
  <table class="list">
    <tr>
      <th colspan="3">Device information</th>
    </tr>
    <tr>
      <td colspan="3">
        <google-map
          width="100%"
          height="300px"
          [center]="this.map.center"
          [zoom]="this.map.zoom"
        >
          <map-marker
            *ngFor="let marker of this.map.markers"
            [position]="marker.position"
            [label]="marker.label"
            [title]="marker.title"
            [options]="marker.options"
          >
          </map-marker>
        </google-map>
      </td>
    </tr>
    <tr>
      <td class="td_data">Last update</td>
      <td></td>
      <td class="td_data td_right">
        {{ this.lastUpdate(this.devMan.getCurrentBattery().last_update) }}
      </td>
    </tr>
    <tr>
      <td class="td_data">Device type</td>
      <td></td>
      <td class="td_data td_right">
        {{ this.device_type() }}
      </td>
    </tr>
    <tr>
      <th colspan="3">GPIOs</th>
    </tr>
    <td
      *ngIf="this.is_empty"
      colspan="3"
      class="td_data td_center"
      style="opacity: 0.7"
    >
      No GPIOs used
    </td>
    <div *ngIf="!this.is_empty">
      <tr class="tr_title">
        <td>Name</td>
        <td>Function</td>
        <td class="td_right">Value</td>
      </tr>
      <div *ngFor="let gpio of this.gpio; let i = index">
        <tr>
          <td
            class="td_data td_subtitle"
            [ngStyle]="{
              background: i % 2 != 0 ? 'rgb(60,60,60)' : '',
              color:
                gpio.name.substring(0, 2) == 'DI'
                  ? '#e7624fff'
                  : gpio.name.substring(0, 2) == 'DO'
                  ? '#4ca0d4ff'
                  : gpio.name.substring(0, 2) == 'AI'
                  ? '#75cba1ff'
                  : '#e8ca6eff'
            }"
          >
            {{ gpio.name }}
          </td>
          <td
            class="td_data"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ gpio.alias }}
          </td>
          <td
            class="td_right"
            *ngIf="gpio.name.substring(0, 1) == 'D'"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            <label *ngIf="gpio.value == 0">LOW</label>
            <label *ngIf="gpio.value == 1">HIGH</label>
          </td>
          <td
            class="td_right"
            *ngIf="gpio.name.substring(0, 1) == 'A'"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ gpio.value.toFixed(1) }}
          </td>
        </tr>
      </div>
    </div>
  </table>
</div>
