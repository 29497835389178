<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div id="body">
  <div class="logo">
    <img src="../../assets/logo-archimede-b.png" alt="" />
  </div>
  <div class="central_block" (window:keypress)="this.keyDownFunction($event)">
    <div>
      <p class="title">Change password</p>
    </div>
    <div class="form" *ngIf="!this.end_change_password">
      <div>
        <p class="label">New password</p>
        <p class="required">*</p>
      </div>
      <input
        id="new_password"
        type="password"
        class="input"
        (input)="
          this.data_registration.parameter_required(0);
          this.data_registration.password_confirmation(
            this.data_registration.data_required[0],
            this.data_registration.data_required[1]
          );
          this.data_registration.warning = false
        "
      />
      <p class="message" *ngIf="this.data_registration.data_required_check[0]">
        This field is required
      </p>

      <div>
        <p class="label">Repeat new password</p>
        <p class="required">*</p>
      </div>
      <input
        id="password_c"
        type="password"
        class="input"
        (input)="
          this.data_registration.parameter_required(1);
          this.data_registration.password_confirmation(
            this.data_registration.data_required[0],
            this.data_registration.data_required[1]
          )
        "
      />
      <p
        class="message"
        *ngIf="
          this.data_registration.data_required_check[1] &&
          !this.data_registration.password_confirmation_bool
        "
      >
        This field is required
      </p>
      <p
        class="message"
        *ngIf="this.data_registration.password_confirmation_bool"
      >
        Password doesn't match
      </p>
      <div>
        <button class="submit" (click)="this.onSubmit()">Save</button>
      </div>
    </div>
    <div class="form" *ngIf="this.end_change_password">
      <div>
        <p class="end_label">{{ this.data_registration.warning_message }}</p>
      </div>
      <div>
        <button class="submit" (click)="this.app.goToLogin()">Go to login</button>
      </div>
    </div>
  </div>

  <app-warning
    [title]="'Server response'"
    [background_color]="this.data_registration.warning_background_color"
    [message]="this.data_registration.warning_message"
    [warning]="this.data_registration.warning"
    (response)="this.data_registration.warning = $event"
  ></app-warning>
</div>
