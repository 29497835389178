import { DeviceListComponent } from './../device-list/device-list.component';
/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppRoutingModule, Message } from '../app-routing.module';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.css'],
})
export class AddDeviceComponent implements OnInit {
  public view_warning: boolean = false;
  public warning_background_color: string = '';
  public warning_message: string = '';

  @Output() close = new EventEmitter<boolean>();
  @Input() view: boolean = false;

  constructor(
    public router: AppRoutingModule,
    public http: HttpClient,
    public device_list: DeviceListComponent
  ) {}

  ngOnInit(): void {}

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.add_divice();
    }
  }

  public add_divice() {
    this.close.emit(false);
    let sn = (
      document.getElementById('add_device_sn')! as HTMLInputElement
    ).value.trim();
    let alias = (
      document.getElementById('add_device_alias')! as HTMLInputElement
    ).value.trim();
    if (alias == '') {
      alias = 'new_device';
    }
    this.http
      .post<Message>(this.router.server_domain + '/device.php', {
        request: 'add_device',
        token: this.router.token,
        sn: sn,
        alias: alias,
      })
      .subscribe(
        (result) => {
          this.view_warning = true;
          this.warning_message = result.message;
          if (result.response) {
            this.warning_background_color = '#50a17c';
            this.device_list.get_devices_list();
            return;
          }
          this.warning_background_color = 'rgba(255, 86, 86, 0.868)';
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
