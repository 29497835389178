<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<table
  class="warning"
  [ngStyle]="{
    'background-color': this.background_color,
    display: this.warning ? 'block' : 'none'
  }"
>
  <tr>
    <td>
      <p class="warning_title">{{ this.title }}</p>
    </td>
    <td>
      <button
        class="circle"
        id="circle"
        [ngStyle]="{ 'background-color': this.background_color }"
        (click)="this.warning = false; this.response.emit(this.warning)"
      >
        <img
          class="icon_close"
          src="../../assets/icon/close.svg"
          alt=""
          srcset=""
        />
      </button>
    </td>
  </tr>
  <tr>
    <td class="warning_message">
      <p *ngFor="let message of this.message">{{ message }}</p>
    </td>
  </tr>
</table>
