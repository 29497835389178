import dayjs from 'dayjs';

export class AppDate {
  static format_day2_month2_yearnum_hour2_minute2_second2: string =
    'MM/DD/YYYY, HH:mm:ss';
  static format_day2_month2_yearnum_hour2_minute2: string = 'MM/DD/YYYY, HH:mm';
  static format_day2_monthshort_yearnum_hour2_minute2: string =
    'DD MMM YYYY, HH:mm';
  static format_day2_monthshort_yearnum: string = 'DD MMM YYYY';
  static format_day2_month2_yearnum: string = 'MM/DD/YYYY';
  static format_monthshort_yearnum: string = 'MMM YYYY';
  static format_day2_monthshort_hour2_minute2: string = 'DD MMM, HH:mm';
  static format_day2_monthshort: string = 'DD MMM';
  static format_hour2_minute2: string = 'HH:mm';
  static formatHour: string = 'HH';
  static formatWeek: string = 'ddd';
  static formatDay: string = 'DD';
  constructor() {}

  static stringFromDateFromYearToMilliseconds(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(6, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  static is_today(date: Date) {
    let format_date = AppDate.stringFromDate(
      date,
      AppDate.format_day2_month2_yearnum
    );
    let today_date = AppDate.stringFromDate(
      new Date(),
      AppDate.format_day2_month2_yearnum
    );
    if (format_date == today_date) {
      return true;
    }
    return false;
  }

  static stringFromDate(date: Date, format: string) {
    return dayjs(date).format(format);
  }
}
