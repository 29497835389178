/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HttpClient } from '@angular/common/http';
import { Input, OnChanges } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { DeviceListComponent } from '../device-list/device-list.component';

@Component({
  selector: 'app-device-options',
  templateUrl: './device-options.component.html',
  styleUrls: ['./device-options.component.css'],
})
export class DeviceOptionsComponent implements OnInit, OnChanges {
  @Input() name: string = '';
  @Input() sn: string = '';
  public title: string = '';
  public label: string = '';
  @Input() option_type_input: string = '';
  @Input() option: boolean = false;
  @Output() close = new EventEmitter<boolean>();

  public delete_active: boolean = false;

  constructor(
    public app: AppRoutingModule,
    public http: HttpClient,
    public devices_list: DeviceListComponent
  ) {}
  ngOnChanges(): void {
    this.change_view(this.option_type_input);
  }

  ngOnInit(): void {}

  public change_view(type_view: string) {
    this.option = false;
    switch (type_view) {
      case 'change':
        this.title = 'New name';
        this.label = '';
        this.option_type_input = 'change';
        let new_alias = this.get_input();
        if (new_alias != '') {
          this.change(new_alias);
          return;
        }
        break;
      case 'share':
        this.title = 'Email';
        this.label = '';
        this.option_type_input = 'share';
        let email = this.get_input();
        if (email != '') {
          this.share(email);
          return;
        }
        break;
      case 'delete':
        this.title = '';
        this.label = 'Are you sure?';
        this.option_type_input = 'delete';
        this.delete_active = true;
        break;
      default:
        this.option = true;
    }
  }

  private get_input() {
    const input = document.getElementById('option_input')! as HTMLInputElement;
    if (input != null) {
      let result = input.value;
      if (result == '') {
        this.label = 'This field is required';
      }
      return result;
    }
    return '';
  }

  private change(new_alias: string) {
    this.http
      .post<boolean>(this.app.server_domain + '/device.php', {
        request: 'device_rename',
        token: this.app.token,
        sn: this.sn,
        new_alias: new_alias,
      })
      .subscribe(
        (result) => {
          if (result) {
            this.devices_list.get_devices_list();
            this.close.emit(false);
            return;
          }
          this.label = 'Device renaming error';
        },
        (error) => {
          console.error(error);
        }
      );
  }
  private share(email: string) {
    this.http
      .post<boolean>(this.app.server_domain + '/device.php', {
        request: 'device_share',
        token: this.app.token,
        sn: this.sn,
        email: email,
      })
      .subscribe(
        (result) => {
          if (result) {
            this.close.emit(false);
            return;
          }
          this.label = 'Device sharing failed';
        },
        (error) => {
          console.error(error);
        }
      );
  }
  public delete() {
    this.http
      .post<boolean>(this.app.server_domain + '/device.php', {
        request: 'device_delete',
        token: this.app.token,
        sn: this.sn,
      })
      .subscribe(
        (result) => {
          if (result) {
            this.devices_list.get_devices_list();
            this.close.emit(false);
            return;
          }
          this.label = 'Device deletion error';
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
