/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Device_shared } from 'src/model/app.device_shared';
import { Redirect } from '../model/app.redirect';
import { HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'ArchimedeWA';
  public device_shared: Device_shared = new Device_shared(this.app, this.http);

  constructor(
    public app: AppRoutingModule,
    public router: Router,
    public http: HttpClient,
    public redirect: Redirect
  ) {
    this.device_shared.save();
    this.app.email_validation = this.redirect.email_validation();
    let temp = false;
    temp = temp || this.redirect.forgot_password();
    temp = temp || this.redirect.register();
    temp = temp || this.redirect.change_password();
    temp = temp || this.redirect.account_delete();
    temp = temp || this.redirect.delete_end();

    if (!temp) {
      this.router.navigate(['/login']);
    }
  }
}
