/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

export enum swipeEvent {
  start = 0,
  end = 1,
}

export enum swipeDirection {
  next = 0,
  previous = 1,
}

export class Swipe {
  private swipeCoord?: [number, number];
  private swipeTime?: number;

  constructor() {}
  /**
   *
   * @param e TouchEvent
   * @param when Type of event (start or end)
   * @returns null if nothing happened, otherwise next or previous
   */
  swipe(e: TouchEvent, when: swipeEvent): swipeDirection | null {
    const coord: [number, number] = [
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY,
    ];
    const time = new Date().getTime();

    if (when === swipeEvent.start) {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === swipeEvent.end) {
      const direction = [
        coord[0] - this.swipeCoord![0],
        coord[1] - this.swipeCoord![1],
      ];
      const duration = time - this.swipeTime!;

      if (
        duration < 1000 && //
        Math.abs(direction[0]) > 30 && // Long enough
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        // Horizontal enough
        return direction[0] < 0 ? swipeDirection.next : swipeDirection.previous;
      }
    }
    return null;
  }
}
