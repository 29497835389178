<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
-->
<div class="central_block" (window:keypress)="this.keyDownFunction($event)">
  <div class="form">
    <table>
      <tr>
        <th>
          <input
            type="number"
            name=""
            id="set_hours"
            min="{{ this.min != null ? this.min!.getHours() : 0 }}"
            max="24"
            (input)="this.setTime()"
          />
        </th>
        <th
          style="
            width: 31px;
            min-width: 31px;
            max-width: 31px;
            text-align: center;
          "
        >
          :
        </th>
        <th>
          <input
            type="number"
            name=""
            id="set_minutes"
            min="{{
              this.min == null || this._hours > this.min.getHours()
                ? 0
                : this.min.getMinutes()
            }}"
            max="60"
            (input)="this.setTime()"
          />
        </th>
      </tr>
    </table>
    <div>
      <button class="submit delete" (click)="this.close.emit(false)">
        CANCEL
      </button>
      <button class="submit" (click)="this.onSubmit()">OK</button>
    </div>
  </div>
</div>
