export class Firmware {
  public firmware_name = '';
  public url = '';
  public firmware_signature = '';
  public certificate = '';
  public firmware_version = '';
  public firmware_architecture = '';
  public firmware_upload_time = 0;

  constructor(
    firmware_name = '',
    url = '',
    firmware_signature = '',
    certificate = '',
    firmware_version = '',
    firmware_architecture = '',
    firmware_upload_time = 0
  ) {
    this.firmware_name = firmware_name;
    this.url = url;
    this.firmware_signature = firmware_signature;
    this.certificate = certificate;
    this.firmware_version = firmware_version;
    this.firmware_architecture = firmware_architecture;
    this.firmware_upload_time = firmware_upload_time;
  }
}
