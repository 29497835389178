<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div
  class="block"
  *ngIf="this.home.advanced_tools.types[0]"
  [ngStyle]="{ opacity: this.isOnline() ? '1' : '0.7' }"
>
  <div>
    <p class="title">Advanced</p>
  </div>
  <app-dashboard-menu
    [ngClass]="
      this.type == 0
        ? 'dashboard_menu dashboard_menu_datalogger'
        : 'dashboard_menu dashboard_menu_battery'
    "
    [mark_color]="'#50a17c'"
    [button_active]="'single'"
    [string_buttons]="
      this.type == 0 ? ['tools', 'reboot'] : ['tools', 'reboot', 'reboot_all']
    "
    [icon_width]="['25px', '25px', '25px']"
    [icon_height]="['25px', '25px', '25px']"
    [icon_margin]="['0px 7px 0px 0px', '0px 40px 0px 0px', '0px 40px 0px 0px']"
    [icon_padding]="['0px 0px 0px 0px', '0px 0px 0px 0px', '0px 0px 0px 0px']"
    [icons]="
      this.type == 0
        ? [
            '../../../assets/icon/advanced/swiss.svg',
            '../../assets/icon/advanced/reboot_white.svg'
          ]
        : [
            '../../../assets/icon/advanced/swiss.svg',
            '../../assets/icon/advanced/reboot_white.svg',
            '../../assets/icon/advanced/reboot_all_white.svg'
          ]
    "
    [labels]="
      this.type == 0 ? ['Tools', 'Reboot'] : ['Tools', 'Reboot', 'Reboot all']
    "
    [labels_width]="
      this.type == 0 ? ['46px', '88px'] : ['46px', '88px', '87px']
    "
    [labels_height]="
      this.type == 0 ? ['18px', '18px'] : ['18px', '18px', '18px']
    "
    [view_labels]="this.optionsLabel()"
    [buttons]="this.options"
    [is_active]="this.is_active"
    (response)="this.open_option($event)"
  ></app-dashboard-menu>
  <app-advanced-option
    [type]="this.type"
    [view_option]="this.is_active[0]"
    (close)="this.is_active[0] = $event"
    (command)="this.command($event)"
  ></app-advanced-option>
  <div class="title_icon">
    <img src="{{ this.src_img() }}" alt="" srcset="" />
  </div>
  <div class="title_icon title_device">
    <p>{{ this.type_title[this.type] }}</p>
  </div>
  <div class="title_icon title_device device_status">
    <p>
      {{ this.isOnline() ? "ONLINE" : "OFFLINE" }}
    </p>
  </div>
  <div class="fixed_table">
    <table class="list">
      <tbody>
        <tr>
          <th class="td_left">Product</th>
          <td class="td_left">
            {{ this.product() }}
          </td>
        </tr>
        <tr>
          <th class="td_left">Name</th>
          <td class="td_left">{{ this.devMan.device.alias }}</td>
        </tr>
        <tr>
          <th class="td_left">Serial Number</th>
          <td class="td_left">{{ this.sn() }}</td>
        </tr>
        <tr>
          <th class="td_left">RSSI</th>
          <td class="td_left">
            {{
              this.type == 0
                ? this.devMan.device.rssi
                : this.devMan.getCurrentBattery().rssi
            }}
            dB
          </td>
        </tr>
        <tr>
          <th class="td_left">Firmware version</th>
          <td class="td_left">
            {{
              this.type == 0
                ? this.devMan.device.fw_ver
                : this.devMan.getCurrentBattery().fw_ver
            }}
          </td>
        </tr>
        <tr>
          <th class="td_left">Updates</th>
          <td class="td_left">
            {{ this.checkVersion() }}
          </td>
        </tr>
        <tr class="first_tr">
          <th class="td_update">Update history</th>
        </tr>
        <tr>
          <td>
            {{
              this.tools.never_updated
                ? "This device has never been upgraded remotely"
                : "Update for datalogger (" +
                  this.tools.status.version +
                  ")"
            }}
          </td>
        </tr>
        <tr style="opacity: 0.7">
          <td class="td_update">
            <label *ngIf="this.tools.waiting_update_response"
              >Waiting response from device...</label
            >
            <label
              *ngIf="
                !this.tools.waiting_update_response &&
                this.tools.status.reasonCode <= 0
              "
            >
              {{
                this.tools.never_updated
                  ? "There is no information available to display"
                  : this.tools.status.status == "Timeout" &&
                    !this.tools.waiting_update_response
                  ? "Succesfully installed on " +
                    this.lastRemoteUpdateHistory(
                      this.tools.status._time
                    )
                  : !this.tools.status.busy
                  ? "Succesfully installed on " +
                    this.lastRemoteUpdateHistory(
                      this.tools.status._time
                    )
                  : "Update in progress..."
              }}
            </label>
            <label
              *ngIf="
                !this.tools.waiting_update_response &&
                this.tools.status.reasonCode > 0
              "
            >
              Terminated with error on
              {{
                this.lastRemoteUpdateHistory(this.tools.status._time)
              }}</label
            >
          </td>
        </tr>
        <tr>
          <td class="td_update">
            <label
              [ngStyle]="{
                color: this.tools.never_updated ? 'white' : '#50a17c',
                opacity: this.tools.never_updated ? 0.7 : 1,
                cursor: this.disableUpdate() ? 'not-allowed' : 'pointer'
              }"
              >View installed update history</label
            >
          </td>
        </tr>
        <tr>
          <td class="td_update" style="padding: 0px">
            <div>
              <app-step-indicator-view
                [numberOfSteps]="6"
                [currentStep]="
                  this.tools.status.reasonCode == -1
                    ? -1
                    : this.tools.status.step
                "
                [error]="this.tools.status.reasonCode != 0"
                [horizontalLabels]="[]"
                [circleRadius]="this.annularStepperRadius() ? 25 : 18"
              ></app-step-indicator-view>
            </div>
          </td>
        </tr>
        <tr>
          <td class="td_update" style="text-align: center; opacity: 0.7">
            <label>
              {{
                this.tools.never_updated
                  ? ""
                  : this.tools.status.status
              }}</label
            >
          </td>
        </tr>
        <tr>
          <td class="td_update" style="text-align: center">
            <button
              class="submit"
              [ngStyle]="{
                color: this.disableUpdate() ? '#343334' : '',
                background: this.disableUpdate() ? '#6E6D6F' : '',
                cursor: this.disableUpdate() ? 'not-allowed' : ''
              }"
              [disabled]="this.disableUpdate()"
              (click)="this.updateFirmware()"
            >
              Upgrade now
            </button>
          </td>
        </tr>
      </tbody>
      <tr class="last_row">
        <td class="switch_left switch_last_row">
          {{ this.type == 0 ? 1 : this.devMan.battery_num + 2 }} /
          {{ this.devMan.batteries_tot_num + 1 }}
        </td>
        <td style="text-align: center"></td>
        <td class="switch_right">
          <img
            src="../../../assets/icon/arrow/chevron.left.svg"
            alt=""
            (click)="this.switchDevice('left')"
          />
        </td>
        <td class="switch_right">
          <img
            src="../../../assets/icon/arrow/chevron.right.svg"
            alt=""
            (click)="this.switchDevice('right')"
          />
        </td>
      </tr>
    </table>
  </div>
</div>
<app-warning
  [title]="'Server response'"
  [background_color]="this.tools.warning_background_color"
  [message]="[this.tools.warning_message]"
  [warning]="this.tools.view_warning"
  [timeout]="5"
  (response)="this.tools.view_warning = $event"
></app-warning>

<app-terminal
  [host]="'grafana.archimede-energia.com'"
  [port]="9001"
  [username]="'mqtt-archimede'"
  [password]="'mqTT@ch12020'"
  [device_sn]="this.devMan.device.sn"
  [sfxPublishTopic]="'/console/in'"
  [sfxSubscribeTopic]="'/console/out'"
  [pfxTopic]="'units/' + this.devMan.device.sn"
  [name]="this.devMan.device.alias"
  [target]="this.devMan.device.sn"
  *ngIf="this.home.advanced_tools.types[1]"
></app-terminal>
<app-device-settings
  *ngIf="this.home.advanced_tools.types[2]"
></app-device-settings>
