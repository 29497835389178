<div
  class="central_block"
  *ngIf="this.view_option"
  (window:resize)="this.set_option()"
>
  <div>
    <button class="circle" id="circle">
      <img
        class="icon_close"
        src="../../../assets/icon/close.svg"
        (click)="this.close.emit(false)"
      />
    </button>
  </div>
  <div class="form">
    <table>
      <tr>
        <td colspan="2" class="filter_title">Tools</td>
      </tr>
      <tr>
        <td colspan="2" class="filter_subtitle">
          Select the items you want to include in the list.
        </td>
      </tr>
      <tr
        *ngFor="let i of this.button_index"
        [ngStyle]="{
          opacity: this.disable[i] ? 0.3 : 1
        }"
      >
        <td
          class="data_tool"
          (click)="this.clickCommand(i)"
          (mouseover)="this.is_over[i] = true"
          (mouseout)="this.is_over[i] = false"
        >
          <img
            src="{{ this.is_over[i] ? tools_imgs_over[i] : tools_imgs[i] }}"
            alt=""
            srcset=""
          />
          <div>
            <p class="p_title">{{ tools_title[i] }}</p>
            <p class="p_data">{{ tools_label[i] }}</p>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
