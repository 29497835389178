import { ChangeDetectorRef, OnChanges } from '@angular/core';
import {
  Component,
  Input,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { StepBarUtility } from './step-bar-utility';

@Component({
  selector: 'app-line-layer',
  template: '<ng-content></ng-content>',
  styleUrls: [],
})
export class LineLayerComponent {
  private utility: StepBarUtility = new StepBarUtility(this.el, this.cdRef);
  public tintColor: string = 'red';
  public strokeColor: string = 'gray';
  public lineWidth: number = 2;
  public width: number = 20;
  public height: number = 20;
  @Input() isFinished: boolean = false; //start false
  public isHorizontal: boolean = true;

  private lineElement: SVGLineElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'line'
  );
  public svg: SVGElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'svg'
  );

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  updateStatus(): void {
    this.utility.removeAllFromSuperLayer(this.svg);
    this.drawLinePath();
    if (this.isFinished) {
      this.drawTintLineAnimated();
    } else {
      this.utility.removeFromSuperLayer(this.svg, this.lineElement);
    }
  }

  private drawLinePath(): void {
    const { svg, strokeColor, width, height } = this;
    let { lineElement } = this;

    const line1 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'line'
    );

    line1.setAttribute('stroke', `${strokeColor}`);
    if (this.isHorizontal) {
      const centerY = '50%';
      line1.setAttribute('x1', '0');
      line1.setAttribute('y1', centerY);
      line1.setAttribute('x2', width.toString());
      line1.setAttribute('y2', centerY);
      lineElement.setAttribute('x1', '0');
      lineElement.setAttribute('y1', centerY);
      lineElement.setAttribute('x2', width.toString());
      lineElement.setAttribute('y2', centerY);
    } else {
      console.log('v');
      const centerX = '50%';
      line1.setAttribute('x1', centerX);
      line1.setAttribute('y1', '0');
      line1.setAttribute('x2', centerX);
      line1.setAttribute('y2', height.toString());
      lineElement.setAttribute('x1', centerX);
      lineElement.setAttribute('y1', '0');
      lineElement.setAttribute('x2', centerX);
      lineElement.setAttribute('y2', height.toString());
    }
    svg.appendChild(line1);
    svg.appendChild(lineElement);
    svg.setAttribute('width', width.toString());
    svg.setAttribute('height', height.toString());
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

    this.renderer.appendChild(this.el.nativeElement, svg);
  }

  private drawTintLineAnimated(): void {
    let { lineElement } = this;
    const { lineWidth, tintColor } = this;
    if (!this.tintColor || !lineElement) {
      return;
    }

    lineElement.style.stroke = 'transparent';
    lineElement.style.strokeWidth = `${lineWidth}`;
    lineElement.style.strokeDasharray = lineElement.getTotalLength().toString();
    lineElement.style.strokeDashoffset = lineElement
      .getTotalLength()
      .toString();

    setTimeout(() => {
      lineElement!.style.transition = 'stroke-dashoffset 0.1s ease-in-out';
      lineElement!.style.stroke = tintColor;
      lineElement!.style.strokeDashoffset = '0';
    }, 0);
  }
}
