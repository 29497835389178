/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HttpClient } from '@angular/common/http';
import { Regex } from 'src/model/Regex';
import { AppRoutingModule } from '../../app-routing.module';
import * as country from '../../../model/JSON/countries.json';

export class Data_registration {
  public country: Country[] = this.json2array(
    JSON.parse(JSON.stringify(country))
  );

  public warning: boolean = false;
  public warning_message: string[] = [];
  public warning_background_color = '';

  public data_required: string[] = [];

  public data_unrequired: string[] = [];

  public data_required_check: boolean[] = [];
  public data_required_input_id: string[] = [];
  public data_unrequired_input_id: string[] = [];

  public unique_email: boolean = false;
  public unique_username: boolean = false;

  public password_confirmation_bool: boolean = false;

  constructor(
    data_required: string[],
    data_unrequired: string[],
    public http: HttpClient,
    public router: AppRoutingModule
  ) {
    for (let i = 0; i < data_required.length; i++) {
      this.data_required[i] = '';
      this.data_required_input_id[i] = data_required[i];
      this.data_required_check[i] = false;
    }
    for (let i = 0; i < data_unrequired.length; i++) {
      this.data_unrequired[i] = '';
      this.data_unrequired_input_id[i] = data_unrequired[i];
    }
  }

  private json2array(json: Country[]) {
    let list: Country[] = [];
    for (let i = 0; i < json.length; i++) {
      list.push({ name: json[i].name, code: json[i].code });
    }
    return list;
  }

  password_confirmation(password: string, password_confirmation: string): void {
    if (password != password_confirmation && password != '') {
      this.password_confirmation_bool = true;
    } else {
      this.password_confirmation_bool = false;
    }
  }

  /**
   * Update input data and check if are null
   * @returns an object with check parameter true if data required are != '', false otherwise
   */
  required(): boolean {
    let check = true; //(true and a) = a  -> a sarà sempre il booleano che identifica la correttezza dei campi in input

    for (let i = 0; i < this.data_required.length; i++) {
      this.parameter_required(i);
      check = check && this.data_required_check[i];
    }

    for (let i = 0; i < this.data_unrequired.length; i++) {
      this.data_unrequired[i] = (
        document.getElementById(
          this.data_unrequired_input_id[i]
        )! as HTMLInputElement
      ).value.trim();
    }

    return check;
  }

  public parameter_required(pos: number) {
    this.data_required[pos] = (
      document.getElementById(
        this.data_required_input_id[pos]
      )! as HTMLInputElement
    ).value.trim();
    if (this.data_required[pos] == '') {
      this.data_required_check[pos] = true;
    } else {
      this.data_required_check[pos] = false;
    }
  }

  public set_input_value(pos: number, value: string) {
    this.data_required[5] = value;
    (
      document.getElementById(
        this.data_required_input_id[pos]
      )! as HTMLInputElement
    ).value = value;
    if (this.data_required[pos] == '') {
      this.data_required_check[pos] = true;
    } else {
      this.data_required_check[pos] = false;
    }
  }

  /**
   * Request to server if there is another user with same parameter
   * @param request is type of request that client want to check
   * @param parameter element to check
   */
  check_unique_contact(request: string, parameter: number, id: string) {
    if (parameter == 2) {
      if (!Regex.regex_email(this.data_required[2])) {
        return;
      }
    }
    if (parameter == 3) {
      if (!Regex.regex_phone(this.data_required[3])) {
        return;
      }
    }
    this.http
      .post<boolean>(this.router.server_domain + '/register.php', {
        request: request,
        parameter: (
          document.getElementById(
            this.data_required_input_id[parameter]
          )! as HTMLInputElement
        ).value.trim(),
        id: id,
      })
      .subscribe(
        (result) => {
          //if result false parameter is not unique
          if (result == false) {
            switch (parameter) {
              case 1:
                this.unique_username = true;
                break;
              case 2:
                this.unique_email = true;
                break;
            }
          } else {
            switch (parameter) {
              case 1:
                this.unique_username = false;
                break;
              case 2:
                this.unique_email = false;
                break;
            }
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
}

export class Country {
  public name: string = '';
  public code: string = '';
}
