<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div class="body" *ngIf="this.app.pages.types[3]">
  <table class="path" *ngIf="this.view_path()">
    <tr>
      <td *ngFor="let p of this.title_path; let i = index">
        <a> > </a>
        <a class="folder_link" (click)="this.change_folder(this.path[i])">
          {{ p }}
        </a>
      </td>
    </tr>
  </table>
  <div
    class="fixed_table"
    *ngIf="this.component.types[0] || this.component.types[6]"
  >
    <!-- Home -->
    <table class="list" *ngIf="this.component.types[0]">
      <tr
        (click)="
          this.change_component('device_list');
          this.menu.change_menu('device_options')
        "
      >
        <th>
          <app-home-menu-button
            [button_id]="'device_list_s'"
            [class]="'menu_button'"
            [background_color]="'#343334'"
            [hover_background_color]="'#343334'"
            [label]="'Device list'"
            [icon_src]="'../../assets/icon/home-menu/device_list.png'"
          ></app-home-menu-button>
          <p>
            View a list of your registered devices and devices shared by other
            users.
          </p>
        </th>
      </tr>
      <tr>
        <th>
          <app-home-menu-button
            [button_id]="'service_s'"
            [class]="'menu_button'"
            [background_color]="'#343334'"
            [hover_background_color]="'#343334'"
            [label]="'Service'"
            [icon_src]="'../../assets/icon/home-menu/service.png'"
          ></app-home-menu-button>
          <p>Coming soon</p>
        </th>
      </tr>
      <tr
        (click)="
          this.change_component('settings'); this.menu.change_menu('settings')
        "
      >
        <th>
          <app-home-menu-button
            [button_id]="'settings_s'"
            [class]="'menu_button'"
            [background_color]="'#343334'"
            [hover_background_color]="'#343334'"
            [label]="'Settings'"
            [icon_src]="'../../assets/icon/home-menu/settings.svg'"
          ></app-home-menu-button>
          <p>
            Manage user parameters, update password, and delete the account.
          </p>
        </th>
      </tr>
      <tr
        *ngIf="this.is_developer"
        (click)="
          this.change_component('admin_area');
          this.menu.change_menu('admin_area')
        "
      >
        <th>
          <app-home-menu-button
            [button_id]="'admin_area_s'"
            [class]="'menu_button'"
            [background_color]="'#343334'"
            [hover_background_color]="'#343334'"
            [label]="'Admin area'"
            [icon_src]="'../../assets/icon/home-menu/key.svg'"
          ></app-home-menu-button>
          <p>Manage ae-portal.</p>
        </th>
      </tr>
    </table>
    <!-- Settings -->
    <table class="list" *ngIf="this.component.types[6]">
      <tr (click)="this.change_component('profile')">
        <th>
          <app-home-menu-button
            [button_id]="'profile_s'"
            [class]="'menu_button'"
            [background_color]="'#343334'"
            [hover_background_color]="'#343334'"
            [label]="'Profile'"
            [icon_src]="'../../assets/icon/home-menu/account icon.svg'"
          ></app-home-menu-button>
          <p>Edit your profile data.</p>
        </th>
      </tr>
      <tr (click)="this.change_component('password')">
        <th>
          <app-home-menu-button
            [button_id]="'change_password_s'"
            [class]="'menu_button'"
            [background_color]="'#343334'"
            [hover_background_color]="'#343334'"
            [label]="'Change password'"
            [icon_src]="'../../assets/icon/home-menu/lock icon.svg'"
          ></app-home-menu-button>
          <p>Change your password.</p>
        </th>
      </tr>
    </table>
  </div>
  <app-home-menu (component)="this.change_component($event)"></app-home-menu>
  <div (click)="this.menu.outnav()" *ngIf="!this.component.types[0]">
    <app-profile *ngIf="this.component.types[4]"></app-profile>
    <app-password *ngIf="this.component.types[5]"></app-password>
    <app-device-list *ngIf="this.component.types[1]"></app-device-list>
    <app-battery-list *ngIf="this.component.types[3]"></app-battery-list>
    <app-advanced-general
      *ngIf="this.component.types[2]"
    ></app-advanced-general>
    <app-admin-area
      *ngIf="this.component.types[7] && this.is_developer"
    ></app-admin-area>
  </div>
</div>
