export class User {
  static user_type_from_number_to_string(type: number) {
    switch (type) {
      case 1:
        return 'Enduser';
      case 2:
        return 'Superuser';
      case 3:
        return 'Manufacturer';
    }
    return 'Enduser';
  }

  static user_type_from_string_to_number(type: string) {
    switch (type) {
      case 'Enduser':
        return 1;
      case 'Superuser':
        return 2;
      case 'Manufacturer':
        return 3;
    }
    return 1;
  }
}
