import { AppDate } from './app.date';

export enum intervalType {
  Hourly = 0,
  Daily = 1,
  Weakly = 2,
  Montly = 3,
}

export class BatteryHistory {
  public _time: number = 0;
  public sample: Sample = new Sample();
}

class intervalIncrement {
  static HourIncrement: number = 60;
  static DayIncrement: number = 60 * 60;
  static WeekIncrement: number = 24 * 60 * 60;
  static MonthIncrement: number = 24 * 60 * 60;
}

export class intervalRange {
  static Hour: number = 60 * 60;
  static Day: number = 24 * 60 * 60;
  static Week: number = 7 * 24 * 60 * 60;
  static Month: number = 29 * 24 * 60 * 60;
}

export class Sample {
  _time: number = 0;
  current: number = 0;
  voltage: number = 0;
  soc: number = 0;
  temperature: number = 0;
  deltaVoltage: number = 0;
  isEmpty: boolean = false;
  timeout: boolean = false;
  readOnly: boolean = false;
}

export class Statistics {
  avg_current: number = 0.0;
  avg_voltage: number = 0.0;
  avg_soc: number = 0;
  avg_temperature: number = 0.0;
  avg_deltavoltage: number = 0.0;
  max_current: number = 0.0;
  max_voltage: number = 0.0;
  max_soc: number = 0;
  max_temperature: number = 0.0;
  max_deltavoltage: number = 0.0;
  min_current: number = 0.0;
  min_voltage: number = 0.0;
  min_soc: number = 0;
  min_temperature: number = 0.0;
  min_deltavoltage: number = 0.0;
}

export type range = Map<Date, Sample>;
/**
 *
 * @param iType
 * @param offset
 * @param start return value
 * @param end return value
 */
export function calculateRangeLimit(
  iType: intervalType,
  offset: number
): { start: Date; end: Date } {
  var increment: number;
  var offsec: number = 0;

  //get current date
  var start = new Date();
  var end = new Date();

  switch (iType) {
    case intervalType.Hourly:
      increment = intervalIncrement.HourIncrement;
      offsec = Number(offset) * increment * 60;
      start.setUTCSeconds(offsec);
      start.setUTCMinutes(0);
      start.setUTCSeconds(0);
      end.setUTCSeconds(offsec);
      end.setUTCMinutes(59);
      end.setUTCSeconds(59);
      break;
    case intervalType.Daily:
      var timeZoneOffsetMinutes = new Date().getTimezoneOffset();
      var hoursToSubtract = timeZoneOffsetMinutes / 60;
      start.setUTCHours(offset * 24);
      start.setUTCHours(0 + hoursToSubtract);
      start.setUTCMinutes(0);
      start.setUTCSeconds(0);
      end.setUTCHours(offset * 24);
      end.setUTCHours(23 + hoursToSubtract);
      end.setUTCMinutes(59);
      end.setUTCSeconds(59);
      break;
    case intervalType.Weakly:
      increment = intervalIncrement.WeekIncrement;
      offsec = Number(offset) * increment * 7;
      let day = start.getUTCDay();
      let diff = start.getUTCDate() - day + (day == 0 ? -6 : 1);
      start.setUTCDate(diff);
      start.setUTCSeconds(offsec);
      start.setUTCHours(0);
      start.setUTCMinutes(0);
      start.setUTCSeconds(0);
      end.setUTCDate(diff + 6);
      end.setUTCSeconds(offsec);
      end.setUTCHours(23);
      end.setUTCMinutes(59);
      end.setUTCSeconds(59);
      break;
    case intervalType.Montly:
      var d = new Date();
      start = new Date(
        d.getUTCFullYear(),
        d.getUTCMonth() + offset,
        1,
        0,
        0,
        0
      );
      end = new Date(
        d.getUTCFullYear(),
        d.getUTCMonth() + 1 + offset,
        0,
        23,
        59,
        59
      );
      break;
  }
  return { start, end };
}

export class RecordMap {
  //delegate
  private m_records: range = new Map<Date, Sample>();
  private m_from: Date = new Date();
  private m_to: Date = new Date();

  constructor() {}

  public getRecords(from: Date, to: Date): range {
    var ret: range = new Map<Date, Sample>();

    for (let item of this.m_records.keys()) {
      if (item > from && item < to) {
        ret.set(item, this.m_records.get(item)!);
      }
    }
    return ret;
  }

  public getStatistics(r: range): Statistics {
    var stats: Statistics = new Statistics();
    var emptyCounter: number = 0;
    var size: number = r.size;

    if (size == 0) {
      return stats;
    }
    for (let key of r.keys()) {
      let s: Sample = r.get(key)!;
      if (s.isEmpty) {
        emptyCounter += 1;
        continue;
      }
      stats.avg_current += s.current;
      stats.avg_voltage += s.voltage;
      stats.avg_temperature += s.temperature;
      stats.avg_soc += s.soc;
      stats.avg_deltavoltage += s.deltaVoltage;
    }

    size -= emptyCounter;
    if (size != 0) {
      stats.avg_current /= Number(size);
      stats.avg_voltage /= Number(size);
      let temp: Number = Number(stats.avg_soc) / Number(size);
      stats.avg_soc = Number(temp);
      stats.avg_temperature /= Number(size);
      stats.avg_deltavoltage /= Number(size);

      for (let t of r) {
        if (!t[1].isEmpty) {
          stats.max_current = t[1].current;
          stats.max_voltage = t[1].voltage;
          stats.max_soc = t[1].soc;
          stats.max_temperature = t[1].temperature;
          stats.max_deltavoltage = t[1].deltaVoltage;

          stats.min_current = t[1].current;
          stats.min_voltage = t[1].voltage;
          stats.min_soc = t[1].soc;
          stats.min_temperature = t[1].temperature;
          stats.min_deltavoltage = t[1].deltaVoltage;

          break;
        }
      }

      r.forEach((x) => {
        if (!x.isEmpty) {
          stats.max_current = Math.max(stats.max_current, x.current);
          stats.max_voltage = Math.max(stats.max_voltage, x.voltage);
          stats.max_soc = Math.max(stats.max_soc, x.soc);
          stats.max_temperature = Math.max(
            stats.max_temperature,
            x.temperature
          );
          stats.max_deltavoltage = Math.max(
            stats.max_deltavoltage,
            x.deltaVoltage
          );
          stats.min_current = Math.min(stats.min_current, x.current);
          stats.min_voltage = Math.min(stats.min_voltage, x.voltage);
          stats.min_soc = Math.min(stats.min_soc, x.soc);
          stats.min_temperature = Math.min(
            stats.min_temperature,
            x.temperature
          );
          stats.min_deltavoltage = Math.min(
            stats.min_deltavoltage,
            x.deltaVoltage
          );
        }
      });
    }

    return stats;
  }

  public emitSample(batteryHistory: BatteryHistory[]) {
    for (let bh of batteryHistory) {
      this.insert(new Date(bh._time), bh.sample);
    }
  }

  public insert(date: Date, sample: Sample) {
    if (this.m_from > date) {
      this.m_from = date;
    }

    if (this.m_to < date) {
      this.m_to = date;
    }

    this.m_records.set(date, sample);
  }

  static getIncrement(iType: intervalType): number {
    var ret: number = 0;

    switch (iType) {
      case intervalType.Hourly:
        ret = intervalIncrement.HourIncrement;
        break;
      case intervalType.Daily:
        ret = intervalIncrement.DayIncrement;
        break;
      case intervalType.Weakly:
        ret = intervalIncrement.WeekIncrement;
        break;
      case intervalType.Montly:
        ret = intervalIncrement.MonthIncrement;
        break;
    }

    return ret;
  }

  public getPoints(
    iType: intervalType,
    offset: number = 0
  ): { ret: range; from: Date; to: Date } {
    var ret: range = new Map<Date, Sample>();
    var from: Date = new Date();
    var to: Date = new Date();
    var increment: number = 0;
    var s: Sample;
    var counter = 0;
    var checkLastHourlyPoint: boolean = false;

    increment = RecordMap.getIncrement(iType);
    let rangeLimit = calculateRangeLimit(iType, offset);
    from = rangeLimit.start;
    to = rangeLimit.end;
    var d = new Date(from);
    var start: Date | undefined = new Date(from!);
    var end: Date | undefined = null!;
    while (d < to) {
      s = new Sample();
      for (let item of this.m_records.keys()) {
        var check: boolean = false;

        switch (iType) {
          case intervalType.Hourly:
            check =
              item.getMinutes() == d.getMinutes() &&
              Math.abs((item.getTime() - d.getTime()) / 1000) <= increment;
            break;
          case intervalType.Daily:
            check =
              item.getHours() == d.getHours() &&
              Math.abs((item.getTime() - d.getTime()) / 1000) <= increment;
            break;

          case intervalType.Weakly:
            check =
              item.getDay() == d.getDay() &&
              Math.abs((item.getTime() - d.getTime()) / 1000) <= increment;
            break;

          case intervalType.Montly:
            check =
              item.getDate() == d.getDate() &&
              Math.abs((item.getTime() - d.getTime()) / 1000) <= increment;
            break;
        }

        if (check) {
          if (end == null) {
            end = item;
          }
          s.current += this.m_records.get(item)?.current!;
          s.voltage += this.m_records.get(item)?.voltage!;
          s.temperature += this.m_records.get(item)?.temperature!;
          s.soc += this.m_records.get(item)?.soc!;
          s.timeout = s.timeout || this.m_records.get(item)?.timeout!;
          s.deltaVoltage += this.m_records.get(item)?.deltaVoltage!;
          counter += 1;
        }
      }

      if (counter != 0) {
        s.current /= Number(counter);
        s.voltage /= Number(counter);
        s.temperature /= Number(counter);
        s.soc /= Number(counter);
        s.deltaVoltage /= Number(counter);

        /* case Hourly is an exception because we do not want to see current date point moving */
        if (iType == intervalType.Hourly) {
          let currentDate = new Date();
          checkLastHourlyPoint =
            currentDate.getMinutes() == d.getMinutes() &&
            Math.abs((currentDate.getTime() - d.getTime()) / 1000) <= increment;
        }
      }

      if (counter == 0 || checkLastHourlyPoint) {
        s.isEmpty = true;
      } else if (iType == intervalType.Hourly) {
        if (
          !(ret.size === 0) &&
          Array.from(ret.values()).pop()!.isEmpty == true &&
          Math.abs((start!.getTime() - end!.getTime()) / 1000) <= 1800
        ) {
          for (let ri of ret) {
            if (ri[0] >= start! && ri[0] < end!) {
              ret.set(ri[0], s);
            }
          }
        }

        if (s.isEmpty == false) {
          start = new Date(end!);
          end = null!;
        }
      }
      var t = new Date(d);
      ret.set(t, s);

      counter = 0;
      checkLastHourlyPoint = false;
      d.setSeconds(+increment);
    }

    return { ret, from, to };
  }

  public dropRecords() {
    for (let key of this.m_records.keys()) {
      if (this.m_records.get(key)?.readOnly == false) {
        this.m_records.delete(key);
      }
    }
  }
}
