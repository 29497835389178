/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { MQTT, MqttHandlerMsg, TopicType } from 'src/model/app.mqtt';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HttpClient } from '@angular/common/http';
import {
  DeviceManager,
  BatteryMethods,
  DeviceMethods,
} from './app.deviceManager';
import { MQTTReceiver } from './app.mqttReceiver';
import { Firmware } from 'src/app/advanced/advanced-general/firmware';
import { AppDate } from './app.date';

export class Tools {
  public never_updated: boolean = true;

  public waiting_update_response: boolean = false;

  public view_warning: boolean = false;
  public warning_background_color: string = '';
  public warning_message: string = '';

  public base_info: boolean = false;
  public read_variable: boolean = false;
  public write_variable: boolean = false;
  public list_variables: boolean = false;
  public reb: boolean = false;
  // Callback function to implement
  public onTimeoutInfo(index: number) {}
  public onInfo(index: number, rssi: string, fw_ver: string) {}
  public onReadVariable(str: string) {}
  public onWriteVariable() {}
  public onListVariables(str: string) {}
  public onReb() {}

  public status: Status = new Status();

  private mqtt: MQTT = new MQTT();
  public mqttReciver: MQTTReceiver = new MQTTReceiver();

  constructor(
    public app: AppRoutingModule,
    public http: HttpClient,
    public devMan: DeviceManager
  ) {
    this.mqtt.onMessageArrived = (message) => {
      this.mqttReciver.emitDidReceived(message);
    };
    this.mqttReciver.processMQTTRemoteCommand = (data, index) => {
      this.subscribe(data, index);
    };
    this.mqttReciver.targetNotReachable = (cmdid: string, index: number) => {
      this.targetNotreachable(cmdid, index);
    };
    this.mqtt.pushSubscribe(this.createTopic(TopicType.subscribe), 0);
  }

  public disconnect() {
    this.mqtt.disconnect();
    this.mqttReciver.reset();
  }

  public getStatus() {
    this.http
      .post<Status | boolean>(this.app.server_domain + '/advanced.php', {
        request: 'device_firmware_status',
        token: this.app.token,
        sn: this.devMan.device.sn,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            //server error
            return;
          }
          this.status = result;
          // If server return null this device was never updated
          if (result != null) {
            this.never_updated = false;
          } else {
            this.status = new Status();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  private async subscribe(data: any, index: number) {
    let cmdid = data.cmdid;
    let parameters = data.parameters;
    this.view_warning = false;
    switch (cmdid) {
      case 'info.rsp':
        this.base_info = false;
        this.onInfo(index, parameters.rssi, parameters.fw_ver);
        break;
      case 'read.variables.rsp':
        this.read_variable = false;
        this.onReadVariable(parameters.value);
        break;
      case 'write.variables.rsp':
        this.write_variable = false;
        this.onWriteVariable();
        break;
      case 'list.variables.rsp':
        this.list_variables = false;
        this.onListVariables(this.decodeHTML(parameters.value));
        break;
      case 'reboot.rsp':
        this.reb = false;
        this.warning_background_color = '#50a17c';
        this.warning_message = 'Target reboot';
        this.view_warning = true;

        this.onReb();
        break;
      case 'update.firmware.res':
      case 'firmware.notification.req':
        this.status = new Status(
          parameters.time,
          parameters.status,
          parameters.reasoncode != null ? parameters.reasoncode : 0,
          parameters.additionalInfo != null
            ? parameters.additionalInfo
            : 'No additional info',
          parameters.busy,
          parameters.step,
          parameters.version
        );
        // If arrive message we don't need to wait response
        this.waiting_update_response = false;
        // In this.checkTarget() we do a publish to set target status = Timeout
        // But we are listening on this topic
        // Is necessary check if message that arrive has timeout status because if has timeout status target is not reachable
        // (we do the publish with timeout status, not the target)
        if (this.status.status != 'Timeout') {
          //this.target_reachable = true;
        }
        break;
    }
  }

  private decodeHTML(str: string) {
    let txt = document.createElement('textarea');
    txt.innerHTML = str;
    return txt.value.trim();
  }
  public async update(
    _type: deviceType,
    firmwareDatalogger: Firmware,
    firmwareBattery: Firmware
  ) {
    if (this.waiting_update_response || this.status.busy == 1) {
      console.log('You are already doing an update');
      return;
    }
    if (this.mqttReciver.hasKeyWithText('update:')) return;
    let requestID = MQTT.createRequestID('update:');
    // If target is IDLE (not BUSY) we waiting response form target
    this.waiting_update_response = true;
    let type = 0;
    if (_type == deviceType.datalogger) {
      if (DeviceMethods.deviceLinux(this.devMan.device.sn.substring(0, 2)))
        type = 1;
      else type = 0;
    }
    if (_type == deviceType.battery) {
      type = 2;
    }
    var message;
    if (type == 1) {
      message = {
        cmdid: 'update.firmware.req',
        parameters: {
          retries: 3,
          retryInterval: 5,
          requestID: requestID,
          location: firmwareDatalogger.url,
          retrieveDateTime: AppDate.stringFromDateFromYearToMilliseconds(
            new Date()
          ),
          signature: firmwareDatalogger.firmware_signature,
          signingCertificate: firmwareDatalogger.certificate,
          version: firmwareDatalogger.firmware_version,
        },
      };
    }
    if (type == 2) {
      message = {
        cmdid: 'update.firmware.req',
        parameters: {
          retries: 3,
          retryInterval: 5,
          requestID: requestID,
          location: firmwareBattery.url,
          retrieveDateTime: AppDate.stringFromDateFromYearToMilliseconds(
            new Date()
          ),
          signature: firmwareBattery.firmware_signature,
          signingCertificate: firmwareBattery.certificate,
          version: firmwareBattery.firmware_version,
          arguments:
            '-a ' +
            BatteryMethods.dec2hex(
              this.devMan.getCurrentBattery().baseid.toString(),
              4
            ),
        },
      };
    }
    this.mqtt.pushPublish(
      new MqttHandlerMsg(
        this.createTopic(TopicType.publish),
        JSON.stringify(message),
        false
      )
    );
  }

  public async readVariable(
    type: deviceType,
    strRangeID: string,
    index: number
  ) {
    if (this.mqttReciver.hasKeyWithText('read.variables:')) return;
    let requestID = MQTT.createRequestID('read.variables:');
    this.mqttReciver.addRequestID(requestID, index);
    let arg: string = '';
    switch (type) {
      case deviceType.datalogger:
        arg = '';
        break;
      case deviceType.battery:
        arg =
          '-a ' +
          BatteryMethods.dec2hex(
            this.devMan.getCurrentBattery().baseid.toString(),
            4
          );
        break;
    }
    let msg = '';
    if (type == deviceType.datalogger) {
      msg = JSON.stringify({
        cmdid: 'read.variables.req',
        parameters: {
          requestID: MQTT.createRequestID(),
          name: strRangeID,
        },
      });
    } else {
      msg = JSON.stringify({
        cmdid: 'read.variables.req',
        parameters: {
          requestID: MQTT.createRequestID(),
          arguments: arg,
          name: strRangeID,
        },
      });
    }
    this.mqtt.pushPublish(
      new MqttHandlerMsg(this.createTopic(TopicType.publish), msg, false)
    );
  }

  public async writeVariable(
    type: deviceType,
    name: string,
    value: string,
    index: number
  ) {
    if (this.mqttReciver.hasKeyWithText('write.variables:')) return;
    let requestID = MQTT.createRequestID('write.variables:');
    this.mqttReciver.addRequestID(requestID, index);
    let arg: string = '';
    switch (type) {
      case deviceType.datalogger:
        arg = '';
        break;
      case deviceType.battery:
        arg =
          '-a ' +
          BatteryMethods.dec2hex(
            this.devMan.getCurrentBattery().baseid.toString(),
            4
          );
        break;
    }
    let msg = '';
    if (type == deviceType.datalogger) {
      msg = JSON.stringify({
        cmdid: 'write.variables.req',
        parameters: {
          requestID: MQTT.createRequestID(),
          name: name,
          value: value,
        },
      });
    } else {
      msg = JSON.stringify({
        cmdid: 'write.variables.req',
        parameters: {
          requestID: MQTT.createRequestID(),
          arguments: arg,
          name: name,
          value: value,
        },
      });
    }
    this.mqtt.pushPublish(
      new MqttHandlerMsg(this.createTopic(TopicType.publish), msg, false)
    );
  }

  public async baseInfo(type: deviceType, index: string) {
    if (this.mqttReciver.hasKeyWithText('info:')) return;
    let requestID = MQTT.createRequestID('info:');
    this.mqttReciver.addRequestID(requestID, Number(index));
    let arg: string = '';
    switch (type) {
      case deviceType.datalogger:
        arg = '';
        break;
      case deviceType.battery:
        arg =
          '-a ' +
          BatteryMethods.dec2hex(
            this.devMan.getCurrentBattery().baseid.toString(),
            4
          );
        break;
    }
    let msg = '';
    if (type == deviceType.datalogger) {
      msg = JSON.stringify({
        cmdid: 'info.req',
        parameters: {
          requestID: requestID,
          index: index,
        },
      });
    } else {
      msg = JSON.stringify({
        cmdid: 'info.req',
        parameters: {
          requestID: requestID,
          arguments: arg,
          index: index,
        },
      });
    }
    this.mqtt.pushPublish(
      new MqttHandlerMsg(this.createTopic(TopicType.publish), msg, false)
    );
  }

  public async listVariables(type: deviceType, index: number) {
    if (this.mqttReciver.hasKeyWithText('list.variables:')) return;
    let requestID = MQTT.createRequestID('list.variables:');
    this.mqttReciver.addRequestID(requestID, index);
    let arg: string = '';
    switch (type) {
      case deviceType.datalogger:
        arg = '';
        break;
      case deviceType.battery:
        arg =
          '-a ' +
          BatteryMethods.dec2hex(
            this.devMan.getCurrentBattery().baseid.toString(),
            4
          );
        break;
    }
    let msg = '';
    if (type == deviceType.datalogger) {
      msg = JSON.stringify({
        cmdid: 'list.variables.req',
        parameters: {
          requestID: MQTT.createRequestID(),
        },
      });
    } else {
      msg = JSON.stringify({
        cmdid: 'list.variables.req',
        parameters: {
          requestID: MQTT.createRequestID(),
          arguments: arg,
        },
      });
    }
    this.mqtt.pushPublish(
      new MqttHandlerMsg(this.createTopic(TopicType.publish), msg, false)
    );
  }

  public async reboot(reboot_type: rebootType) {
    if (this.mqttReciver.hasKeyWithText('reboot:')) return;
    let requestID = MQTT.createRequestID('reboot:');
    // this.mqttReciver.addRequestID(requestID, -1);
    let arg: string = '';
    switch (reboot_type) {
      case rebootType.datalogger:
        arg = '';
        break;
      case rebootType.battery:
        arg =
          '-a ' +
          BatteryMethods.dec2hex(
            this.devMan.getCurrentBattery().baseid.toString(),
            4
          );
        break;
      case rebootType.all_battery:
        arg = '-a all';
        break;
    }
    let msg = '';
    if (reboot_type == rebootType.datalogger) {
      msg = JSON.stringify({
        cmdid: 'reboot.req',
        parameters: {
          requestID: requestID,
        },
      });
    } else {
      msg = JSON.stringify({
        cmdid: 'reboot.req',
        parameters: {
          requestID: requestID,
          arguments: arg,
        },
      });
    }
    this.mqtt.pushPublish(
      new MqttHandlerMsg(this.createTopic(TopicType.publish), msg, false)
    );
  }

  /**
   * Check if target is reachable
   * If target is not reachable we start update firmware recovery operations
   */
  private targetNotreachable(cmdid: string, index: number) {
    switch (cmdid) {
      case 'read.variables':
        this.read_variable = false;
        break;
      case 'write.variables':
        this.write_variable = false;
        break;
      case 'list.variables':
        this.list_variables = false;
        break;
      case 'reboot':
        this.reb = false;
        this.onReb();
        break;
      case 'info':
        this.base_info = false;
        this.onTimeoutInfo(index);
        break;
      case 'update':
        break;
    }
    this.warning_background_color = 'rgba(255, 86, 86, 0.868)';
    this.warning_message = 'Target is not reachable';
    this.view_warning = true;
  }

  public createTopic(topic_type: TopicType) {
    let topic = 'units/';

    topic += this.devMan.device.sn;
    switch (topic_type) {
      case TopicType.publish:
        topic += '/command/dev';
        break;
      case TopicType.subscribe:
        topic += '/command/app';
        break;
    }
    return topic;
  }
}

export enum rebootType {
  datalogger = 0,
  battery = 1,
  all_battery = 2,
}

export enum deviceType {
  datalogger = 0,
  battery = 1,
}

export class Status {
  public _time: number = 0;
  public status: string = 'Never updated';
  public reasonCode: number = -1;
  public additional_info: string = '';
  public busy: number = 0;
  public step: number = 0;
  public version: string = '';
  constructor(
    _time: number = 0,
    status: string = 'Never updated',
    reasonCode: number = -1,
    additional_info: string = '',
    busy: number = 0,
    step: number = 0,
    version: string = ''
  ) {
    this._time = _time;
    this.status = status;
    this.reasonCode = reasonCode;
    this.additional_info = additional_info;
    this.busy = busy;
    this.step = step;
    this.version = version;
  }
}
