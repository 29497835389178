/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Command } from 'src/app/dashboard-menu/command';
import { HomeComponent } from 'src/app/home/home.component';
import { User } from 'src/app/advanced/advanced-user/user';
import { deviceType } from 'src/model/app.tools';
import { AdvancedGeneralComponent } from '../advanced-general/advanced-general.component';
import { CommentObj, DeviceSettings, ExtendedObj } from './device-settings';

@Component({
  selector: 'app-device-settings',
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.css'],
})
export class DeviceSettingsComponent implements OnInit, OnDestroy {
  public menu_string_option: string[] = ['import', 'save'];
  public menu_options: boolean[] = [false, false];
  public is_active: boolean[] = [false, false];
  public menu_icons: string[] = [
    '../../assets/icon/advanced/square.and.arrow.up.fill.svg',
    '../../assets/icon/advanced/square.and.arrow.down.svg',
  ];
  // INFO, STATE, GENERAL, LOG, TEMPERATURES, VOLTAGES, CURRENT, SOC, SOH, CANBUS, CHARGER, CLUSTER, VIASAT, CLIMATIC
  public categories: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  public objList: ExtendedObj[] = [];
  public commentsList: CommentObj[] = [];
  public comment: CommentObj = new CommentObj('', '');
  public wait_variables: boolean = true;
  public write_id: string | null = null;
  public interval_list_variables: any;
  constructor(
    public home: HomeComponent,
    public adv: AdvancedGeneralComponent,
    public devSet: DeviceSettings
  ) {
    this.commentsList = this.devSet.getCommentsObjs();
  }

  ngOnInit(): void {
    this.adv.tools.onListVariables = (str: string) => {
      this.objList = this.devSet.getExtendedObjs(str);
      this.wait_variables = false;
    };
    this.adv.tools.listVariables(deviceType.battery, this.adv.devMan.battery_num);

    this.adv.tools.onReadVariable = (str: string) => {
      let all_tmp = str.includes(',') ? str.split(',') : [str];
      all_tmp.forEach((y: string) => {
        let first_space = y.indexOf(' ');
        let tmp = [y.substring(0, first_space), y.substring(first_space)];
        let index = this.objList.findIndex((x) => x.id == Number(tmp[0]));
        this.objList[index].value = tmp[1].replace(/'/g, '');
      });
    };

    this.adv.tools.onWriteVariable = () => {
      if (this.write_id != null)
        this.adv.tools.readVariable(deviceType.battery, this.write_id, this.adv.devMan.battery_num);
    };

    this.interval_list_variables = setInterval(() => {
      let listID = 0 + '..' + this.objList[this.objList.length - 1].id;
      this.adv.tools.readVariable(deviceType.battery, listID, this.adv.devMan.battery_num);
    }, 15 * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval_list_variables);
  }

  public onKeydownMain(
    event: { key: string; preventDefault: () => void },
    id: number,
    name: string,
    old_value: string
  ): void {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      let input = document.getElementById(
        'inputVariable_' + name
      )! as HTMLInputElement;
      let value = input.value.trim();
      this.adv.tools.writeVariable(deviceType.battery, name, value, this.adv.devMan.battery_num);
      input.value = old_value;
      this.write_id = id.toString();
      // My Functionality goes here
    }
  }

  public filter() {
    let apply_categories: string[] = [];
    let list: ExtendedObj[] = [];
    list = this.objList;

    list = list.filter((x) => !this.notVisibleRow(x.permission));

    for (let i = 0; i < this.categories.length; i++) {
      if (this.categories[i]) apply_categories.push(this.getCategories(i));
    }

    if (apply_categories.length > 0) {
      list = list.filter((x) => apply_categories.includes(x.category));
    }
    let search = (
      document.getElementById('search_objs_input')! as HTMLInputElement
    ).value
      .trim()
      .toLowerCase();
    if (search != '') {
      let tmp = search.split('|');
      let names: string[] = [];
      tmp.forEach((x) => {
        if (x != '') names.push(x.trim());
      });
      list = list.filter((x) => names.some((y) => x.name.includes(y)));
    }
    return list;
  }
  public setComment(str: string) {
    let tmp = this.commentsList.filter((x) => x.name == str);
    if (tmp.length == 1) {
      this.comment = tmp[0];
    } else {
      this.comment = new CommentObj('', '');
    }
  }
  private getCategories(index: number) {
    switch (index) {
      case 0:
        return 'INFO';
      case 1:
        return 'STATE';
      case 2:
        return 'GENERAL';
      case 3:
        return 'LOG';
      case 4:
        return 'TEMPERATURES';
      case 5:
        return 'VOLTAGES';
      case 6:
        return 'CURRENT';
      case 7:
        return 'SOC';
      case 8:
        return 'SOH';
      case 9:
        return 'CANBUS';
      case 10:
        return 'CHARGER';
      case 11:
        return 'CLUSTER';
      case 12:
        return 'VIASAT';
      case 13:
        return 'CLIMATIC';
      default:
        return 'INFO';
    }
  }
  public command(command: Command = new Command('', [false, false])) {
    this.is_active = command.active;
    switch (command.command) {
      case 'save':
        this.saveFile();
        this.is_active[1] = false;
        break;
      case 'import':
        break;
    }
  }
  public saveFile() {
    let obj = this.objList.filter((x) => x.name == 'sn');
    let name = obj.length == 1 ? obj[0].value : 'values';
    name = name != '' ? name : 'values';
    const xmlContent: string = this.devSet.fromExtendedObjToXMLStr(
      this.objList
    );

    const blob = new Blob([xmlContent], { type: 'text/xml' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = name + '.params';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  public blueRow(permission: number) {
    return (
      (permission &
        (0x1 <<
          ((User.user_type_from_string_to_number(this.home.user_type) - 1) *
            2))) ==
      0
    );
  }
  public editableRow(permission: number) {
    return (
      (permission &
        (0x1 <<
          ((User.user_type_from_string_to_number(this.home.user_type) - 1) *
            2))) !=
      0
    );
  }
  public notVisibleRow(permission: number) {
    return (
      (permission &
        (0x2 <<
          ((User.user_type_from_string_to_number(this.home.user_type) - 1) *
            2))) ==
      0
    );
  }
}
