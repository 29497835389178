<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div class="central_block">
  <div
    id="circle_left"
    class="circle"
    (click)="this.option = true; this.option_type_input = ''"
    *ngIf="!this.option"
  >
    <img
      class="icon_close"
      src="../../assets/icon/home-menu/arrow_back.png"
      alt=""
      srcset=""
    />
  </div>
  <div id="circle_right" class="circle" (click)="this.close.emit(false)">
    <img
      class="icon_close"
      src="../../assets/icon/close.svg"
      alt=""
      srcset=""
    />
  </div>
  <div class="form">
    <p class="label" style="margin-top: 0px; float: none" *ngIf="!this.option">
      Device name: {{ this.name }}
    </p>
    <p
      class="label"
      *ngIf="
        !this.option &&
        (this.option_type_input == 'change' ||
          this.option_type_input == 'share')
      "
    >
      {{ this.title }}
    </p>
    <p
      class="required"
      *ngIf="
        !this.option &&
        (this.option_type_input == 'change' ||
          this.option_type_input == 'share')
      "
    >
      *
    </p>
    <input
      id="option_input"
      type="text"
      class="input"
      *ngIf="
        !this.option &&
        (this.option_type_input == 'change' ||
          this.option_type_input == 'share')
      "
    />
    <p class="label" style="margin-top: 0px" *ngIf="!this.option">
      {{ this.label }}
    </p>
    <div>
      <button
        class="submit"
        *ngIf="this.option_type_input == 'change' || this.option"
        (click)="this.change_view('change')"
      >
        Rename
      </button>
      <button
        class="submit"
        *ngIf="this.option_type_input == 'share' || this.option"
        (click)="this.change_view('share')"
      >
        Share
      </button>
      <button
        id="delete_button"
        class="submit"
        *ngIf="
          (this.option_type_input == 'delete' || this.option) &&
          !this.delete_active
        "
        (click)="this.change_view('delete')"
      >
        Delete
      </button>
      <button
        id="delete_button"
        class="submit delete"
        *ngIf="
          (this.option_type_input == 'delete' || this.option) &&
          this.delete_active
        "
        (click)="this.delete()"
      >
        I'm sure
      </button>
    </div>
  </div>
</div>
