export class BatteryEvent {
  public _time: number = 0;
  public sw: number = 0;
  constructor() {}
}

export enum EventType {
  EventTypeInfo = 0,
  EventTypeWarning = 1,
  EventTypeError = 2,
}

/// A struct to store exactly one event's data.
export class EventLog {
  public date: Date = new Date();
  public type: EventType = EventType.EventTypeError;
  public code: number = 0;
  public value: boolean = false;
  public normal: boolean = false;
  constructor(
    date: Date,
    type: EventType,
    code: number,
    value: boolean,
    normal: boolean
  ) {
    this.date = date;
    this.type = type;
    this.code = code;
    this.value = value;
    this.normal = normal;
  }
}

export class EventErrorCode {
  static UNDERVOLTAGE_CODE: number = 0x00100;
  static OVERVOLTAGE_CODE: number = 0x00200;
  static PEC_ERROR_CODE: number = 0x00400;
  static CONTACTOR_ERROR_CODE: number = 0x00000;
  static UNBALANCED_CODE: number = 0x00800;
  static OVER_DISCHARGING_TEMPERATURE_CODE: number = 0x01000;
  static UNDER_DISCHARGING_TEMPERATURE_CODE: number = 0x02000;
  static UNDER_CHARGING_TEMPERATURE_CODE: number = 0x08000;
  static ENABLE_CHARGE_CODE: number = 0x00000;
  static ENABLE_DISCHARGE_CODE: number = 0x00010;
  static OVER_CHARGING_TEMPERATURE_CODE: number = 0x04000;
  static CHARGE_HYSTERESIS_CODE: number = 0x00000;
  static POWER_ON_CODE: number = 0x00000;
  static ENABLE_BATTERY_CODE: number = 0x00000;
  static CURRENT_IN_RANGE_CODE: number = 0x10000;
  static REACHED_ABSOLUTE_VOLTAGE_LIMITS_CODE: number = 0x20000;
}

export class EventObj {
  public EventDescriptionTable = new Map([
    [0x1, 'Canbus Timeout'],
    [EventErrorCode.ENABLE_DISCHARGE_CODE, 'Enable discharge'],
    [0x20, 'Discharging'],
    [0x40, 'Turn OFF'],
    [0x80, 'Turn ON'],
    [EventErrorCode.UNDERVOLTAGE_CODE, 'Undervoltage'],
    [EventErrorCode.OVERVOLTAGE_CODE, 'Overvoltage'],
    [EventErrorCode.PEC_ERROR_CODE, 'PEC error'],
    [EventErrorCode.UNBALANCED_CODE, 'Unbalanced'],
    [
      EventErrorCode.OVER_DISCHARGING_TEMPERATURE_CODE,
      'Over discharging temperature',
    ],
    [
      EventErrorCode.UNDER_DISCHARGING_TEMPERATURE_CODE,
      'Under discharging temperature',
    ],
    [
      EventErrorCode.OVER_CHARGING_TEMPERATURE_CODE,
      'Over charging temperature',
    ],
    [
      EventErrorCode.UNDER_CHARGING_TEMPERATURE_CODE,
      'Under charging temperature',
    ],
    [EventErrorCode.CURRENT_IN_RANGE_CODE, 'Current in range'],
    [
      EventErrorCode.REACHED_ABSOLUTE_VOLTAGE_LIMITS_CODE,
      'Reached absolute voltage limits',
    ],
  ]);

  EventShortDescriptionTable = new Map([
    [0x1, 'CTO'],
    [EventErrorCode.ENABLE_DISCHARGE_CODE, 'ED'],
    [0x20, 'DS'],
    [0x40, 'OFF'],
    [0x80, 'ON'],
    [EventErrorCode.UNDERVOLTAGE_CODE, 'UV'],
    [EventErrorCode.OVERVOLTAGE_CODE, 'OV'],
    [EventErrorCode.PEC_ERROR_CODE, 'PEC'],
    [EventErrorCode.UNBALANCED_CODE, 'UB'],
    [EventErrorCode.OVER_DISCHARGING_TEMPERATURE_CODE, 'ODT'],
    [EventErrorCode.UNDER_DISCHARGING_TEMPERATURE_CODE, 'UDT'],
    [EventErrorCode.OVER_CHARGING_TEMPERATURE_CODE, 'OCT'],
    [EventErrorCode.UNDER_CHARGING_TEMPERATURE_CODE, 'UCT'],
    [EventErrorCode.CURRENT_IN_RANGE_CODE, 'CIR'],
    [EventErrorCode.REACHED_ABSOLUTE_VOLTAGE_LIMITS_CODE, 'RAVL'],
  ]);

  public EventCodes = [
    EventErrorCode.UNDERVOLTAGE_CODE,
    EventErrorCode.OVERVOLTAGE_CODE,
    EventErrorCode.PEC_ERROR_CODE,
    EventErrorCode.CONTACTOR_ERROR_CODE,
    EventErrorCode.UNBALANCED_CODE,
    EventErrorCode.OVER_DISCHARGING_TEMPERATURE_CODE,
    EventErrorCode.UNDER_DISCHARGING_TEMPERATURE_CODE,
    EventErrorCode.UNDER_CHARGING_TEMPERATURE_CODE,
    EventErrorCode.ENABLE_CHARGE_CODE,
    EventErrorCode.ENABLE_DISCHARGE_CODE,
    EventErrorCode.OVER_CHARGING_TEMPERATURE_CODE,
    EventErrorCode.CHARGE_HYSTERESIS_CODE,
    EventErrorCode.POWER_ON_CODE,
    EventErrorCode.ENABLE_BATTERY_CODE,
    EventErrorCode.CURRENT_IN_RANGE_CODE,
    EventErrorCode.REACHED_ABSOLUTE_VOLTAGE_LIMITS_CODE,
  ];
  static EventDescriptionTable: any;

  constructor() {}
}
