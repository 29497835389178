/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { DeviceUUID } from 'device-uuid';

export class DeviceID {
  constructor() {}

  createDeviceID(user: string, password: string) {
    var du = new DeviceUUID().parse();
    var dua = [
      // 0
      du.language,
      // 1
      du.cpuCores,
      // 2
      du.pixelDepth,
      // 3
      du.browser,
      // 4
      du.version,
      // 5
      user,
      // 6
      password,
    ];
    var uuid = du.hashMD5(dua.join(':'));
    return uuid;
  }
}
