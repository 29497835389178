/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { intervalType } from './../../../model/app.record_map';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  OnDestroy,
} from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';
import {
  RecordMap,
  BatteryHistory,
  Statistics,
  range,
  calculateRangeLimit,
  intervalRange,
} from 'src/model/app.record_map';
import { Chart } from 'src/model/chart/Chart';
import { Swipe, swipeDirection, swipeEvent } from 'src/model/app.swipe';
import { AppDate } from 'src/model/app.date';
import { Command } from 'src/app/dashboard-menu/command';
import { BatteryListComponent } from 'src/app/battery-list/battery-list.component';
import { DeviceManager } from 'src/model/app.deviceManager';
@Component({
  selector: 'app-battery-chart',
  templateUrl: './battery-chart.component.html',
  styleUrls: ['./battery-chart.component.css'],
})
export class BatteryChartComponent implements OnInit, AfterViewInit, OnDestroy {
  private _update: boolean = false;
  @Input() public set update(value: boolean) {
    this._update = value;
    if (this._update) {
      this.setGraphs(this.interval, this.offset);
    }
    this._update = false;
  }

  public intervalType = intervalType;

  public stat: Statistics = new Statistics();

  public offset: number = 0;
  public increment: number = 0;
  public interval: intervalType = intervalType.Hourly;

  public chartHeight: number = 0;

  public chartCurrent: Chart = new Chart('#e7624fff', '#2B2B2B', '#694342bf');
  public chartVoltage: Chart = new Chart('#4ca0d4ff', '#2B2B2B', '#314d5abf');
  public chartSoc: Chart = new Chart('#75cba1ff', '#2B2B2B', '#355449bf');
  public chartTemperature: Chart = new Chart(
    '#e8ca6eff',
    '#2B2B2B',
    '#60563ebf'
  );
  public chartDeltaVoltage: Chart = new Chart(
    '#ce93d8ff',
    '#2B2B2B',
    '#56384bbf'
  );

  public labelCurrent: string = '';
  public labelVoltage: string = '';
  public labelSoc: string = '';
  public labelTemperature: string = '';
  public labelDeltaVoltage: string = '';

  public labelCurrentRange: string = '';
  public labelVoltageRange: string = '';
  public labelSocRange: string = '';
  public labelTemperatureRange: string = '';
  public labelDeltaVoltageRange: string = '';

  private dateFormat: string = AppDate.format_hour2_minute2;

  private seriesX: number[] = [];
  private seriesTemperatureY: number[] = [];
  private seriesCurrentY: number[] = [];
  private seriesSocY: number[] = [];
  private seriesVoltageY: number[] = [];
  private seriesDeltaVoltageY: number[] = [];

  private seriesStatus: boolean[] = [];

  public time_unit: string = 'Last hour';

  public recordMap: RecordMap = new RecordMap();
  private chartCurrentMax: number = -100.0;
  private chartCurrentMin: number = 100.0;
  private chartVoltageMax: number = -100.0;
  private chartVoltageMin: number = 100.0;
  private chartDeltaVoltageMax: number = -100.0;
  private chartDeltaVoltageMin: number = 100.0;
  private chartTemperatureMax: number = -100.0;
  private chartTemperatureMin: number = 100.0;

  public swipeObj = new Swipe();
  public swipeEvent = swipeEvent;

  public smallScreen: boolean = false;
  public canvas_col_span: number = 1;

  public menu_string_option: string[] = ['sync'];
  public menu_options: boolean[] = [false];
  public menu_icons: string[] = ['../../assets/icon/advanced/sync_white.svg'];

  public chartInterval: any;

  constructor(
    public app: AppRoutingModule,
    public http: HttpClient,
    public devMan: DeviceManager,
    public b_list: BatteryListComponent
  ) {
    this.onResize();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.setGraphs(this.interval, this.offset);
    this.chartInterval = setInterval(() => {
      this.setGraphs(this.interval, this.offset);
    }, 1 * 60 * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.chartInterval);
  }
  public onResize() {
    var width = window.innerWidth;
    var height = window.innerHeight / 3;
    //delete swipe icons on small screem
    if (width < 700) {
      this.canvas_col_span = 3;
      this.smallScreen = true;
    } else {
      this.canvas_col_span = 1;
      this.smallScreen = false;
    }
    //change graphs ratio on small screen
    if (width <= height) {
      this.chartHeight = width;
    } else {
      this.chartHeight = height;
    }
  }

  swipe(e: TouchEvent, when: swipeEvent): void {
    var swipe: swipeDirection | null = this.swipeObj.swipe(e, when);
    let offset = this.offset;
    if (swipe != null) {
      switch (swipe) {
        case swipeDirection.previous:
          offset--;
          break;
        case swipeDirection.next:
          offset++;
          break;
      }
      this.setGraphs(this.interval, offset);
    }
  }

  private markInterval(interval: intervalType) {
    let th = ['H', 'D', 'W', 'M'];
    let first_line = document.getElementById('first_line')! as HTMLTableElement;
    let second_line = document.getElementById(
      'second_line'
    )! as HTMLTableElement;
    let third_line = document.getElementById('third_line')! as HTMLTableElement;
    first_line.style.color = 'white';
    second_line.style.color = 'white';
    third_line.style.color = 'white';

    th.forEach((x) => {
      let temp = document.getElementById(x)! as HTMLTableElement;
      temp.style.backgroundColor = '#565556';
      temp.style.borderColor = '#565556';
    });

    let temp;
    switch (interval) {
      case intervalType.Hourly:
        temp = document.getElementById('H')! as HTMLTableElement;
        temp.style.backgroundColor = '#50A17C';
        temp.style.borderColor = '#50A17C';
        first_line.style.color = '#565556';
        break;
      case intervalType.Daily:
        temp = document.getElementById('D')! as HTMLTableElement;
        temp.style.backgroundColor = '#50A17C';
        temp.style.borderColor = '#50A17C';
        first_line.style.color = '#565556';
        second_line.style.color = '#565556';
        break;
      case intervalType.Weakly:
        temp = document.getElementById('W')! as HTMLTableElement;
        temp.style.backgroundColor = '#50A17C';
        temp.style.borderColor = '#50A17C';
        third_line.style.color = '#565556';
        second_line.style.color = '#565556';
        break;
      case intervalType.Montly:
        temp = document.getElementById('M')! as HTMLTableElement;
        temp.style.backgroundColor = '#50A17C';
        temp.style.borderColor = '#50A17C';
        third_line.style.color = '#565556';
        break;
    }
  }

  public setGraphs(interval: intervalType, offset: number) {
    if (this.offset != offset || this.interval != interval) {
      this.clearChartBalloon();
    }
    this.interval = interval;
    this.offset = offset;
    if (this.offset > 0) {
      this.offset = 0;
    }

    let calcRange = calculateRangeLimit(this.interval, this.offset);
    this.recordMap.dropRecords();
    this.markInterval(interval);
    switch (interval) {
      case intervalType.Hourly:
        this.increment = intervalRange.Hour;
        this.dateFormat = AppDate.format_hour2_minute2;
        break;
      case intervalType.Daily:
        this.increment = intervalRange.Day;
        this.dateFormat = AppDate.formatHour;
        break;
      case intervalType.Weakly:
        this.increment = intervalRange.Week;
        this.dateFormat = AppDate.formatWeek;
        break;
      case intervalType.Montly:
        this.increment = intervalRange.Month;
        this.dateFormat = AppDate.formatDay;
        break;
    }
    this.increment /= 200;

    this.http
      .post<BatteryHistory[]>(this.app.server_domain + '/battery.php', {
        request: 'device_battery_history',
        token: this.app.token,
        sn: this.devMan.device.sn,
        num_battery: this.devMan.battery_num,
        start_date: Math.floor(calcRange.start.getTime() / 1000),
        end_date: Math.floor(calcRange.end.getTime() / 1000),
        increment: this.increment,
      })
      .subscribe(
        (result) => {
          this.recordMap.emitSample(result);
          let chart_points: { ret: range; from: Date; to: Date };
          chart_points = this.recordMap.getPoints(this.interval, this.offset);
          this.stat = this.recordMap.getStatistics(chart_points.ret);
          this.clearSeries();
          this.clearChart();
          let increment = RecordMap.getIncrement(this.interval);
          let start = new Date(chart_points.from);
          for (let item of chart_points.ret) {
            this.seriesX.push(Chart.dateTimeToKey(start));
            this.seriesCurrentY.push(
              item[1].isEmpty ? NaN : Number(item[1].current)
            );
            this.seriesVoltageY.push(
              item[1].isEmpty ? NaN : Number(item[1].voltage)
            );
            this.seriesSocY.push(item[1].isEmpty ? NaN : Number(item[1].soc));
            this.seriesTemperatureY.push(
              item[1].isEmpty ? NaN : Number(item[1].temperature)
            );
            this.seriesDeltaVoltageY.push(
              item[1].isEmpty ? NaN : Number(item[1].deltaVoltage)
            );
            this.seriesStatus.push(item[1].isEmpty ? false : item[1].timeout);
            start.setSeconds(+increment);
          }
          this.chartCurrent.setData(this.seriesX, this.seriesCurrentY);
          this.chartCurrent.setStatus(this.seriesStatus);
          this.chartVoltage.setData(this.seriesX, this.seriesVoltageY);
          this.chartVoltage.setStatus(this.seriesStatus);
          this.chartSoc.setData(this.seriesX, this.seriesSocY);
          this.chartSoc.setStatus(this.seriesStatus);
          this.chartTemperature.setData(this.seriesX, this.seriesTemperatureY);
          this.chartTemperature.setStatus(this.seriesStatus);
          this.chartDeltaVoltage.setData(
            this.seriesX,
            this.seriesDeltaVoltageY
          );
          this.chartDeltaVoltage.setStatus(this.seriesStatus);
          this.calculateLimits(this.stat);
          this.refreshPlot(chart_points.from, chart_points.to);
          this.chartCurrent.draw(
            'line_graph',
            this.chartHeight,
            this.dateFormat
          );
          this.chartVoltage.draw(
            'line_graph1',
            this.chartHeight,
            this.dateFormat
          );
          this.chartSoc.draw('line_graph2', this.chartHeight, this.dateFormat);
          this.chartTemperature.draw(
            'line_graph3',
            this.chartHeight,
            this.dateFormat
          );
          this.chartDeltaVoltage.draw(
            'line_graph4',
            this.chartHeight,
            this.dateFormat
          );
        },
        (error) => {
          console.error(error);
        }
      );
  }

  private clearSeries() {
    this.seriesX = [];
    this.seriesCurrentY = [];
    this.seriesVoltageY = [];
    this.seriesSocY = [];
    this.seriesTemperatureY = [];
    this.seriesDeltaVoltageY = [];

    this.seriesStatus = [];
  }
  private clearChart() {
    this.chartCurrent.clearData();
    this.chartVoltage.clearData();
    this.chartSoc.clearData();
    this.chartTemperature.clearData();
    this.chartDeltaVoltage.clearData();
  }

  private clearChartBalloon() {
    this.chartCurrent.clearBalloon();
    this.chartVoltage.clearBalloon();
    this.chartSoc.clearBalloon();
    this.chartTemperature.clearBalloon();
    this.chartDeltaVoltage.clearBalloon();
  }

  private calculateLimits(stats: Statistics) {
    if (
      Number(Number(stats.max_current) - this.chartCurrentMax) >= 0 ||
      Number(this.chartCurrentMax - Number(stats.max_current)) >= 5
    ) {
      this.chartCurrentMax = Number(
        (Math.floor(stats.max_current / 5.0) + 1.0) * 5.0
      );
    }

    if (
      Number(Number(stats.min_current) - this.chartCurrentMin) <= 0 ||
      Number(this.chartCurrentMin - Number(stats.min_current)) <= -5
    ) {
      this.chartCurrentMin = Number(
        (Math.floor(stats.min_current / 5.0) - 1.0) * 5.0
      );
    }

    if (
      Number(Number(stats.max_voltage) - this.chartVoltageMax) > 0 ||
      Number(this.chartVoltageMax - Number(stats.max_voltage)) >= 5
    ) {
      this.chartVoltageMax = Number(
        (Math.floor(stats.max_voltage / 5.0) + 1.0) * 5.0
      );
    }

    if (
      Number(Number(stats.min_voltage) - this.chartVoltageMin) <= 0 ||
      Number(this.chartVoltageMin - Number(stats.min_voltage)) <= -5
    ) {
      this.chartVoltageMin = Number(
        (Math.floor(stats.min_voltage / 5.0) - 1.0) * 5.0
      );
      if (this.chartVoltageMin < 0.0) {
        this.chartVoltageMin = 0.0;
      }
    }

    if (
      Number(Number(stats.max_temperature) - this.chartTemperatureMax) > 0 ||
      Number(this.chartTemperatureMax - Number(stats.max_temperature)) >= 5
    ) {
      this.chartTemperatureMax = Number(
        (Math.floor(stats.max_temperature / 5.0) + 1.0) * 5.0
      );
    }

    if (
      Number(Number(stats.min_temperature) - this.chartTemperatureMin) <= 0 ||
      Number(this.chartTemperatureMin - Number(stats.min_temperature)) <= -5
    ) {
      this.chartTemperatureMin = Number(
        (Math.floor(stats.min_temperature / 5.0) - 1.0) * 5.0
      );
    }

    if (
      Number(Number(stats.max_deltavoltage) - this.chartDeltaVoltageMax) > 0 ||
      Number(this.chartDeltaVoltageMax - Number(stats.max_deltavoltage)) >= 5
    ) {
      this.chartDeltaVoltageMax = Number(
        (Math.floor(stats.max_deltavoltage / 5.0) + 1.0) * 5.0
      );
    }

    if (
      Number(Number(stats.min_deltavoltage) - this.chartDeltaVoltageMin) <= 0 ||
      Number(this.chartDeltaVoltageMin - Number(stats.min_deltavoltage)) <= -5
    ) {
      this.chartDeltaVoltageMin = Number(
        (Math.floor(stats.min_deltavoltage / 5.0) - 1.0) * 5.0
      );
      if (this.chartDeltaVoltageMin < 0.0) {
        this.chartDeltaVoltageMin = 0.0;
      }
    }
  }
  private refreshPlot(chartFrom: Date, chartTo: Date) {
    this.refreshLabels(chartFrom, chartTo);

    this.chartCurrent.tickOriginX = Chart.dateTimeToKey(chartFrom);
    this.chartCurrent.tickOriginY = this.chartCurrentMin;
    this.chartCurrent.setRangeX(
      Chart.dateTimeToKey(chartFrom),
      Chart.dateTimeToKey(chartTo)
    );
    this.chartCurrent.setRangeY(this.chartCurrentMin, this.chartCurrentMax);

    this.chartVoltage.tickOriginX = Chart.dateTimeToKey(chartFrom);
    this.chartVoltage.tickOriginY = this.chartVoltageMin;
    this.chartVoltage.setRangeX(
      Chart.dateTimeToKey(chartFrom),
      Chart.dateTimeToKey(chartTo)
    );
    this.chartVoltage.setRangeY(this.chartVoltageMin, this.chartVoltageMax);

    this.chartSoc.tickOriginX = Chart.dateTimeToKey(chartFrom);
    this.chartSoc.setRangeX(
      Chart.dateTimeToKey(chartFrom),
      Chart.dateTimeToKey(chartTo)
    );
    this.chartSoc.setRangeY(0, 100);

    this.chartTemperature.tickOriginX = Chart.dateTimeToKey(chartFrom);
    this.chartTemperature.tickOriginY = this.chartTemperatureMin;
    this.chartTemperature.setRangeX(
      Chart.dateTimeToKey(chartFrom),
      Chart.dateTimeToKey(chartTo)
    );
    this.chartTemperature.setRangeY(
      this.chartTemperatureMin,
      this.chartTemperatureMax
    );

    this.chartDeltaVoltage.tickOriginX = Chart.dateTimeToKey(chartFrom);
    this.chartDeltaVoltage.tickOriginY = this.chartDeltaVoltageMin;
    this.chartDeltaVoltage.setRangeX(
      Chart.dateTimeToKey(chartFrom),
      Chart.dateTimeToKey(chartTo)
    );
    this.chartDeltaVoltage.setRangeY(
      this.chartDeltaVoltageMin,
      this.chartDeltaVoltageMax
    );
  }

  private refreshLabels(chartFrom: Date, chartTo: Date) {
    let start: string = '';
    let end: string = '';

    let tmpDate: Date = new Date();
    let formatBase: string = '';
    let formatStart: string = '';
    let currentYear: number = 0;
    let endYear: number = 0;

    switch (this.interval) {
      case intervalType.Hourly:
        if (this.offset == 0) {
          this.labelCurrentRange = 'Last hour';
          this.labelVoltageRange = 'Last hour';
          this.labelSocRange = 'Last hour';
          this.labelTemperatureRange = 'Last hour';
          this.labelDeltaVoltageRange = 'Last hour';
          return;
        }
        tmpDate = new Date(chartTo);
        tmpDate.setUTCSeconds(+60);

        currentYear = new Date().getUTCFullYear();
        endYear = tmpDate.getUTCFullYear();

        formatBase =
          currentYear == endYear
            ? AppDate.format_day2_monthshort_hour2_minute2
            : AppDate.format_day2_monthshort_yearnum_hour2_minute2;

        start = AppDate.stringFromDate(chartFrom, formatBase);
        end = AppDate.stringFromDate(tmpDate, formatBase);
        this.labelCurrentRange = start + ' - ' + end;
        this.labelVoltageRange = start + ' - ' + end;
        this.labelSocRange = start + ' - ' + end;
        this.labelTemperatureRange = start + ' - ' + end;
        this.labelDeltaVoltageRange = start + ' - ' + end;
        break;
      case intervalType.Daily:
        if (this.offset == 0) {
          this.labelCurrentRange = 'Today';
          this.labelVoltageRange = 'Today';
          this.labelSocRange = 'Today';
          this.labelTemperatureRange = 'Today';
          this.labelDeltaVoltageRange = 'Today';
          return;
        }
        if (this.offset == -1) {
          this.labelCurrentRange = 'Yesterday';
          this.labelVoltageRange = 'Yesterday';
          this.labelSocRange = 'Yesterday';
          this.labelTemperatureRange = 'Yesterday';
          this.labelDeltaVoltageRange = 'Yesterday';
          return;
        }
        currentYear = new Date().getUTCFullYear();
        endYear = chartTo.getUTCFullYear();

        formatBase =
          currentYear == endYear
            ? AppDate.format_day2_monthshort
            : AppDate.format_day2_monthshort_yearnum;

        start = AppDate.stringFromDate(chartFrom, formatBase);
        this.labelCurrentRange = start;
        this.labelVoltageRange = start;
        this.labelSocRange = start;
        this.labelTemperatureRange = start;
        this.labelDeltaVoltageRange = start;
        break;

      case intervalType.Weakly:
        tmpDate = new Date(chartTo);
        let startMonth = chartFrom.getUTCMonth();
        let endMonth = tmpDate.getUTCMonth();
        let startYear = chartFrom.getUTCFullYear();
        endYear = tmpDate.getUTCFullYear();

        formatBase =
          startYear == endYear
            ? AppDate.format_day2_monthshort
            : AppDate.format_day2_monthshort_yearnum;
        formatStart = startMonth == endMonth ? AppDate.formatDay : formatBase;

        start = AppDate.stringFromDate(chartFrom, formatStart);
        let tmpEnd = new Date(Date.parse(tmpDate.toString()) - 24 * 60 * 60 * 1000);
        end = AppDate.stringFromDate(
          tmpEnd,
          AppDate.format_day2_monthshort_yearnum
        );
        this.labelCurrentRange = start + ' - ' + end;
        this.labelVoltageRange = start + ' - ' + end;
        this.labelSocRange = start + ' - ' + end;
        this.labelTemperatureRange = start + ' - ' + end;
        this.labelDeltaVoltageRange = start + ' - ' + end;
        break;
      case intervalType.Montly:
        start = AppDate.stringFromDate(
          chartFrom,
          AppDate.format_monthshort_yearnum
        );
        this.labelCurrentRange = start;
        this.labelVoltageRange = start;
        this.labelSocRange = start;
        this.labelTemperatureRange = start;
        this.labelDeltaVoltageRange = start;
        break;
    }
  }
  public command(command: Command = new Command('', [false])) {
    this.b_list.sync = command.active[0];
    switch (command.command) {
      case 'sync':
        this.b_list.synchronize();
        break;
    }
  }
}
