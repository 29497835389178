/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

export class DataLogin {
  public user: string = '';
  public password: string = '';

  public user_message: boolean = false;
  public password_message: boolean = false;

  constructor() {}

  required(): boolean {
    this.user = (
      document.getElementById('user')! as HTMLInputElement
    ).value.trim();
    this.password = (
      document.getElementById('password')! as HTMLInputElement
    ).value.trim();

    if (this.user == '') {
      this.user_message = true;
    } else {
      this.user_message = false;
    }
    if (this.password == '') {
      this.password_message = true;
    } else {
      this.password_message = false;
    }
    return !this.user_message && !this.password_message;
  }
}
