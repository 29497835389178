import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { HomeComponent } from 'src/app/home/home.component';
import { DeviceManager, DeviceMethods } from 'src/model/app.deviceManager';

@Component({
  selector: 'app-advanced-option',
  templateUrl: './advanced-option.component.html',
  styleUrls: ['./advanced-option.component.css'],
})
export class AdvancedOptionComponent implements OnInit, OnChanges {
  // 0 datalogger 1 battery
  @Input() type: number = 0;

  @Input() view_option: boolean = false;
  @Output() close = new EventEmitter<boolean>();
  @Output() command = new EventEmitter<string>();

  public button_index: number[] = [
    toolType.settings,
    toolType.gpio,
    toolType.console,
  ];

  public tools_imgs: string[] = [
    '../../assets/icon/advanced/parameter.svg',
    '../../assets/icon/advanced/gpio.svg',
    '../../assets/icon/advanced/console.svg',
  ];
  public tools_imgs_over: string[] = [
    '../../assets/icon/advanced/parameter_white.svg',
    '../../assets/icon/advanced/gpio_white.svg',
    '../../assets/icon/advanced/console_white.svg',
  ];
  public is_over: boolean[] = [false, false, false];
  public tools_title: string[] = ['Device settings', 'GPIO Control', 'Console'];
  public tools_label: string[] = [
    'Modify device parameters',
    'Configure read/write GPIO',
    'Available for manufacturer on computer screens',
  ];

  public disable: boolean[] = [false, false, false];

  public dev_methods: DeviceMethods = new DeviceMethods();

  constructor(public home: HomeComponent, public devMan: DeviceManager) {}
  ngOnChanges(): void {
    this.set_option();
  }

  public set_option() {
    if (this.type == 0) {
      if (this.dev_methods.deviceLinux(this.devMan.device.sn.substring(0, 2))) {
        this.button_index = [
          toolType.settings,
          toolType.gpio,
          toolType.console,
        ];
      } else {
        this.button_index = [toolType.settings, toolType.gpio];
      }
    } else this.button_index = [toolType.settings, toolType.gpio];
    // Disable tools that are not implemented
    this.disable[toolType.gpio] = true;
    this.disable_settings();
    this.disable_console();
  }

  ngOnInit(): void {}

  public clickCommand(index: number) {
    let command = this.tools_title[index];
    if (!this.disable[index]) {
      this.command.emit(command);
      this.close.emit(false);
    }
  }

  public disable_settings() {
    if (this.home.is_developer) {
      this.disable[toolType.settings] = false;
      return;
    }
    if (this.home.user_type != 'Manufacturer') {
      this.disable[toolType.settings] = true;
    } else {
      this.disable[toolType.settings] = false;
    }
  }
  public disable_console() {
    let w = window.innerWidth;
    if (w <= 1200) {
      this.disable[toolType.console] = true;
      return;
    }
    if (this.home.is_developer) {
      this.disable[toolType.console] = false;
      return;
    }
    if (this.home.user_type != 'Manufacturer') {
      this.disable[toolType.console] = true;
    } else {
      this.disable[toolType.console] = false;
    }
  }
}

enum toolType {
  console = 2,
  gpio = 1,
  settings = 0,
}
