/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HttpClient } from '@angular/common/http';
import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { Command } from '../dashboard-menu/command';
import { Map } from '../../model/app.map';
import { HomeComponent } from '../home/home.component';
import { AppCollapsible } from 'src/model/app.collapsible';
import {
  Device,
  DeviceManager,
  DeviceMethods,
} from 'src/model/app.deviceManager';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.css'],
})
@Injectable({
  providedIn: 'root',
})
export class DeviceListComponent implements OnInit, OnDestroy {
  public devices_list: Device[] = [];
  public add_device: boolean = false;

  public menu_string_option: string[] = [
    'add_device',
    'online',
    'offline',
    'map',
  ];
  public menu_options: boolean[] = [false, false, false, false];
  public is_active: boolean[] = [false, false, false, false];
  public menu_icons: string[] = [
    '../../assets/icon/device-list/device_list_menu/add_device.svg',
    '../../assets/icon/device-list/device_list_menu/offline.svg',
    '../../assets/icon/device-list/device_list_menu/online.svg',
    '../../assets/icon/device-list/device_list_menu/map.svg',
  ];

  public dashboard_message: string = '';

  public interval_dashboard: any;

  public view_option: boolean = false;
  public view_option_name: string = '';
  public view_option_sn: string = '';
  public option: boolean = false;
  public option_type: string = '';

  public last_row_option_opened: string = '';

  public dev: Device = new Device();
  public dev_methods = new DeviceMethods();
  public map: Map = new Map();

  constructor(
    public app: AppRoutingModule,
    public http: HttpClient,
    public home: HomeComponent,
    public devMan: DeviceManager
  ) {
    this.get_devices_list();
  }

  ngOnInit(): void {
    this.interval_dashboard = setInterval(() => {
      this.get_devices_list();
    }, 9 * 60 * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval_dashboard);
  }

  /**
   *
   * @returns devices list that user will see
   */
  public devices_list_filter() {
    let list: Device[] = [];
    list = this.devices_list;
    if (this.is_active[1]) {
      list = list.filter((x) => x.is_online != true);
    }
    if (this.is_active[2]) {
      list = list.filter((x) => x.is_online == true);
    }
    let search = (
      document.getElementById('search_devices_list_input')! as HTMLInputElement
    ).value
      .trim()
      .toLowerCase();
    if (search != '') {
      list = list.filter(
        (x) =>
          x.alias.toLowerCase().includes(search) ||
          x.sn.toLowerCase().includes(search)
      );
    }
    return list;
  }

  public updateDevice(device: Device) {
    this.devMan.resetBatteries();
    this.devMan.device = device;
    this.devMan.get_number_of_batteries(this.devMan.device.sn);
  }

  /**
   * Check type of command arrived from dashboard-menu component
   *
   * If you don't give parameter he set command for total device
   * @param command type of command and if is activated or deactivated
   */
  public command(
    command: Command = new Command('', [false, false, false, false])
  ) {
    this.is_active = command.active;
    switch (command.command) {
      case 'add_device':
        this.add_device = !this.add_device;
        this.is_active[0] = false;
        break;
      case 'map':
        let bodyHeight = '';
        AppCollapsible.height_collapsible(
          'device_list_fixed_table',
          'devices_list_map'
        );
        let map_height = (
          document.getElementById('devices_list_map')! as HTMLDivElement
        ).style.maxHeight;
        let body = document.getElementById(
          'device_list_body'
        )! as HTMLTableElement;
        console.log(Number(map_height));
        if (Number(map_height) != 0) {
          bodyHeight =
            'calc(' + window.innerHeight + 'px - 200px - ' + map_height + ')';
        } else {
          bodyHeight = 'calc(' + window.innerHeight + 'px - 200px )';
        }
        body.style.height = bodyHeight;
        this.update_map();
        break;
      case 'offline':
      case 'online':
        this.update_map();
        break;
      default:
        this.update_message();
    }
  }

  private update_message() {
    let total = this.devices_list.length;
    let online = this.devices_list.filter((x) => x.is_online == true).length;

    this.dashboard_message = 'Total: ' + total;
    this.dashboard_message += ', Online: ' + online;
  }

  /**
   * Request to server user's devices list.
   */
  public get_devices_list() {
    this.http
      .post<Device[]>(this.app.server_domain + '/device.php', {
        request: 'devices_list',
        token: this.app.token,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            this.devices_list = [];
            return;
          }
          this.devices_list = result;
          this.command();
        },
        (error) => {
          console.error(error);
        }
      );
  }

  public get_row_option(alias: string, sn: string) {
    if (this.last_row_option_opened == '') {
      this.last_row_option_opened = alias + '_' + sn + '_collapsed';
    }
    //user close row option open
    else if (this.last_row_option_opened == alias + '_' + sn + '_collapsed') {
      this.last_row_option_opened = '';
    }
    //user select a row option when another is open
    else if (this.last_row_option_opened != alias + '_' + sn + '_collapsed') {
      //close last row option
      AppCollapsible.width_collapsible(
        this.last_row_option_opened,
        210,
        true,
        0
      );
      this.last_row_option_opened = alias + '_' + sn + '_collapsed';
    }
    AppCollapsible.width_collapsible(
      alias + '_' + sn + '_collapsed',
      210,
      true
    );
  }

  public update_map() {
    this.map.clearMap();
    this.devices_list_filter().forEach((x) =>
      this.map.addMarker(x.lat, x.lng, x.sn + '-' + x.alias)
    );
    this.map.setMap();
  }
}
