import { Component } from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';

@Component({
  selector: 'app-account-deleted',
  templateUrl: './account-deleted.component.html',
  styleUrls: ['./account-deleted.component.css'],
})
export class AccountDeletedComponent {
  constructor(public app: AppRoutingModule) {
    this.app.page_access('delete_end');
  }
}
