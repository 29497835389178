/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import {
  Component,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements AfterViewInit {
  public day_of_week: string[] = [
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun',
  ];
  public mL = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  public mS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  /**
   * @param day number of day
   * @param type is day of this month (true) or is day of previus or before month (false)
   */
  public days: { day: number; type: boolean; is_selected: boolean }[] = [];
  public selected: number = 0;
  public date: Date = new Date();
  public format_date: string = '';

  @Input() value: Date | null = null;
  @Input() max: Date | null = null;
  @Input() min: Date | null = null;
  @Output() return = new EventEmitter<Date>();
  @Output() close = new EventEmitter<boolean>();

  constructor() {
    this.calendar();
  }
  ngAfterViewInit(): void {
    if (this.value == null) {
      this.value = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate()
      );
    } else {
      this.date = new Date(
        this.value.getFullYear(),
        this.value.getMonth(),
        this.value.getDate()
      );
    }
    this.calendar();
  }
  public switch(response: string) {
    switch (response) {
      case 'left':
        if (this.min != null) {
          var tmpDate = new Date(
            this.date.getFullYear(),
            this.date.getMonth() - 1
          );
          var tmpDateMin = new Date(
            this.min.getFullYear(),
            this.min.getMonth()
          );
          if (tmpDate.getTime() < tmpDateMin.getTime()) return;
        }
        this.date.setMonth(this.date.getMonth() - 1);
        break;
      case 'right':
        if (this.max != null) {
          var tmpDate = new Date(
            this.date.getFullYear(),
            this.date.getMonth() + 1
          );
          var tmpDateMax = new Date(
            this.max.getFullYear(),
            this.max.getMonth()
          );
          if (tmpDate.getTime() > tmpDateMax.getTime()) return;
        }
        this.date.setMonth(this.date.getMonth() + 1);
        break;
    }
    this.calendar();
  }

  private calendar() {
    this.createCalendar();
    this.setValue();
    this.setMin();
    this.setMax();
  }

  private createCalendar() {
    this.days = [];
    var max_day: number = this.daysInMonth(
      this.date.getMonth(),
      this.date.getFullYear()
    );
    for (let i = 1; i <= max_day; i++) {
      this.days.push({ day: i, type: true, is_selected: false });
    }
    var tmpDate = new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      this.days[0].day
    );
    let num_of_day = this.daysInMonth(
      tmpDate.getMonth() - 1,
      tmpDate.getFullYear()
    );
    for (let i = 1; i < this.formatDay(tmpDate.getDay()); i++) {
      this.days.splice(0, 0, {
        day: num_of_day,
        type: false,
        is_selected: false,
      });
      num_of_day--;
    }
    num_of_day = 1;
    for (let i = this.days.length; i < 42; i++) {
      this.days.push({ day: num_of_day, type: false, is_selected: false });
      num_of_day++;
    }
  }

  /**
   *
   * @param month 0-11
   * @param year
   * @returns number of days in the specific month
   */
  private daysInMonth(month: number, year: number) {
    switch (month) {
      case 3:
      case 5:
      case 8:
      case 10:
        return 30;
      case 1:
        if (year % 4 == 0) {
          return 29;
        }
        return 28;
      default:
        return 31;
    }
  }

  /**
   * Format day from 0-6 (sunday)-(saturday) to 1-7 (monday)-(sunday)
   * @param javascriptDay
   * @returns number of days in the specific month
   */
  private formatDay(javascriptDay: number) {
    //Days in javasrcript go from 0 (sunday) to 6 (saturday) but is not easy to use
    if (javascriptDay == 0) return 7;
    return javascriptDay;
  }

  public select(selected: number, type: boolean) {
    if (!type) return;
    this.days[this.selected].is_selected = false;
    this.selected = selected;
    this.days[this.selected].is_selected = true;
    this.value = new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      this.days[this.selected].day
    );
    this.formatDate();
  }

  private setValue() {
    if (this.value == null) return;
    if (this.date.getFullYear() != this.value.getFullYear()) return;

    if (this.date.getMonth() != this.value.getMonth()) return;
    for (let i = 0; i < this.days.length; i++) {
      if (this.days[i].type) {
        if (this.days[i].day == this.value?.getDate()) {
          this.select(i, true);
        }
      }
    }
  }
  private setMin() {
    if (this.min == null) return;
    if (
      this.date.getFullYear() != this.min.getFullYear() ||
      this.date.getMonth() != this.min.getMonth()
    )
      return;
    for (let i = 0; i < this.days.length; i++) {
      var tmpDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.days[i].day
      );
      if (tmpDate.getTime() < this.min.getTime()) {
        this.days[i].type = false;
      }
    }
  }
  private setMax() {
    if (this.max == null) return;
    if (
      this.date.getFullYear() != this.max.getFullYear() ||
      this.date.getMonth() != this.max.getMonth()
    )
      return;
    for (let i = 0; i < this.days.length; i++) {
      var tmpDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.days[i].day
      );
      if (tmpDate.getTime() > this.max.getTime()) {
        this.days[i].type = false;
      }
    }
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.ok();
    }
  }

  public ok() {
    this.return.emit(this.value!);
    this.close.emit(false);
  }
  private formatDate() {
    if (this.value == null) return;
    let format_date = this.value.toLocaleDateString('it-IT', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    this.format_date = format_date;
  }
}
