<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->
<div id="body" *ngIf="this.app.pages.types[4]">
  <div class="logo">
    <img src="../../assets/logo-archimede-b.png" alt="" />
  </div>
  <div class="central_block" (window:keypress)="this.keyDownFunction($event)">
    <div>
      <p class="title">Account delete</p>
    </div>
    <div class="form" *ngIf="this.presentation">
      <div>
        <p class="text">Welcome to the page to delete your AE account</p>
        <p class="text">We will guide you through the unsubscribe procedure</p>
        <p class="text">
          Please note that a valid AE account email is required for this
          unsubscribe procedure
        </p>
        <p class="label">Email address</p>
        <p class="required">*</p>
        <input
          id="email"
          type="text"
          class="input"
          (input)="this.email.data_required_check[0] = false"
        />
        <p class="message" *ngIf="this.email.data_required_check[0]">
          This field is required
        </p>
        <button class="submit delete" (click)="this.delete()">Delete</button>
      </div>
    </div>
    <div class="form" *ngIf="this.delete_confirm">
      <div>
        <p class="text">We are sorry to see you go</p>
        <p class="text" style="padding-bottom: 40px">
          Check your email to complete the procedure
        </p>
      </div>
    </div>
    <app-warning
      [title]="'Server response'"
      [message]="this.warning_message"
      [background_color]="this.warning_background_color"
      [warning]="this.warning"
      (response)="this.warning = $event"
    ></app-warning>
  </div>
</div>
