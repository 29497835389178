/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppRoutingModule, Message } from '../../app-routing.module';
import { Regex } from '../../../model/Regex';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  public email: string = '';

  public email_message: boolean = false;
  public first_submit: boolean = false;

  public warning_new_password: boolean = false;
  public server_message: string = '';
  public warning_background_color: string = '';

  public end_forgot_password: boolean = false;

  constructor(public app: AppRoutingModule, public http: HttpClient) {
    this.app.page_access('forgot_password');
  }

  ngOnInit(): void {}

  required(): boolean {
    this.email = (
      document.getElementById('email')! as HTMLInputElement
    ).value.trim();

    if (this.first_submit == false) {
      return false;
    }

    if (this.email == '') {
      this.email_message = true;
    } else {
      this.email_message = false;
    }
    return this.email_message;
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }

  onSubmit(): void {
    this.first_submit = true;
    if (
      this.end_forgot_password ||
      (!this.required() && Regex.regex_email(this.email))
    ) {
      this.http
        .post<Message>(this.app.server_domain + '/new_password.php', {
          request: 'new_password',
          email: this.email,
        })
        .subscribe(
          (result) => {
            if (!result.response) {
              this.warning_background_color = 'rgba(255, 86, 86, 0.868)';
              this.server_message = result.message;
              this.warning_new_password = true;
            } else {
              this.end_forgot_password = true;
            }
          },
          (error) => {
            console.error(error);
          }
        );
    } else {
      this.warning_background_color = 'rgba(255, 86, 86, 0.868)';
      this.warning_new_password = true;
      this.server_message =
        'Invalid email. Email can only contain letters and numbers. (Ex:text.44@gmail.com)';
    }
  }
}
