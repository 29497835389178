/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  Input,
} from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';
import {
  BatteryEvent,
  EventErrorCode,
  EventLog,
  EventObj,
  EventType,
} from './event';
import { Command } from 'src/app/dashboard-menu/command';
import { SetDate } from './setDate';
import { AppDate } from 'src/model/app.date';
import { BatteryListComponent } from 'src/app/battery-list/battery-list.component';
import { DeviceManager } from 'src/model/app.deviceManager';

@Component({
  selector: 'app-battery-history',
  templateUrl: './battery-history.component.html',
  styleUrls: ['./battery-history.component.css'],
})
export class BatteryHistoryComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private _update: boolean = false;
  @Input() public set update(value: boolean) {
    this._update = value;
    if (this._update) {
      this.updateHistory();
    }
    this._update = false;
  }
  public events: EventLog[] = [];
  public eventsDescription = new EventObj().EventDescriptionTable;
  public eventsShortDescription = new EventObj().EventShortDescriptionTable;

  public eventsCode = new EventErrorCode();

  public eventType = EventType;
  public date: string = 'Today';
  public max_date: Date = new Date();
  public valueDate: Date = new Date();

  public num_events_filter: number = 0;

  public rows_offset = 0;
  public range_rows: string = '0 - 0';

  public menu_string_option: string[] = ['sync', 'date', 'filter'];
  public menu_options: boolean[] = [false, false, false];
  public calendar: boolean = false;
  public filter: boolean = false;
  public menu_icons: string[] = [
    '../../assets/icon/advanced/sync_white.svg',
    '../../assets/icon/battery-details/clock.svg',
    '../../assets/icon/battery-details/filter.svg',
  ];

  public filters_code: number[] = [
    EventErrorCode.UNDERVOLTAGE_CODE,
    EventErrorCode.OVERVOLTAGE_CODE,
    EventErrorCode.PEC_ERROR_CODE,
    EventErrorCode.UNBALANCED_CODE,
    EventErrorCode.OVER_DISCHARGING_TEMPERATURE_CODE,
    EventErrorCode.UNDER_DISCHARGING_TEMPERATURE_CODE,
    EventErrorCode.ENABLE_DISCHARGE_CODE,
    EventErrorCode.OVER_CHARGING_TEMPERATURE_CODE,
    EventErrorCode.CURRENT_IN_RANGE_CODE,
    EventErrorCode.REACHED_ABSOLUTE_VOLTAGE_LIMITS_CODE,
    EventErrorCode.UNDER_CHARGING_TEMPERATURE_CODE,
  ];

  public active_filters: number[] = [
    EventErrorCode.UNDERVOLTAGE_CODE,
    EventErrorCode.OVERVOLTAGE_CODE,
    EventErrorCode.PEC_ERROR_CODE,
    EventErrorCode.UNBALANCED_CODE,
    EventErrorCode.OVER_DISCHARGING_TEMPERATURE_CODE,
    EventErrorCode.UNDER_DISCHARGING_TEMPERATURE_CODE,
    EventErrorCode.ENABLE_DISCHARGE_CODE,
    EventErrorCode.OVER_CHARGING_TEMPERATURE_CODE,
    EventErrorCode.CURRENT_IN_RANGE_CODE,
    EventErrorCode.REACHED_ABSOLUTE_VOLTAGE_LIMITS_CODE,
    EventErrorCode.UNDER_CHARGING_TEMPERATURE_CODE,
  ];
  public active_checkbox: boolean[] = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ];
  public short_description_filters: string = 'No filters';
  public view_filter: boolean = false;

  public eventsInterval: any;

  constructor(
    public app: AppRoutingModule,
    public http: HttpClient,
    public devMan: DeviceManager,
    public b_list: BatteryListComponent
  ) {
    this.get_battery_history(this.valueDate.getTime());
  }
  ngAfterViewInit(): void {
    this.eventsInterval = setInterval(() => {
      this.updateHistory();
    }, 1 * 60 * 1000);
  }

  ngOnInit(): void {
    this.updateHistory();
  }

  ngOnDestroy(): void {
    clearInterval(this.eventsInterval);
  }

  public updateHistory() {
    this.setDate(this.valueDate);
    this.get_battery_history(this.valueDate.getTime());
  }

  public reset() {
    this.filters_code.forEach((x) => {
      let active = document.getElementById(x.toString())! as HTMLInputElement;
      if (active) {
        active.checked = true;
      }
    });
  }

  /**
   *
   * @param date timestemp
   */
  public get_battery_history(date: number) {
    let start = new Date(date);
    let end = new Date(date);

    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    this.eventsDescription.forEach((x) => {
      x;
    });
    this.http
      .post<BatteryEvent[]>(this.app.server_domain + '/battery.php', {
        request: 'device_battery_event',
        token: this.app.token,
        sn: this.devMan.device.sn,
        num_battery: this.devMan.battery_num,
        start_date: Math.floor(start.getTime() / 1000),
        end_date: Math.floor(end.getTime() / 1000),
      })
      .subscribe(
        (result) => {
          var lastSw: number = 0;
          var events: EventLog[] = [];
          result.forEach((x) => {
            var date: Date = new Date();
            var statusWord: number = 0;

            statusWord = x.sw;
            date = new Date(x._time);

            if (lastSw == 0) {
              lastSw = statusWord;
            }
            var eventCodes = new EventObj().EventCodes;
            for (let i = 0; i < eventCodes.length; i++) {
              if (eventCodes[i] == 0x0) {
                continue;
              }
              let swBit = (statusWord >> i) & 0x1;
              let lsBit = (lastSw >> i) & 0x1;

              if (swBit != lsBit) {
                let eLog = new EventLog(
                  date,
                  EventType.EventTypeError,
                  eventCodes[i],
                  swBit != 0,
                  ((0x7300 >> i) & 0x1) != 0
                );
                events.unshift(eLog);
              }
            }
            lastSw = statusWord;
          });
          this.events = events;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  public apply_filter() {
    this.rows_offset = 0;
    let noFilter = true;
    this.active_filters = [];
    this.active_checkbox = [];
    this.short_description_filters = '';
    this.filters_code.forEach((x) => {
      let active = document.getElementById(x.toString())! as HTMLInputElement;
      if (active) {
        if (active.checked) {
          this.short_description_filters +=
            this.eventsShortDescription.get(x) + ', ';
          this.active_filters.push(x);
          this.active_checkbox.push(true);
        } else {
          this.active_filters.push(NaN);
          this.active_checkbox.push(false);
          noFilter = false;
        }
      } else {
        this.active_filters = [
          EventErrorCode.UNDERVOLTAGE_CODE,
          EventErrorCode.OVERVOLTAGE_CODE,
          EventErrorCode.PEC_ERROR_CODE,
          EventErrorCode.UNBALANCED_CODE,
          EventErrorCode.OVER_DISCHARGING_TEMPERATURE_CODE,
          EventErrorCode.UNDER_DISCHARGING_TEMPERATURE_CODE,
          EventErrorCode.ENABLE_DISCHARGE_CODE,
          EventErrorCode.OVER_CHARGING_TEMPERATURE_CODE,
          EventErrorCode.CURRENT_IN_RANGE_CODE,
          EventErrorCode.REACHED_ABSOLUTE_VOLTAGE_LIMITS_CODE,
          EventErrorCode.UNDER_CHARGING_TEMPERATURE_CODE,
        ];
        this.active_checkbox = [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ];
        this.short_description_filters = 'No filters';
      }
    });

    this.short_description_filters = this.short_description_filters.slice(
      0,
      -2
    );

    if (noFilter) {
      this.short_description_filters = 'No filters';
    }

    this.view_filter = false;
  }

  public event_filter() {
    let start = this.rows_offset * 100;
    let end = this.rows_offset * 100 + 100;
    let list: EventLog[] = [];

    if (this.filter) {
      this.events.forEach((x) => {
        if (this.active_filters.includes(x.code)) {
          list.push(x);
        }
      });
    } else {
      list = this.events.slice();
    }
    if (end > list.length) {
      end = list.length;
    }
    this.range_rows = start + 1 + ' - ' + end;
    if (list.length == 0) {
      this.range_rows = '0 - 0';
    }

    this.num_events_filter = list.length;
    list = list.slice(start, end);
    return list;
  }

  public change_offset(type: boolean) {
    let max = Math.floor(this.num_events_filter / 100);
    if (type) {
      this.rows_offset++;
      if (this.rows_offset > max) {
        this.rows_offset = 0;
      }
      return;
    }
    this.rows_offset--;
    if (this.rows_offset < 0) {
      this.rows_offset = max;
    }
  }

  public event_date(timestemp: number) {
    /*forse *100*/
    let date = new Date(timestemp);
    return AppDate.stringFromDate(
      date,
      AppDate.format_day2_month2_yearnum_hour2_minute2_second2
    );
  }

  private setDate(date: Date) {
    let tmp = new SetDate();
    this.date = AppDate.stringFromDate(date, AppDate.format_day2_month2_yearnum);
  }

  /**
   * Check type of command arrived from dashboard-menu component
   *
   * If you don't give parameter he set command for total device
   * @param command type of command and if is activated or deactivated
   */
  public command(command: Command = new Command('', [false, false, false])) {
    this.b_list.sync = command.active[0];
    this.calendar = command.active[1];
    this.filter = command.active[2];
    switch (command.command) {
      case 'date':
        break;
      case 'filter':
        this.rows_offset = 0;
        break;
      case 'sync':
        this.b_list.synchronize();
        break;
    }
  }
}
