<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div class="block">
  <div>
    <p class="title">Log access</p>
  </div>
  <div>
    <div class="list_message">
      {{ this.dashboard_message }}
    </div>
    <div id="search_log_access_list">
      <img
        class="search"
        src="../../assets/icon/device-list/device_list_menu/search.svg"
        alt=""
      />
      <input
        id="search_log_access_list_input"
        class="input"
        type="text"
        placeholder="Search for a user"
        (input)="this.get_log_access_list()"
      />
    </div>
  </div>
  <div class="fixed_table">
    <table class="list">
      <thead>
        <tr>
          <th class="small_td">ID</th>
          <th>Name</th>
          <th class="username">Username</th>
          <th class="company">Company</th>
          <th class="large_td created_on">Created on</th>
          <th class="large_td">Last activity</th>
        </tr>
      </thead>
      <tbody id="log_access_list_body">
        <tr *ngFor="let user of this.log_access_list_filter(); let i = index">
          <td
            class="small_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ user.account_id }}
          </td>
          <td [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }">
            {{ user.account_name }}
          </td>
          <td
            class="username"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ user.account_username }}
          </td>
          <td
            class="company"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ user.account_company }}
          </td>
          <td
            class="large_td created_on"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ this.formatDate(user.account_date) }}
          </td>
          <td
            class="large_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ this.formatDate(user.max_last_access) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
