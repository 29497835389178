/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './public_component/login/login.component';
import { ForgotPasswordComponent } from './public_component/forgot-password/forgot-password.component';
import { RegistrationComponent } from './public_component/registration/registration.component';
import { HomeComponent } from './home/home.component';
import { Device_shared } from 'src/model/app.device_shared';
import { ChangePasswordComponent } from './public_component/change-password/change-password.component';
import * as config from '../../../../../etc/ae.portal/config.json';
import { AccountDeleteComponent } from './public_component/account-delete/account-delete.component';
import { SwitchType } from 'src/model/app.switchType';
import { DeviceID } from 'src/model/app.device_id';
import { Version } from 'src/version';
import { AccountDeletedComponent } from './public_component/account-deleted/account-deleted.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot_password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'change_password',
    component: ChangePasswordComponent,
  },
  {
    path: 'register',
    component: RegistrationComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'account_delete',
    component: AccountDeleteComponent,
  },
  {
    path: 'delete_end',
    component: AccountDeletedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
@Injectable({
  providedIn: 'root',
})
export class AppRoutingModule {
  public version: string = Version.toString();
  public pages: SwitchType = new SwitchType(6); //login register new_password home account_delete delete_end
  public email_validation: boolean = false;
  public invalid_login: boolean = false;
  public token: Token = new Token();
  public remember_me_check: boolean = false;
  public conf = JSON.parse(JSON.stringify(config));
  public server_domain: string = this.conf.server_ip + ':3500';
  public console_topic: string = 'server/' + this.conf.domain;
  public device_shared: Device_shared = new Device_shared(this, this.http);
  public deviceID: string = '';

  constructor(public router: Router, public http: HttpClient) {}

  public remember_me(password: string, email: string) {
    if (this.remember_me_check) {
      localStorage.setItem('password', password);
      localStorage.setItem('email', email);
    }
  }

  public login(password: string, email: string, page: string) {
    this.deviceID = new DeviceID().createDeviceID(email, password);

    this.http
      .post<any>(this.server_domain + '/login.php', {
        request: 'login',
        password: password,
        email: email,
        deviceID: this.deviceID,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            this.invalid_login = true;
            this.router.navigate(['/login']);
            this.page_access('login');
            return;
          }
          this.token = result;
          this.device_shared.add();
          sessionStorage.setItem('token', JSON.stringify(result));
          sessionStorage.setItem('password', password);
          sessionStorage.setItem('email', email);
          this.remember_me(password, email);
          this.router.navigate(['/' + page + '']);
          this.page_access(page);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  public logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('password');
    sessionStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('email');
    this.page_access('login');
    this.router.navigate(['/login']);
  }
  public sessionOut() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('password');
    sessionStorage.removeItem('email');
    this.router.navigate(['/login']);
  }

  public goToLogin() {
    this.page_access('login');
    this.router.navigate(['/login']);
  }

  public check_session(page: string) {
    let token = sessionStorage.getItem('token');

    if (token == null) {
      this.auto_login(page);
    }

    if (token != null) {
      this.http
        .post<any>(this.server_domain + '/login.php', {
          request: 'check',
          token: token,
        })
        .subscribe(
          (result) => {
            if (typeof result == 'boolean') {
              this.auto_login(page);
              return;
            }
            this.token = result;
            this.device_shared.add();
            sessionStorage.setItem('token', JSON.stringify(result));
            this.router.navigate(['/' + page + '']);
            this.page_access(page);
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }

  private auto_login(page: string) {
    let password = sessionStorage.getItem('password');
    let email = sessionStorage.getItem('email');

    if (password == null || email == null) {
      password = localStorage.getItem('password');
      email = localStorage.getItem('email');

      if (password == null || email == null) {
        this.logout();
        return;
      }
    }
    this.login(password, email, page);
  }

  public page_access(page: string) {
    switch (page) {
      case 'login':
        this.pages.change_types(0);
        break;
      case 'register':
        this.pages.change_types(1);
        break;
      case 'forgot_password':
        this.pages.change_types(2);
        break;
      case 'home':
        this.pages.change_types(3);
        break;
      case 'account_delete':
        this.pages.change_types(4);
        break;
      case 'delete_end':
        this.pages.change_types(5);
        break;
      default:
        this.pages.change_types();
    }
  }
}

export class Message {
  public response: boolean = false;
  public message: string = '';
  constructor() {}
}

export class Token {
  public id: string = '';
  public token: string = '';
  constructor() {}
}
