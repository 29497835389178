<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
-->

<div class="block">
  <div class="top_nav">
    <div class="battery_list_details">
      <app-dashboard-menu
        [string_buttons]="this.detail_string_option"
        [mark_color]="'4px solid #50A17C'"
        [button_active]="'single'"
        [least_one_active]="true"
        [border_radius]="'0px'"
        [mark_type]="'bottom'"
        [icon_width]="this.icons_width"
        [icon_height]="this.icons_height"
        [icon_margin]="this.icons_margin"
        [icon_padding]="this.icons_padding"
        [buttons]="this.detail_options"
        [icons]="this.detail_icons"
        [is_active]="this.detail_is_active"
        (response)="this.type($event)"
      ></app-dashboard-menu>
    </div>
    <div>
      <table
        class="switch"
        [ngStyle]="{
          opacity:
            this.devMan.device.is_online == false ||
            this.battery_online() == false
              ? '0.7'
              : '1'
        }"
      >
        <tr>
          <td>
            {{ this.devMan.device.alias }}
            -
            {{ this.devMan.device.sn }}
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div
    class="component_details"
    [ngStyle]="{
      opacity:
        this.devMan.device.is_online == false || this.battery_online() == false
          ? '0.7'
          : '1'
    }"
  >
    <app-battery-general
      *ngIf="this.types.types[0]"
      [update]="this.types.types[0] && this.devMan.update"
    ></app-battery-general>
    <app-battery-chart
      *ngIf="this.types.types[2]"
      [update]="this.types.types[2] && this.devMan.update"
    ></app-battery-chart>
    <app-battery-info *ngIf="this.types.types[3]"></app-battery-info>
    <app-battery-history
      *ngIf="this.types.types[1]"
      [update]="this.types.types[1] && this.devMan.update"
    ></app-battery-history>
    <app-general-events
      *ngIf="this.types.types[4]"
      [update]="this.types.types[4] && this.devMan.update"
    ></app-general-events>
  </div>
</div>
<app-warning
  [title]="'Server response'"
  [background_color]="this.warning_background_color"
  [message]="[this.warning_message]"
  [warning]="this.view_warning"
  (response)="this.view_warning = $event"
></app-warning>
