/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { CanvasResize } from './CanvasResize';

export class Gauge {
  public id: string = '';
  public perc: number = 0;
  public arc: number = 0;
  public color: string = '';

  public start: number = 0;
  public end: number = 0;

  private start_rad: number = 0;
  private end_rad: number = 0;

  private x: number = 0;
  private y: number = 0;
  private r: number = 0;

  public linewidth: number = 15;

  public canvas_resize: CanvasResize = new CanvasResize();

  private interval: any;
  private animate_gauge: number = 0;

  /**
   * Object to create
   * @param id canvas id
   * @param perc percentage (example: 0 to 100)
   * @param arc arc in rad
   * @param color
   * @param start start example 0
   * @param end end example 100
   * @param linewidth
   */
  constructor(
    id: string,
    perc: number,
    arc: number,
    color: string,
    start: number,
    end: number,
    linewidth: number
  ) {
    this.id = id;
    this.perc = perc;
    this.arc = arc;
    this.color = color;
    this.start = start;
    this.end = end;
    this.linewidth = linewidth;
    this.start_rad = this.state_of_charge(this.start);
    this.end_rad = this.state_of_charge(this.end);
  }

  public clear(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  public center(canvas: HTMLCanvasElement) {
    this.x = Math.floor(canvas.width / 2);
    this.y =
      Math.floor(canvas.height / 2) + this.linewidth * window.devicePixelRatio;
    this.r = Math.floor(Math.min(canvas.height, canvas.width) / 2);
  }

  public text(ctx: CanvasRenderingContext2D, color: string) {
    ctx.beginPath();
    let text_dim = 35 * window.devicePixelRatio;
    ctx.font = 'Bold ' + text_dim + 'px Arial';
    ctx.fillStyle = color;
    ctx.textAlign = 'center';
    ctx.fillText(this.perc.toString(), this.x, this.y - 15);
    let perc_length = ctx.measureText(this.perc.toString()).width;
    text_dim = 17 * window.devicePixelRatio;
    ctx.font = 'Bold ' + text_dim + 'px Arial';
    ctx.fillText('%', this.x + perc_length, this.y - 15);
    ctx.stroke();
    ctx.closePath();
  }

  public draw_arc(
    ctx: CanvasRenderingContext2D,
    start: number,
    end: number,
    color: string
  ) {
    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.lineWidth = 15 * window.devicePixelRatio;
    ctx.imageSmoothingEnabled = false;
    ctx.imageSmoothingQuality = 'high';
    ctx.arc(this.x, this.y, this.r, start * Math.PI, end * Math.PI);
    ctx.stroke();
    ctx.closePath();
  }

  public draw() {
    const canvas = document.getElementById(this.id)! as HTMLCanvasElement;

    if (canvas != null) {
      let dimensions = this.canvas_resize.getObjectFitSize(
        true,
        canvas.clientWidth,
        canvas.clientHeight,
        canvas.width,
        canvas.height
      );

      const dpr = window.devicePixelRatio;
      canvas.width = dimensions.width * dpr;
      canvas.height = dimensions.height * dpr;

      const ctx = canvas.getContext('2d');
      if (ctx != null) {
        this.animate_gauge = 0;
        this.clear(canvas, ctx);
        this.center(canvas);
        this.draw_arc(ctx, this.start_rad, this.end_rad, 'white');
        if (this.perc != 0) {
          clearInterval(this.interval);
          this.interval = setInterval(() => {
            let e = this.state_of_charge(this.animate_gauge);

            if (this.animate_gauge <= this.perc) {
              this.clear(canvas, ctx);
              this.text(ctx, 'white');
              this.draw_arc(ctx, this.start_rad, e, this.color);
              this.draw_arc(ctx, e, this.end_rad, 'white');
            } else {
              clearInterval(this.interval);
            }
            this.animate_gauge++;
          }, 5);
        } else {
          this.text(ctx, 'white');
        }
      }
    }
  }

  /**
   * @param arc angle in degrees / 180 -> can be simplify (ex 270/180 = 3/2)
   * @returns return color and circle partition that rappresent state of charge (0<=circle partition<=1, where 1 is complete circle)
   */
  private state_of_charge(perc: number): number {
    var soc = Number(perc);
    var pct = (this.arc / 100) * soc;
    return pct - 1.25;
  }
}