/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HomeMenuComponent } from './../home-menu/home-menu.component';
import { HttpClient } from '@angular/common/http';
import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { SwitchType } from 'src/model/app.switchType';
import { User } from '../advanced/advanced-user/user';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
@Injectable({
  providedIn: 'root',
})
export class HomeComponent implements OnInit, OnDestroy {
  //0 home 1 device_list 2 advanced 3 battery_list 4 profile 5 password 6 settings 7 armin_area
  public component = new SwitchType(8, 0);
  //0 advanced_general 1 console 2 device settings
  public advanced_tools = new SwitchType(3, 0);
  //0 log access 1 console
  public admin_tools = new SwitchType(2, 0);

  public title_path: string[] = ['>Home', '>Device list'];
  public path: string[] = ['home', 'device_list'];

  public user_type: string = 'Enduser';
  public user_name: string = '';
  public user_email: string = ' ';
  public user_initials: string = ' ';
  public is_developer: boolean = false;

  public menu: HomeMenuComponent = new HomeMenuComponent(this.app, this);

  public interval_home: any;

  constructor(public app: AppRoutingModule, public http: HttpClient) {
    this.app.check_session('home');
    this.change_component('home');
    this.get_user_parameters();
  }

  /**
   * Update token every minute
   */
  ngOnInit(): void {
    this.interval_home = setInterval(() => {
      this.app.check_session('home');
    }, 60 * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval_home);
    this.app.sessionOut();
  }

  public change_component(component: string) {
    switch (component) {
      case 'home':
        this.component.change_types(0);
        this.title_path = ['Home'];
        this.path = ['home'];
        break;
      case 'device_list':
        this.component.change_types(1);
        this.title_path = ['Home', 'Device list'];
        this.path = ['home', 'device_list'];
        break;
      case 'battery_details':
        this.component.change_types(3);
        this.title_path = ['Home', 'Device list', 'Battery details'];
        this.path = ['home', 'device_list', 'battery_details'];
        break;
      case 'advanced':
        this.component.change_types(2);
        this.advanced_tools.change_types(0);
        this.title_path = ['Home', 'Device list', 'Advanced'];
        this.path = ['home', 'device_list', 'advanced'];
        break;
      case 'profile':
        this.component.change_types(4);
        this.title_path = ['Home', 'Settings', 'Profile'];
        this.path = ['home', 'settings', 'profile'];
        break;
      case 'settings':
        this.component.change_types(6);
        this.title_path = ['Home', 'Settings'];
        this.path = ['home', 'settings'];
        break;
      case 'admin_area':
        this.admin_tools.change_types(0);
        this.component.change_types(7);
        this.title_path = ['Home', 'Admin area'];
        this.path = ['home', 'admin_area'];
        break;
      case 'log_access':
        this.admin_tools.change_types(0);
        break;
      case 'server_console':
        this.admin_tools.change_types(1);
        break;
      case 'password':
        this.component.change_types(5);
        this.title_path = ['Home', 'Settings', 'Change password'];
        this.path = ['home', 'settings', 'password'];
        break;
      case 'tools':
        this.component.change_types(2);
        this.menu.change_menu('advanced_tools');
        this.title_path = ['Home', 'Device list', 'Advanced', 'Tools'];
        this.path = ['home', 'device_list', 'advanced', 'tools'];
        break;
      case 'console':
        this.advanced_tools.change_types(1);
        break;
      case 'device_settings':
        this.advanced_tools.change_types(2);
        break;
      default:
        this.component.change_types(0);
        this.title_path = ['Home'];
        this.path = ['home'];
    }
    this.menu.outnav();
  }

  public view_path() {
    let w = window.innerWidth;
    if (w > 1200) {
      return true;
    }
    return false;
  }
  public change_folder(folder: string) {
    switch (folder) {
      case 'home':
        if (
          this.title_path[this.title_path.length - 1] == 'Change password' ||
          this.title_path[this.title_path.length - 1] == 'Profile'
        ) {
          this.change_component('settings');
        }
        if (this.title_path[this.title_path.length - 1] == 'Settings') {
          this.menu.change_menu('back');
        }
        if (this.title_path[this.title_path.length - 1] == 'Device list') {
          this.menu.change_menu('back2');
        }
        if (
          this.title_path[this.title_path.length - 1] == 'Battery details' ||
          this.title_path[this.title_path.length - 1] == 'Advanced'
        ) {
          this.menu.change_menu('back1');
          this.menu.change_menu('back2');
        }
        if (this.title_path[this.title_path.length - 1] == 'Admin area') {
          this.menu.change_menu('back4');
        }
        if (this.title_path[this.title_path.length - 1] == 'Tools') {
          this.menu.change_menu('back3');
          this.menu.change_menu('back1');
          this.menu.change_menu('back2');
        }
        this.change_component('home');
        break;
      case 'device_list':
        if (
          this.title_path[this.title_path.length - 1] == 'Battery details' ||
          this.title_path[this.title_path.length - 1] == 'Advanced'
        ) {
          this.menu.change_menu('back1');
        }
        if (this.title_path[this.title_path.length - 1] == 'Tools') {
          this.menu.change_menu('back3');
          this.menu.change_menu('back1');
        }
        this.change_component('device_list');
        break;
      case 'advanced':
        this.menu.change_menu('back3');
        this.change_component('advanced');
        break;
      case 'settings':
        this.change_component('settings');
        break;
    }
  }

  public get_user_parameters() {
    this.http
      .post<any>(this.app.server_domain + '/user_setting.php', {
        request: 'get_user_type_info',
        token: this.app.token,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            console.log('Internal server error');
            return;
          }
          let tmp = this.splitMulti(result[0], [
            '=',
            ';',
            ':',
            '.',
            '-',
            '_',
            ',',
            ' ',
          ]);
          this.user_initials =
            tmp.length > 0 ? tmp[0].substring(0, 1).toUpperCase() : ' ';
          if (tmp.length > 1)
            this.user_initials =
              this.user_initials + tmp[1].substring(0, 1).toUpperCase();
          this.user_name = result[0];
          this.user_email = result[1];
          this.is_developer = result[2];
          this.user_type = User.user_type_from_number_to_string(result[3]);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  private splitMulti(str: string, tokens: string[]) {
    var tempChar = tokens[0]; // We can use the first token as a temporary join character
    for (var i = 1; i < tokens.length; i++) {
      str = str.split(tokens[i]).join(tempChar);
    }
    return str.split(tempChar);
  }
}
