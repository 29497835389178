import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AnnularLayerComponent } from '../annular-layer.component';
import { LineLayerComponent } from '../line-layer.component';
import { StepBarUtility } from '../step-bar-utility';
enum StepIndicatorViewDirection {
  leftToRight,
  rightToLeft,
  topToBottom,
  bottomToTop,
}
@Component({
  selector: 'app-step-indicator-view',
  templateUrl: './step-indicator-view.component.html',
  styleUrls: ['./step-indicator-view.component.css'],
})
export class StepIndicatorViewComponent implements OnInit, AfterViewInit {
  private utility: StepBarUtility = new StepBarUtility(this.el, this.cdRef);

  // Variables
  static defaultColor = 'rgba(179,189,194,1)';
  static defaultTintColor = 'rgba(0,180,124,1)';
  static defaultTintErrorColor = 'red';

  @Input() horizontalLabels: string[] = [];
  private annularLayers: Array<ComponentRef<AnnularLayerComponent>> = ([] = []);
  private horizontalLineLayers: Array<ComponentRef<LineLayerComponent>> = ([] =
    []);
  private _frame: boolean = false;
  @Input() public set frame(value: boolean) {
    this._frame = value;
    this.updateSubLayers();
  }

  // override public func layoutSubviews() {
  //   super.layoutSubviews()
  //   self.updateSubLayers()
  // }

  private _numberOfSteps: number = 5;
  @Input() public set numberOfSteps(value: number) {
    this._numberOfSteps = value;
    this.createSteps();
  }

  private _currentStep: number = -1;
  @Input() public set currentStep(value: number) {
    let oldValue = this._currentStep;
    if (this._currentStep < -1) {
      this._currentStep = -1;
    }
    this._currentStep = value;
    if (this.annularLayers.length <= 0) {
      return;
    }
    if (oldValue != this._currentStep) {
      this.setCurrentStep(this._currentStep);
    }
  }

  private _error: boolean = false;
  @Input() public set error(value: boolean) {
    let oldValue = this._error;
    this._error = value;
    if (oldValue != this._error) {
      this.setError(this._error);
    }
    if (this.annularLayers.length <= 0) {
      return;
    }
  }

  private _displayNumbers: boolean = false;
  @Input() public set displayNumbers(value: boolean) {
    this._displayNumbers = value;
    this.updateSubLayers();
  }
  private _circleRadius: number = 25.0;
  @Input() public set circleRadius(value: number) {
    this._circleRadius = value;
    this.updateSubLayers();
  }

  private _circleColor: string = StepIndicatorViewComponent.defaultColor;
  @Input() public set circleColor(value: string) {
    this._circleColor = value;
    this.updateSubLayers();
  }

  private _circleTintColor: string =
    StepIndicatorViewComponent.defaultTintColor;
  @Input() public set circleTintColor(value: string) {
    this._circleTintColor = value;
    this.updateSubLayers();
  }

  private _circleTintErrorColor: string =
    StepIndicatorViewComponent.defaultTintErrorColor;
  @Input() public set circleTintErrorColor(value: string) {
    this._circleTintErrorColor = value;
    this.updateSubLayers();
  }
  private _circleStrokeWidth: number = 3.0;
  @Input() public set circleStrokeWidth(value: number) {
    this._circleStrokeWidth = value;
    this.updateSubLayers();
  }

  private _lineColor: string = StepIndicatorViewComponent.defaultColor;
  @Input() public set lineColor(value: string) {
    this._lineColor = value;
    this.updateSubLayers();
  }

  private _lineTintColor: string = StepIndicatorViewComponent.defaultTintColor;
  @Input() public set lineTintColor(value: string) {
    this._lineTintColor = value;
    this.updateSubLayers();
  }

  private _lineMargin: number = 4.0;
  @Input() public set lineMargin(value: number) {
    this._lineMargin = value;
    this.updateSubLayers();
  }

  private _lineStrokeWidth: number = 2.0;
  @Input() public set lineStrokeWidth(value: number) {
    this._lineStrokeWidth = value;
    this.updateSubLayers();
  }

  private _direction: number = StepIndicatorViewDirection.leftToRight;
  @Input() public set direction(value: number) {
    this._direction = value;
    this.updateSubLayers();
  }

  private _showFlag: boolean = true;
  @Input() public set showFlag(value: boolean) {
    this._showFlag = value;
    this.updateSubLayers();
  }

  @ViewChild('viewContainerRef', { read: ViewContainerRef })
  VCR!: ViewContainerRef;

  annularComponentsReferences = Array<AnnularLayerComponent>();

  constructor(
    private CFR: ComponentFactoryResolver,
    private el: ElementRef,
    private renderer: Renderer2,
    private readonly cdRef: ChangeDetectorRef
  ) {}
  ngOnInit(): void {}
  public viewCreated = false;
  ngAfterViewInit(): void {
    this.viewCreated = true;
    this.createSteps();
  }

  createSteps() {
    if (!this.viewCreated) {
      return;
    }
    let { _currentStep, annularLayers, horizontalLineLayers, _numberOfSteps } =
      this;

    //remove all component from VCR (now work witout this row)
    /*this.VCR.clear();*/

    annularLayers = [];
    horizontalLineLayers = [];

    if (_numberOfSteps <= 0) {
      return;
    }
    for (let i = 0; i < _numberOfSteps; i++) {
      let componentFactory = this.CFR.resolveComponentFactory(
        AnnularLayerComponent
      );
      let childComponentRef = this.VCR.createComponent(componentFactory);
      this.annularLayers.push(childComponentRef);
      if (i < _numberOfSteps - 1) {
        let componentFactory =
          this.CFR.resolveComponentFactory(LineLayerComponent);
        let childComponentRef = this.VCR.createComponent(componentFactory);
        this.horizontalLineLayers.push(childComponentRef);
      }
    }
    this.updateSubLayers();
    this.setCurrentStep(_currentStep);
  }

  labelLlayoutHorizontal() {
    const { _circleRadius, _numberOfSteps, _lineMargin, horizontalLabels } =
      this;

    let canvasEl = document.getElementById(
      'horizontal_labels'
    )! as HTMLCanvasElement;
    if (canvasEl) {
      canvasEl.setAttribute('width', this.utility.getOffSetWidth());
      canvasEl.setAttribute('height', (20).toString());

      let ctx = canvasEl.getContext('2d');

      if (_numberOfSteps != horizontalLabels.length) {
        return;
      }
      let diameter = _circleRadius * 2;
      let stepWidth =
        _numberOfSteps == 1
          ? 0
          : (this.utility.getOffSetWidth() - _lineMargin - diameter) /
            (_numberOfSteps - 1);
      let y = 12;
      if (ctx) {
        for (let i = 0; i < horizontalLabels.length; i++) {
          let x =
            _numberOfSteps == 1
              ? this.utility.getOffSetWidth() / 2.0 - _circleRadius
              : _lineMargin + i * stepWidth;
          ctx.beginPath();
          let labelSize = ctx.measureText(horizontalLabels[i]);
          ctx.font = '13px Arial';
          ctx.fillStyle = 'white';
          ctx.fillText(
            horizontalLabels[i],
            i == 0
              ? x
              : i == _numberOfSteps - 1
              ? x + diameter - labelSize.width - 9
              : x + _circleRadius - _lineMargin - labelSize.width / 2,
            y,
            labelSize.width
          );
          ctx.fill();
          ctx.closePath();
        }
      }
      this.horizontalLabels.forEach((x) => {});
    }
  }

  updateSubLayers(windowResize: boolean = false) {
    if (!this.viewCreated) {
      return;
    }
    let { _direction } = this;
    if (
      _direction == StepIndicatorViewDirection.leftToRight ||
      _direction == StepIndicatorViewDirection.rightToLeft
    ) {
      this.layoutHorizontal(windowResize);
      this.labelLlayoutHorizontal();
    } else {
      this.layoutVertical(windowResize);
    }

    this.applyDirection();
  }

  private layoutHorizontal(windowResize: boolean = false) {
    const {
      _circleRadius,
      _numberOfSteps,
      _lineMargin,
      annularLayers,
      horizontalLineLayers,
      el,
    } = this;
    let diameter = _circleRadius * 2;

    let stepWidth =
      _numberOfSteps == 1
        ? 0
        : (this.utility.getOffSetWidth() - _lineMargin - diameter) /
          (_numberOfSteps - 1);
    let y = this.utility.getOffSetHeight() / 2.0;

    for (let i = 0; i < annularLayers.length; i++) {
      let annularLayer = annularLayers[i];
      if (windowResize) {
        annularLayer.instance.resizeAnnular(diameter, diameter);
      } else {
        let x =
          _numberOfSteps == 1
            ? this.utility.getOffSetWidth() / 2.0 - _circleRadius
            : _lineMargin + i * stepWidth;

        annularLayer.instance.svg.setAttribute(
          'position',
          `${x} ${y - _circleRadius} 0`
        );
        annularLayer.instance.width = diameter;
        annularLayer.instance.height = diameter;
        this.applyAnnularStyle(i);
        annularLayer.instance.step = i + 1;
        annularLayer.instance.updateStatus();
      }

      if (i < _numberOfSteps - 1) {
        let lineLayer = horizontalLineLayers[i];
        lineLayer.instance.svg.setAttribute(
          'position',
          `${i * stepWidth + diameter} ${y - 1} 0`
        );
        lineLayer.instance.width = stepWidth - diameter;
        lineLayer.instance.height = diameter;
        this.applyLineStyle(i);
        lineLayer.instance.updateStatus();
      }
    }
  }

  private layoutVertical(windowResize: boolean = false) {
    const {
      _circleRadius,
      _numberOfSteps,
      _lineMargin,
      annularLayers,
      horizontalLineLayers,
      el,
    } = this;
    let diameter = _circleRadius * 2;
    let stepWidth =
      _numberOfSteps == 1
        ? 0
        : (this.utility.getOffSetHeight() - _lineMargin - diameter) /
          (_numberOfSteps - 1);
    let x = this.utility.getOffSetWidth() / 2.0;

    for (let i = 0; i < annularLayers.length; i++) {
      let annularLayer = annularLayers[i];
      if (windowResize) {
        annularLayer.instance.resizeAnnular(diameter, diameter);
      } else {
        let y =
          _numberOfSteps == 1
            ? this.utility.getOffSetHeight() / 2.0 - _circleRadius
            : _lineMargin + i * stepWidth;
        annularLayer.instance.svg.setAttribute(
          'position',
          `${x - _circleRadius} ${y} 0`
        );
        annularLayer.instance.width = diameter;
        annularLayer.instance.height = diameter;
        this.applyAnnularStyle(i);
        annularLayer.instance.step = i + 1;
        annularLayer.instance.updateStatus();
      }

      if (i < _numberOfSteps - 1) {
        let lineLayer = horizontalLineLayers[i];
        lineLayer.instance.svg.setAttribute(
          'position',
          `${x - 1} ${i * stepWidth + diameter} 0`
        );
        lineLayer.instance.width = diameter;
        lineLayer.instance.height = stepWidth - diameter;
        lineLayer.instance.isHorizontal = false;
        this.applyLineStyle(i);
        lineLayer.instance.updateStatus();
      }
    }
  }

  private applyAnnularStyle(index: number) {
    const {
      annularLayers,
      _circleColor,
      _circleTintColor,
      _circleTintErrorColor,
      _circleStrokeWidth,
      _displayNumbers,
      _showFlag,
    } = this;
    annularLayers[index].instance.annularDefaultColor = _circleColor;
    annularLayers[index].instance.tintColor = _circleTintColor;
    annularLayers[index].instance.tintErrorColor = _circleTintErrorColor;
    annularLayers[index].instance.lineWidth = _circleStrokeWidth;
    annularLayers[index].instance.displayNumber = _displayNumbers;
    annularLayers[index].instance.showFlag = _showFlag;
  }

  private applyLineStyle(index: number) {
    const {
      horizontalLineLayers,
      _lineTintColor,
      _lineColor,
      _lineStrokeWidth,
    } = this;

    horizontalLineLayers[index].instance.strokeColor = _lineColor;
    horizontalLineLayers[index].instance.tintColor = _lineTintColor;
    horizontalLineLayers[index].instance.lineWidth = _lineStrokeWidth;
  }

  private applyDirection() {
    const { _direction, annularLayers } = this;

    switch (_direction) {
      case StepIndicatorViewDirection.rightToLeft:
        this.renderer.setStyle(
          this.el.nativeElement,
          'transform',
          'rotateY(180deg)'
        );
        annularLayers.forEach((x) => {
          x.instance.svg.style.transform = 'rotateY(180deg)';
        });
        break;
      case StepIndicatorViewDirection.bottomToTop:
        this.renderer.setStyle(
          this.el.nativeElement,
          'transform',
          'rotateX(180deg)'
        );
        annularLayers.forEach((x) => {
          x.instance.svg.style.transform = 'rotateX(180deg)';
        });
        break;
      default:
        this.renderer.setStyle(this.el.nativeElement, 'transform', '');
        annularLayers.forEach((x) => {
          x.instance.svg.style.transform = '';
        });
    }
  }

  setCurrentStep(step: number = this._currentStep) {
    if (!this.viewCreated) {
      return;
    }
    const { _numberOfSteps, annularLayers } = this;
    for (let i = 0; i < _numberOfSteps; i++) {
      if (i < step) {
        if (!annularLayers[i].instance.isFinished) {
          annularLayers[i].instance.isFinished = true;
        }
        this.setLineFinished(true, i - 1);
      } else if (i == step && !this._error) {
        annularLayers[i].instance.isFinished = false;
        annularLayers[i].instance.isCurrent = true;

        this.setLineFinished(true, i - 1);
      } else if (i == step && this._error) {
        this.setError(this._error);
      } else {
        annularLayers[i].instance.isFinished = false;
        annularLayers[i].instance.isCurrent = false;
        annularLayers[i].instance.isError = false;
        annularLayers[i].instance.updateStatus();
        this.setLineFinished(false, i - 1);
      }
    }
  }
  private setError(value: boolean) {
    const { _currentStep, annularLayers } = this;
    if (!annularLayers[_currentStep - 1]) {
      return;
    }
    annularLayers[_currentStep - 1].instance.isError = value;
    if (!annularLayers[_currentStep]) {
      return;
    }
    annularLayers[_currentStep].instance.isFinished = false;
    annularLayers[_currentStep].instance.isCurrent = false;
    annularLayers[_currentStep].instance.isError = false;
    annularLayers[_currentStep].instance.updateStatus();
    this.setLineFinished(false, _currentStep - 1);
  }

  private setLineFinished(isFinished: boolean, index: number) {
    const { horizontalLineLayers } = this;
    if (index >= 0) {
      if (horizontalLineLayers[index].instance.isFinished != isFinished) {
        horizontalLineLayers[index].instance.isFinished = isFinished;
        horizontalLineLayers[index].instance.updateStatus();
      }
    }
  }
}
