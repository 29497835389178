import { Component, Inject } from '@angular/core';
import { bind } from 'decko';
import { Terminal, ITerminalOptions, IDisposable } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import { CanvasAddon } from 'xterm-addon-canvas';
import { WebglAddon } from 'xterm-addon-webgl';
import { ImageAddon } from 'xterm-addon-image';
import { WebLinksAddon } from 'xterm-addon-web-links';
import { OverlayAddon } from './addons/overlay';
import * as Paho from 'paho-mqtt';
import { Buffer } from 'buffer';
import { xtea_normalize_buf, xtea_crypt_buf, xtea_decrypt_buf } from './xtea';

interface TtydTerminal extends Terminal {
  fit(): void;
}

declare global {
  interface Window {
    term: TtydTerminal;
  }
}

const enum Command {
  // server side
  OUTPUT = '0',
  SET_WINDOW_TITLE = '1',
  SET_PREFERENCES = '2',

  // client side
  INPUT = '0',
  RESIZE_TERMINAL = '1',
  PAUSE = '2',
  RESUME = '3',
  INIT_TERMINAL = '4',
}

type Preferences = ITerminalOptions & ClientOptions;

export type RendererType = 'dom' | 'canvas' | 'webgl';

export interface ClientOptions {
  rendererType: RendererType;
  disableLeaveAlert: boolean;
  disableResizeOverlay: boolean;
  enableZmodem: boolean;
  enableTrzsz: boolean;
  enableSixel: boolean;
  titleFixed?: string;
  isWindows: boolean;
}

export interface FlowControl {
  limit: number;
  highWater: number;
  lowWater: number;
}

export interface XtermOptions {
  host: string;
  username?: string;
  password?: string;
  port: number;
  device_sn: string;
  sfxPublishTopic: string;
  sfxSubscribeTopic: string;
  pfxTopic: string;
  enableXtea: boolean;
  flowControl: FlowControl;
  clientOptions: ClientOptions;
  termOptions: ITerminalOptions;
}

function toDisposable(f: () => void): IDisposable {
  return { dispose: f };
}

function addEventListener(
  target: EventTarget,
  type: string,
  listener: EventListener
): IDisposable {
  target.addEventListener(type, listener);
  return toDisposable(() => target.removeEventListener(type, listener));
}

@Component({
  selector: 'app-xterm',
  templateUrl: './xterm.component.html',
  styleUrls: ['./xterm.component.scss'],
})
export class XtermComponent {
  private disposables: IDisposable[] = [];
  private textEncoder = new TextEncoder();
  private textDecoder = new TextDecoder();
  private written = 0;
  private pending = 0;
  private keyXtea = '';
  private enableXtea = true;

  private _xterm?: Terminal;
  private _fitAddon = new FitAddon();
  private _overlayAddon = new OverlayAddon();
  private webglAddon?: WebglAddon;
  private canvasAddon?: CanvasAddon;

  private client?: Paho.Client;
  private clientId: string = '';
  private publishTopic: string = '';
  private subscribeTopic: string = '';
  private opened = false;
  private title?: string;
  private titleFixed?: string;
  private resizeOverlay = true;
  private reconnect = true;
  private doReconnect = true;
  private _console_version: string = '';
  public get console_version() {
    return this._console_version;
  }

  private writeFunc = (data: ArrayBuffer) =>
    this.writeData(new Uint8Array(data));

  constructor(@Inject('options') private options: XtermOptions) {
    this.keyXtea = this.options.device_sn;
    this.enableXtea = this.options.enableXtea;
    this.publishTopic = this.options.pfxTopic + this.options.sfxPublishTopic;
    this.subscribeTopic = this.options.pfxTopic + this.options.sfxSubscribeTopic;
  }

  dispose() {
    for (const d of this.disposables) {
      d.dispose();
    }
    this.disposables.length = 0;
  }

  @bind
  private register<T extends IDisposable>(d: T): T {
    this.disposables.push(d);
    return d;
  }

  @bind
  private onWindowUnload(event: BeforeUnloadEvent) {
    event.preventDefault();
    if (this.client?.isConnected() === true) {
      const message = 'Close terminal? this will also terminate the command.';
      event.returnValue = message;
      return message;
    }
    return undefined;
  }

  @bind
  public open(parent: HTMLElement) {
    const xterm = new Terminal(this.options.termOptions);
    this._xterm = xterm;
    const { _xterm, _fitAddon, _overlayAddon } = this;
    window.term = _xterm as TtydTerminal;
    window.term.fit = () => {
      this._fitAddon.fit();
    };
    _xterm.loadAddon(_fitAddon);
    _xterm.loadAddon(_overlayAddon);
    _xterm.loadAddon(new WebLinksAddon());

    _xterm.open(parent);
    _fitAddon.fit();
  }

  @bind
  private initListeners() {
    const { _xterm, _fitAddon, _overlayAddon, register, sendData } = this;
    register(
      _xterm!.onTitleChange((data) => {
        if (data && data !== '' && !this.titleFixed) {
          this._console_version = this.title + ' | ' + data;
        }
      })
    );
    register(_xterm!.onData((data) => sendData(data)));
    register(
      _xterm!.onBinary((data) =>
        sendData(Uint8Array.from(data, (v) => v.charCodeAt(0)))
      )
    );
    register(
      _xterm!.onResize(({ cols, rows }) => {
        const msg =
          Command.RESIZE_TERMINAL + ' ' + String(cols) + ' ' + String(rows);
        const buffer = this.enableXtea
          ? this.crypt_msg(msg, this.keyXtea)
          : this.textEncoder.encode(msg);
        this.client?.send(this.publishTopic, buffer);
        if (this.resizeOverlay)
          _overlayAddon.showOverlay(`${cols}x${rows}`, 300);
      })
    );
    register(
      _xterm!.onSelectionChange(() => {
        if (this._xterm!.getSelection() === '') return;
        try {
          document.execCommand('copy');
        } catch (e) {
          return;
        }
        this._overlayAddon?.showOverlay('\u2702', 200);
      })
    );
    register(addEventListener(window, 'resize', () => _fitAddon.fit()));
    register(addEventListener(window, 'beforeunload', this.onWindowUnload));
  }

  @bind
  public writeData(data: string | Uint8Array) {
    const { _xterm, textEncoder } = this;
    const { limit, highWater, lowWater } = this.options.flowControl;

    this.written += data.length;
    if (this.written > limit) {
      _xterm!.write(data, () => {
        this.pending = Math.max(this.pending - 1, 0);
        if (this.pending < lowWater) {
          const msg = Command.RESUME;
          const buffer = this.enableXtea
            ? this.crypt_msg(msg, this.keyXtea)
            : textEncoder.encode(msg);
          this.client?.send(this.publishTopic, buffer);
        }
      });
      this.pending++;
      this.written = 0;
      if (this.pending > highWater) {
        const msg = Command.PAUSE;
        const buffer = this.enableXtea
          ? this.crypt_msg(msg, this.keyXtea)
          : textEncoder.encode(msg);
        this.client?.send(this.publishTopic, buffer);
      }
    } else {
      _xterm!.write(data);
    }
  }
  @bind
  public onConnectCbl() {
    console.log('[tty2mqtt] mqtt connection opened');
    // Once a connection has been made, make a subscription and send a message.
    const { textEncoder, _xterm, _overlayAddon } = this;
    const msgInit = Command.INIT_TERMINAL;
    let buffer = this.enableXtea
      ? this.crypt_msg(msgInit, this.keyXtea)
      : textEncoder.encode(msgInit);
    this.client?.send(this.publishTopic, buffer);

    const msgResize =
      Command.RESIZE_TERMINAL +
      ' ' +
      String(_xterm!.cols) +
      ' ' +
      String(_xterm!.rows);
    buffer = this.enableXtea
      ? this.crypt_msg(msgResize, this.keyXtea)
      : textEncoder.encode(msgResize);
    this.client?.send(this.publishTopic, buffer);

    if (this.opened) {
      _xterm!.reset();
      _xterm!.options.disableStdin = false;
      _overlayAddon.showOverlay('Reconnected', 300);
    } else {
      this.opened = true;
      this.client?.subscribe(this.subscribeTopic);
      _overlayAddon.showOverlay('Connected', 300);
    }

    this.initListeners();
    _xterm!.focus();
  }

  @bind
  public onConnectionLostCbl(error: Paho.MQTTError) {
    console.log(
      `[tty2mqtt] mqtt connection closed with code: ${error.errorCode}`
    );
  }

  @bind
  public onMessageArrivedCbl(message: Paho.Message) {
    // Once a connection has been made, make a subscription and send a message.
    const { textDecoder } = this;
    let cmd = '';
    let data: Buffer | ArrayBuffer;

    if (this.enableXtea) {
      const decodedBuffer = Buffer.alloc(message.payloadBytes.byteLength);
      const buffer = Buffer.from(message.payloadBytes);

      xtea_decrypt_buf(decodedBuffer, buffer, Buffer.from(this.keyXtea));

      cmd = decodedBuffer.toString('utf-8', 0, 1);
      data = decodedBuffer.slice(1);
    } else {
      const rawData = message.payloadBytes as ArrayBuffer;
      cmd = String.fromCharCode(new Uint8Array(rawData)[0]);
      data = rawData.slice(1);
    }

    switch (cmd) {
      case Command.OUTPUT:
        this.writeFunc(data);
        break;
      case Command.SET_WINDOW_TITLE:
        this.title = textDecoder.decode(data);
        this._console_version = this.title;
        break;
      case Command.SET_PREFERENCES:
        let jsonString = textDecoder.decode(data);
        jsonString = jsonString.replace(/\0+$/, '');
        this.applyPreferences({
          ...this.options.clientOptions,
          ...JSON.parse(jsonString),
        } as Preferences);
        break;
      default:
        console.warn(`[tty2mqtt] unknown command: ${cmd}`);
        break;
    }
  }

  @bind
  public sendData(data: string | Uint8Array) {
    const { textEncoder } = this;
    if (this.client?.isConnected() === false) return;

    if (typeof data === 'string') {
      const payload = new Uint8Array(data.length * 3 + 1);
      payload[0] = Command.INPUT.charCodeAt(0);
      const stats = textEncoder.encodeInto(data, payload.subarray(1));
      const temp = payload.subarray(0, (stats.written as number) + 1);
      if (this.enableXtea) {
        const buffer = this.crypt_msg(temp, this.keyXtea);
        this.client?.send(this.publishTopic, buffer);
      } else {
        this.client?.send(this.publishTopic, temp);
      }
    } else {
      const payload = new Uint8Array(data.length + 1);
      payload[0] = Command.INPUT.charCodeAt(0);
      payload.set(data, 1);
      if (this.enableXtea) {
        const buffer = this.crypt_msg(payload, this.keyXtea);
        this.client?.send(this.publishTopic, buffer);
        this.client?.send(this.publishTopic, buffer);
      } else {
        this.client?.send(this.publishTopic, payload);
      }
    }
  }

  @bind
  public disconnect() {
    if (this.client?.isConnected() == true) {
      this.client?.disconnect();
    }
  }

  @bind
  public connect() {
    this.clientId = 'myclientid_' + String(Math.floor(Math.random() * 1000));
    this.client = new Paho.Client(
      this.options.host,
      this.options.port,
      this.clientId
    );
    this.client.onMessageArrived = this.onMessageArrivedCbl;
    this.client.onConnectionLost = this.onConnectionLostCbl;
    if (
      this.options.username == undefined ||
      this.options.password == undefined
    ) {
      this.client.connect({ onSuccess: this.onConnectCbl });
    } else {
      this.client.connect({
        onSuccess: this.onConnectCbl,
        userName: this.options.username,
        password: this.options.password,
      });
    }
  }

  @bind
  private applyPreferences(prefs: Preferences) {
    const { _xterm, _fitAddon, register } = this;
    Object.entries(prefs).forEach((entry) => {
      const key = entry[0];
      const value = entry[1];

      switch (key) {
        case 'rendererType':
          this.setRendererType(value);
          break;
        case 'disableLeaveAlert':
          if (value) {
            window.removeEventListener('beforeunload', this.onWindowUnload);
            console.log('[ttyd] Leave site alert disabled');
          }
          break;
        case 'disableResizeOverlay':
          if (value) {
            console.log('[ttyd] Resize overlay disabled');
            this.resizeOverlay = false;
          }
          break;
        case 'disableReconnect':
          if (value) {
            console.log('[ttyd] Reconnect disabled');
            this.reconnect = false;
            this.doReconnect = false;
          }
          break;
        case 'enableZmodem':
          if (value) console.log('[ttyd] Zmodem enabled');
          break;
        case 'enableTrzsz':
          if (value) console.log('[ttyd] trzsz enabled');
          break;
        case 'enableSixel':
          if (value) {
            _xterm!.loadAddon(register(new ImageAddon()));
            console.log('[ttyd] Sixel enabled');
          }
          break;
        case 'titleFixed':
          if (!value || value === '') return;
          this.titleFixed = value;
          this._console_version = value;
          break;
        case 'isWindows':
          if (value) console.log('[ttyd] is windows');
          break;
        default:
          Object.entries(_xterm!.options).forEach((entry) => {
            if (entry[0] === key) {
              if (entry[1] instanceof Object) {
                entry[1] = Object.assign({}, entry[1], value);
              } else {
                entry[1] = value;
              }
            }
          });
          if (key.indexOf('font') === 0) _fitAddon.fit();
          break;
      }
    });
  }

  @bind
  private setRendererType(value: RendererType) {
    const { _xterm } = this;
    const disposeCanvasRenderer = () => {
      try {
        this.canvasAddon?.dispose();
      } catch {
        // ignore
      }
      this.canvasAddon = undefined;
    };
    const disposeWebglRenderer = () => {
      try {
        this.webglAddon?.dispose();
      } catch {
        // ignore
      }
      this.webglAddon = undefined;
    };
    const enableCanvasRenderer = () => {
      if (this.canvasAddon) return;
      this.canvasAddon = new CanvasAddon();
      disposeWebglRenderer();
      try {
        this._xterm!.loadAddon(this.canvasAddon);
        console.log('[ttyd] canvas renderer loaded');
      } catch (e) {
        console.log(
          '[ttyd] canvas renderer could not be loaded, falling back to dom renderer',
          e
        );
        disposeCanvasRenderer();
      }
    };
    const enableWebglRenderer = () => {
      if (this.webglAddon) return;
      this.webglAddon = new WebglAddon();
      disposeCanvasRenderer();
      try {
        this.webglAddon.onContextLoss(() => {
          this.webglAddon?.dispose();
        });
        _xterm!.loadAddon(this.webglAddon);
        console.log('[ttyd] WebGL renderer loaded');
      } catch (e) {
        console.log(
          '[ttyd] WebGL renderer could not be loaded, falling back to canvas renderer',
          e
        );
        disposeWebglRenderer();
        enableCanvasRenderer();
      }
    };

    switch (value) {
      case 'canvas':
        enableCanvasRenderer();
        break;
      case 'webgl':
        enableWebglRenderer();
        break;
      case 'dom':
        disposeWebglRenderer();
        disposeCanvasRenderer();
        console.log('[ttyd] dom renderer loaded');
        break;
      default:
        break;
    }
  }

  @bind
  private crypt_msg(msg: any, key: string): Buffer {
    const msgBuffer = Buffer.from(msg);
    const keyBuffer = Buffer.from(key);
    const ptr = xtea_normalize_buf(msgBuffer);
    const buffer = Buffer.alloc(ptr.length);

    xtea_crypt_buf(buffer, ptr, keyBuffer);

    return buffer;
  }
}
