<div class="block">
  <div>
    <p class="title">Device settings</p>
  </div>
  <div>
    <!-- <div class="list_message">Total events: {{ this.events.length }}</div>
    <div class="list_message date_message">{{ this.date }}</div>
    <div *ngIf="this.filter" class="list_message date_message filter_message">
      <a
        (click)="this.view_filter = !this.view_filter"
        [ngStyle]="{
          'pointer-events': this.calendar ? 'none' : 'all'
        }"
        >{{ this.short_description_filters }}</a
      >
    </div> -->
    <app-dashboard-menu
      class="dashboard_menu"
      [mark_color]="'#50a17c'"
      [button_active]="'single'"
      [string_buttons]="this.menu_string_option"
      [icon_width]="['25px', '25px']"
      [icon_height]="['30px', '30px']"
      [icon_margin]="['0px 3px 0px 0px', '0px 30px 0px 0px']"
      [icon_padding]="['0px 0px 0px 0px', '0px 0px 0px 0px']"
      [buttons]="this.menu_options"
      [labels]="['Import', 'Save']"
      [labels_width]="['46px', '61px']"
      [labels_height]="['18px', '18px']"
      [view_labels]="true"
      [icons]="this.menu_icons"
      [is_active]="this.is_active"
      (response)="this.command($event)"
    ></app-dashboard-menu>
  </div>
  <div class="fixed_table">
    <table class="list">
      <thead>
        <tr>
          <th class="id_td">ID</th>
          <th class="name_td" style="text-align: center">Name</th>
          <th class="value_td">Value</th>
          <th class="um_td">UM</th>
          <th class="type_td">Type</th>
          <th>Category</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td *ngIf="this.wait_variables" class="wait_td">
            The download of device parameters can need few times.
          </td>
        </tr>
        <tr
          *ngFor="let obj of this.filter(); let i = index"
          (click)="this.setComment(obj.name)"
          [ngClass]="obj.name == this.comment.name ? 'mark_row' : ''"
        >
          <td
            class="id_td"
            [ngStyle]="{
              background:
                obj.name == this.comment.name
                  ? '#4790d0'
                  : i % 2 != 0
                  ? 'rgb(60,60,60)'
                  : '#333334',
              color:
                obj.name == this.comment.name
                  ? '#333334'
                  : this.blueRow(obj.permission)
                  ? '#4790d0'
                  : 'white'
            }"
          >
            {{ obj.id }}
          </td>
          <td
            class="name_td"
            [ngStyle]="{
              background:
                obj.name == this.comment.name
                  ? '#4790d0'
                  : i % 2 != 0
                  ? 'rgb(60,60,60)'
                  : '#333334',
              color:
                obj.name == this.comment.name
                  ? '#333334'
                  : this.blueRow(obj.permission)
                  ? '#4790d0'
                  : 'white'
            }"
          >
            {{ obj.name }}
          </td>
          <td
            class="value_td"
            style="text-align: right"
            [ngStyle]="{
              background:
                obj.name == this.comment.name
                  ? '#4790d0'
                  : i % 2 != 0
                  ? 'rgb(60,60,60)'
                  : '#333334',
              color:
                obj.name == this.comment.name
                  ? '#333334'
                  : this.blueRow(obj.permission)
                  ? '#4790d0'
                  : 'white'
            }"
          >
            <input
              *ngIf="this.editableRow(obj.permission)"
              type="text"
              name=""
              id="inputVariable_{{ obj.name }}"
              [ngClass]="obj.name == this.comment.name ? 'comm_input' : ''"
              value="{{ obj.value }}"
              (keydown)="this.onKeydownMain($event, obj.id, obj.name, obj.value)"
            />
            <label
              *ngIf="!this.editableRow(obj.permission)"
              [ngStyle]="{
                background:
                  obj.name == this.comment.name
                    ? '#4790d0'
                    : i % 2 != 0
                    ? 'rgb(60,60,60)'
                    : '#333334',
                color:
                  obj.name == this.comment.name
                    ? '#333334'
                    : this.blueRow(obj.permission)
                    ? '#4790d0'
                    : 'white'
              }"
            >
              {{ obj.value }}
            </label>
          </td>
          <td
            class="um_td"
            style="text-align: center"
            [ngStyle]="{
              background:
                obj.name == this.comment.name
                  ? '#4790d0'
                  : i % 2 != 0
                  ? 'rgb(60,60,60)'
                  : '#333334',
              color:
                obj.name == this.comment.name
                  ? '#333334'
                  : this.blueRow(obj.permission)
                  ? '#4790d0'
                  : 'white'
            }"
          >
            {{ obj.um }}
          </td>
          <td
            class="type_td"
            style="text-align: center"
            [ngStyle]="{
              background:
                obj.name == this.comment.name
                  ? '#4790d0'
                  : i % 2 != 0
                  ? 'rgb(60,60,60)'
                  : '#333334',
              color:
                obj.name == this.comment.name
                  ? '#333334'
                  : this.blueRow(obj.permission)
                  ? '#4790d0'
                  : 'white'
            }"
          >
            {{ obj.type }}
          </td>
          <td
            style="text-align: right"
            [ngStyle]="{
              background:
                obj.name == this.comment.name
                  ? '#4790d0'
                  : i % 2 != 0
                  ? 'rgb(60,60,60)'
                  : '#333334',
              color:
                obj.name == this.comment.name
                  ? '#333334'
                  : this.blueRow(obj.permission)
                  ? '#4790d0'
                  : 'white'
            }"
          >
            {{ obj.category }}
          </td>
        </tr>
      </tbody>
      <tr class="last_row">
        <td>
          <table class="flag_table">
            <tr>
              <td class="big_td">Comment</td>
              <td colspan="4" style="font-weight: bolder">
                {{ this.comment.name }}
              </td>
            </tr>
            <tr>
              <td class="big_td"><br /></td>
              <td colspan="4">{{ this.comment.description }}</td>
            </tr>
            <tr>
              <td class="big_td">Name</td>
              <td colspan="4">
                <div id="search_devices_list">
                  <img
                    class="search"
                    src="../../assets/icon/device-list/device_list_menu/search.svg"
                    alt=""
                  />
                  <input
                    id="search_objs_input"
                    class="input"
                    type="text"
                    placeholder="Search for one or more names with | separator"
                    (input)="this.filter()"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td class="big_td">Category</td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[0] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[0] = !this.categories[0]"
                  />
                </svg>
                INFO
              </td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[4] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[4] = !this.categories[4]"
                  />
                </svg>
                TEMPERATURES
              </td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[8] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[8] = !this.categories[8]"
                  />
                </svg>
                SOH
              </td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[12] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[12] = !this.categories[12]"
                  />
                </svg>
                VIASAT
              </td>
            </tr>
            <tr>
              <td class="big_td"></td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[1] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[1] = !this.categories[1]"
                  />
                </svg>
                STATE
              </td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[5] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[5] = !this.categories[5]"
                  />
                </svg>
                VOLTAGES
              </td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[9] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[9] = !this.categories[9]"
                  />
                </svg>
                CANBUS
              </td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[13] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[13] = !this.categories[13]"
                  />
                </svg>
                CLIMATIC
              </td>
            </tr>
            <tr>
              <td class="big_td"></td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[2] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[2] = !this.categories[2]"
                  />
                </svg>
                GENERAL
              </td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[6] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[6] = !this.categories[6]"
                  />
                </svg>
                CURRENT
              </td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[10] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[10] = !this.categories[10]"
                  />
                </svg>
                CHARGER
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="big_td"></td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[3] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[3] = !this.categories[3]"
                  />
                </svg>
                LOG
              </td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[7] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[7] = !this.categories[7]"
                  />
                </svg>
                SOC
              </td>
              <td>
                <svg>
                  <rect
                    style="
                      stroke-width: 4.59433;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      fill-opacity: 1;
                    "
                    id="rect341"
                    width="15.405674"
                    height="15.40567"
                    x="2.2971649"
                    y="2.2971649"
                    [ngStyle]="{
                      fill: this.categories[11] ? '#50a17c' : 'rgb(75,75,75)'
                    }"
                    (click)="this.categories[11] = !this.categories[11]"
                  />
                </svg>
                CLUSTER
              </td>
              <td></td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</div>
