/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { FileService } from 'src/model/app.fileService';
import { XML } from './XML';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceSettings {
  constructor(private fileService: FileService) {}
  public fromExtendedObjToXMLStr(objs: ExtendedObj[]) {
    var doc = document.implementation.createDocument('', '', null);
    const pi = doc.createProcessingInstruction(
      'xml',
      'version="1.0" encoding="UTF-8"'
    );
    doc.insertBefore(pi, doc.firstChild);
    var valuesElem = doc.createElement('values');
    valuesElem.appendChild(doc.createTextNode('\n    '));
    objs.forEach((x, index) => {
      var record = doc.createElement('record');
      record.setAttribute('name', x.name);
      record.setAttribute('value', x.value);
      record.setAttribute('type', x.type);
      valuesElem.appendChild(record);
      // Add newline character after each record except the last one
      if (index < objs.length - 1) {
        valuesElem.appendChild(doc.createTextNode('\n    '));
      }
    });
    valuesElem.appendChild(doc.createTextNode('\n'));
    doc.appendChild(valuesElem);
    const xmlString = new XMLSerializer().serializeToString(doc);
    return xmlString;
  }

  public getExtendedObjs(XMLStr: string) {
    let params_records = XML.fromXMLStrToRecords(XMLStr);
    let objList: ExtendedObj[] = [];
    for (let i = 0; i < params_records.length; i++) {
      let attributes = params_records[i].attributes;
      let name = attributes[0].value;
      let value = attributes[1].value;
      let type = attributes[2].value;
      let um = attributes[3].value;
      let category = attributes[4].value;
      let permission = attributes[5].value;
      let id = attributes[6].value;
      objList.push(
        new ExtendedObj(
          Number(id),
          name,
          value,
          type,
          um,
          category,
          Number(permission)
        )
      );
    }
    return objList;
  }

  public getCommentsObjs() {
    let commentsList: CommentObj[] = [];
    this.fileService
      .getFileContent('/assets/comments.xml')
      .subscribe((data) => {
        let comments_records = XML.fromXMLStrToRecords(data.toString());
        for (let i = 0; i < comments_records.length; i++) {
          let attributes = comments_records[i].attributes;
          let name = attributes.length > 0 ? attributes[0].value : '-';
          let description = attributes.length > 1 ? attributes[1].value : '-';
          commentsList.push(new CommentObj(name, description));
        }
      });
    return commentsList;
  }
}

export class ExtendedObj {
  id: number = 0;
  name: string = '';
  value: string = '';
  type: string = '';
  um: string = '';
  category: string = '';
  permission: number = 0;
  constructor(
    id: number,
    name: string,
    value: string,
    type: string,
    um: string,
    category: string,
    permission: number
  ) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.type = type;
    this.um = um;
    this.category = category;
    this.permission = permission;
  }
}

export class CommentObj {
  name: string = '';
  description: string = '';
  constructor(name: string, description: string) {
    this.name = name;
    this.description = description;
  }
}
