import { ChangeDetectorRef, ElementRef } from "@angular/core";

export class StepBarUtility{
    constructor(private el: ElementRef, private readonly cdRef: ChangeDetectorRef){

    }

    public removeFromSuperLayer(superLayer: SVGElement, element: any) {
        let node: any = null;
        superLayer.childNodes.forEach((x) => {
          if (x.isSameNode(element)) {
            node = x;
          }
        });
        if (node != null) superLayer.removeChild(node);
      }

      public removeAllFromSuperLayer(superLayer: SVGElement) {
        superLayer.childNodes.forEach((x) => {
            superLayer.removeChild(x);
        });
      }
      getOffSetWidth() {
        // run change detection
        this.cdRef.detectChanges();
        // And then try getting the offset
        const offsetWidth = this.el.nativeElement.offsetWidth;
        return offsetWidth; 
    }
    
    getOffSetHeight() {
      // run change detection
      this.cdRef.detectChanges();
      // And then try getting the offset
      const offsetHeight = this.el.nativeElement.offsetHeight;
      return offsetHeight; 
    }
}
