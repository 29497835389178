export class SetDate {
  constructor() {}

  public setDate(date: Date) {
    let labelDate = '';
    let today = new Date();
    if (
      date.getFullYear() == today.getFullYear() &&
      date.getMonth() == today.getMonth() &&
      date.getDate() == today.getDate()
    ) {
      labelDate = 'Today';
    } else {
      labelDate =
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
        '/' +
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        '/' +
        date.getFullYear();
    }
    return labelDate;
  }
}
