<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div
  class="central_block"
  *ngIf="this.view"
  (window:keypress)="this.keyDownFunction($event)"
>
  <div class="form">
    <p class="label">Device sn</p>
    <p class="required">*</p>
    <input id="add_device_sn" type="text" class="input" />
    <p class="label">Device alias</p>
    <input
      id="add_device_alias"
      type="text"
      class="input"
      placeholder="new_device"
    />
    <div>
      <button
        class="submit delete"
        (click)="this.view = false; this.close.emit(false)"
      >
        Cancel
      </button>
      <button class="submit" (click)="add_divice()">OK</button>
    </div>
  </div>
</div>
<app-warning
  [title]="'Server response'"
  [background_color]="this.warning_background_color"
  [message]="[this.warning_message]"
  [warning]="this.view_warning"
  (response)="this.view_warning = $event"
></app-warning>
