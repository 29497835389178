/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { Component, OnInit, OnDestroy, Injectable } from '@angular/core';
import { HomeComponent } from 'src/app/home/home.component';

import { HttpClient } from '@angular/common/http';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { Command } from 'src/app/dashboard-menu/command';
import { Tools, deviceType, rebootType } from 'src/model/app.tools';
import { Firmware } from './firmware';
import { AppDate } from 'src/model/app.date';
import { DeviceManager, DeviceMethods } from 'src/model/app.deviceManager';

@Component({
  selector: 'app-advanced-general',
  templateUrl: './advanced-general.component.html',
  styleUrls: ['./advanced-general.component.css'],
})
@Injectable({
  providedIn: 'root',
})
export class AdvancedGeneralComponent implements OnInit, OnDestroy {
  // 0 datalogger 1 battery
  public type: number = 0;

  public type_title: string[] = ['DATALOGGER', 'BATTERY'];

  public options: boolean[] = [false, false, false];
  public is_active: boolean[] = [false, false, false];
  public labels: string[] = [
    'Accepted',
    'Downloading',
    'Downloaded',
    'SignatureVerified',
    'Installing',
    'Installed',
  ];

  public tools: Tools = new Tools(this.app, this.http, this.devMan);
  public newFirmwareDatalogger = new Firmware();
  public newFirmwareBattery = new Firmware();

  public interval_check_target: any;

  constructor(
    public home: HomeComponent,
    public app: AppRoutingModule,
    public http: HttpClient,
    public devMan: DeviceManager
  ) {
    this.devMan.battery_num = 0;
    this.get_firmware(0);
    this.get_firmware(1);
  }

  ngOnInit(): void {
    this.tools.onReb = () => {
      this.is_active[1] = false;
      this.is_active[2] = false;
    };
    this.tools.onInfo = (index: number, rssi: string, fw_ver: string) => {
      this.devMan.updateValueForProperty('isOnline', true, index);
      this.devMan.updateValueForProperty('rssi', rssi, index);
      this.devMan.updateValueForProperty('fw_ver', fw_ver, index);
    };
    this.tools.onTimeoutInfo = (index) => {
      this.devMan.updateValueForProperty('isOnline', false, index);
      this.devMan.updateValueForProperty('rssi', '-', index);
      this.devMan.updateValueForProperty('fw_ver', '-', index);
    };
    this.updateInfo();
    this.interval_check_target = setInterval(() => {
      this.updateInfo();
    }, 30 * 1000);
  }

  ngOnDestroy(): void {
    this.tools.disconnect();
  }

  public src_img() {
    if (this.type == 0) {
      return DeviceMethods.device_type(this.devMan.device.sn.substring(0, 2));
    }
    return DeviceMethods.device_type('02');
  }
  public product() {
    if (this.type == 0) {
      return DeviceMethods.device_type_name(
        this.devMan.device.sn.substring(0, 2)
      );
    }
    return DeviceMethods.device_type_name('02');
  }

  public sn() {
    if (this.type == 0) {
      return this.devMan.device.sn;
    }
    return this.devMan.getCurrentBattery().sn;
  }

  public async command(command: string) {
    this.home.change_component('tools');
    switch (command) {
      case 'Console':
        this.home.advanced_tools.change_types(1);
        break;
      case 'Device settings':
        this.home.advanced_tools.change_types(2);
        break;
    }
  }

  private updateInfo() {
    let type = deviceType.datalogger;
    let index = '-1';
    if (this.type != 0) {
      type = deviceType.battery;
      index = this.devMan.battery_num.toString();
    }
    /**
     * TODO: Update status
     */
    // If the type is datalogger is always possible check if is online
    this.tools.baseInfo(type, index);
    this.tools.getStatus();
  }

  /**
   * Set needed info to update firmware of Datalogger or Battery
   * @param type 0 Datalogger, 1 Battery
   */
  private get_firmware(dev_type: Number) {
    let type = 0;
    if (dev_type == 0) {
      if (DeviceMethods.deviceLinux(this.devMan.device.sn.substring(0, 2)))
        type = 1;
      else type = 0;
    }
    if (dev_type == 1) {
      type = 2;
    }
    this.http
      .post<Firmware | boolean>(this.app.server_domain + '/advanced.php', {
        request: 'get_update_firmware',
        token: this.app.token,
        device_type: type,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            //server error
            return;
          }
          if (result == null) result = new Firmware();
          if (dev_type == 0) this.newFirmwareDatalogger = result;
          if (dev_type == 1) this.newFirmwareBattery = result;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  public checkVersion() {
    let new_version =
      this.type == 0
        ? this.newFirmwareDatalogger.firmware_version
        : this.newFirmwareBattery.firmware_version;
    let current_version =
      this.type == 0
        ? this.devMan.device.fw_ver
        : this.devMan.getCurrentBattery().fw_ver;

    if (current_version === '-') {
      return '-';
    } else if (new_version === current_version) {
      return 'Up to date';
    } else {
      return 'New version available: ' + new_version;
    }
  }

  public disableUpdate() {
    // If online is false or we waiting a response or the device is BUSY we can't do update
    return (
      !this.isOnline() ||
      this.tools.waiting_update_response ||
      this.tools.status.busy == 1
    );
  }

  public async updateFirmware() {
    if (this.disableUpdate()) return;
    let type = this.type == 0 ? deviceType.datalogger : deviceType.battery;
    this.tools.update(
      type,
      this.newFirmwareDatalogger,
      this.newFirmwareBattery
    );
  }

  public switchDevice(type: string) {
    if (this.type == 0) {
      this.type = 1;
      this.updateInfo();
      return;
    }
    if (this.type == 1) {
      if (
        type == 'right' &&
        this.devMan.battery_num == this.devMan.batteries_tot_num - 1
      ) {
        this.type = 0;
        this.devMan.battery_num = 0;
        this.updateInfo();
        return;
      } else if (type == 'left' && this.devMan.battery_num == 0) {
        this.type = 0;
        this.devMan.battery_num = this.devMan.batteries_tot_num - 1;
        this.updateInfo();
        return;
      }
    }
    this.devMan.switch(type);
    this.updateInfo();
  }

  public open_option(
    command: Command = new Command('', [false, false, false])
  ) {
    this.is_active = command.active;
    switch (command.command) {
      case 'tools':
        break;
      case 'reboot':
        if (this.type == 0) this.tools.reboot(rebootType.datalogger);
        else this.tools.reboot(rebootType.battery);
        break;
      case 'reboot_all':
        this.tools.reboot(rebootType.all_battery);
        break;
    }
  }

  public lastRemoteUpdateHistory(timestamp: number) {
    let date = new Date(timestamp);

    return AppDate.stringFromDate(
      date,
      AppDate.format_day2_month2_yearnum_hour2_minute2
    );
  }

  public optionsLabel() {
    let w = window.innerWidth;
    if (w >= 800) {
      return true;
    }
    return false;
  }

  public stepperLabel() {
    let w = window.innerWidth;
    if (w >= 900) {
      return true;
    }
    return false;
  }

  public annularStepperRadius() {
    let w = window.innerWidth;
    if (w >= 500) {
      return true;
    }
    return false;
  }

  public isOnline() {
    if (this.type == 0) return this.devMan.device.isOnline;
    else return this.devMan.batteries[this.devMan.battery_num].isOnline;
  }
}
