/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { AppDate } from '../app.date';
import { CanvasResize } from './CanvasResize';
import { sprintf } from 'sprintf-js';

export class Point {
  x: number = 0;
  y: number = 0;
  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

export class Chart {
  ctx: CanvasRenderingContext2D | null | undefined;
  canvasEl: HTMLCanvasElement | undefined;
  private id: string = '';
  private height: number = 0;

  tickOriginX: number = 0.0;
  tickOriginY: number = 0.0;
  gridColor: string = '#ffffff';
  lineColor: string = '#FF0202';
  scatterColor: string = '#FF0202';
  disabledColor: string = '#C5C5C5';
  surfaceColor: string = '#C5C5C5';
  textColor: string = '#ffffff';
  axisColor: string = '#ffffff';
  tickerFormat: string = AppDate.format_hour2_minute2;
  tickCountX: number = 5;
  tickCountY: number = 5;
  tickerType: number = 1;

  private baseRect: DOMRect | undefined;
  private alreadySorted: boolean = true;
  private rangeX: range = { max: 0, min: 0 };
  private rangeY: range = { max: 0, min: 0 };
  private keys: number[] = [];
  private values: number[] = [];
  private status: boolean[] = [];

  private panTop: number = 5.0;
  private panBottom: number = 15.0;
  private panLeft: number = 15.0;
  private panRight: number = 40.0;
  private tickerFont: string = '';
  public selected: number = -1;

  private unselectedTextBGColor: string = '#888A85';
  private selectedTextBGColor: string = '#FFFFFF';
  private dashLengths: [number, number] = [7.0, 3.0];
  private screenScale: number = window.devicePixelRatio;

  private lminX: number = 0.0;
  private lmaxX: number = 0.0;
  private lminY: number = 0.0;
  private lmaxY: number = 0.0;

  private ticksX: number[] = [];
  private ticksY: number[] = [];
  private tickStepX: number = 0.0;
  private tickStepY: number = 0.0;

  private balloonPoint: Point | null = null;
  public balloonValue: string | null = null;
  public balloonDate: string | null = null;
  private balloonTimer: any = null;

  private canvas_resize: CanvasResize = new CanvasResize();

  constructor(lineColor: string, scatterColor: string, surfaceColor: string) {
    this.lineColor = lineColor;
    this.scatterColor = scatterColor;
    this.surfaceColor = surfaceColor;
    this.panTop = 5;
    this.panLeft = 15;
    this.panRight = 15;
    this.panBottom = 15;
  }

  longPress() {
    this.canvasEl!.addEventListener('click', (e) => {
      const x = e.offsetX;
      const y = e.offsetY;
      let coeX =
        (this.baseRect!.width - this.panRight - this.panLeft) /
        (this.tickStepX * Number(this.ticksX.length));
      let coeY =
        (this.baseRect!.height - this.panBottom - this.panTop) /
        (this.lmaxY - this.lminY);
      var index = -1;
      var pX: number = 0.0;
      var pY: number = 0.0;
      var find = -1;
      var distance_min: number = Number.POSITIVE_INFINITY;
      for (let p of this.keys) {
        index += 1;

        pX = coeX * (p - this.lminX) + this.panLeft;
        pY = coeY * (this.lmaxY - this.values[index]) + this.panTop;

        let dx = x - pX;
        let dy = y - pY;

        let distance = Math.sqrt(dx * dx + dy * dy);
        if (distance <= distance_min) {
          distance_min = distance;
          find = index;
          this.balloonPoint = new Point(pX, pY);
          this.balloonValue = 'Value: ' + sprintf('%6.2f', this.values[find]);
          let date = new Date(this.keys[find] * 1000);
          let label = AppDate.stringFromDate(date, this.tickerFormat);
          this.balloonDate = 'Time: ' + label;
        }
      }
      if (
        find != -1 &&
        distance_min < 15.0 &&
        this.values[find] != Number.NaN
      ) {
        this.selected = find;

        if (this.balloonTimer != null) {
          clearTimeout(this.balloonTimer);
        }

        this.balloonTimer = setTimeout(() => {
          this.selected = -1; //todo
          this.draw(this.id, this.height, this.tickerFormat);
          clearTimeout(this.balloonTimer);
        }, 10 * 1000);
      } else {
        this.unselect();
      }
      this.draw(this.id, this.height, this.tickerFormat);
    });
  }

  public clearBalloon() {
    this.selected = -1; //todo
  }

  private unselect() {
    this.selected = -1;
    this.balloonPoint = null;
    this.balloonValue = null;
    this.balloonDate = null;
    if (this.balloonTimer != null) {
      clearTimeout(this.balloonTimer);
    }
    this.balloonTimer = setTimeout(() => {
      this.selected = -1; //todo
      this.draw(this.id, this.height, this.tickerFormat);
      clearTimeout(this.balloonTimer);
    }, 10 * 1000);
  }

  draw(id: string, height: number, dateFormat: string): void {
    this.id = id;
    this.height = height;
    this.tickerFormat = dateFormat;
    this.canvasEl = document.getElementById(this.id)! as HTMLCanvasElement;

    if (this.canvasEl) {
      let dimensions = this.canvas_resize.getObjectFitSize(
        true,
        this.canvasEl.clientWidth,
        this.canvasEl.clientHeight,
        this.canvasEl.width,
        this.canvasEl.height
      );

      this.canvasEl.width = dimensions.width;
      this.canvasEl.height = this.height;

      this.ctx = this.canvasEl.getContext('2d');

      if (this.ctx) {
        this.ctx.fillStyle = '#000000'; // text color
        this.ctx.font = '9px Arial';
        this.ctx.imageSmoothingEnabled = false;
        this.ctx.imageSmoothingQuality = 'high';
        this.baseRect = this.canvasEl.getBoundingClientRect();

        this.drawGrids();
        this.drawAxis();
        this.drawTickers();

        this.drawSurface();
        this.drawLines();
        this.drawScatters();

        if (this.selected != -1) {
          // this.drawBalloon(
          //   this.balloonPoint!,
          //   this.balloonValue!,
          //   this.balloonDate!
          // );
        }
      }
    }
  }

  public setData(
    keys: number[],
    values: number[],
    alreadySorted: boolean = true
  ) {
    this.alreadySorted = alreadySorted;
    this.keys = keys;
    this.values = values;
    if (this.keys.length < this.values.length) {
      this.values = this.values.slice(0, this.keys.length);
    } else if (this.keys.length > this.values.length) {
      this.keys = this.keys.slice(0, this.values.length);
    }
  }

  public clearData() {
    this.alreadySorted = true;
    this.keys = [];
    this.values = [];
    this.status = [];
    this.ctx?.clearRect(0, 0, this.baseRect!.width, this.baseRect!.height);
  }

  drawAxis(): void {
    if (this.ctx && this.baseRect) {
      this.ctx.beginPath();
      // left vert line
      this.ctx.moveTo(
        this.panLeft,
        this.baseRect.height - this.panBottom + this.ctx.lineWidth
      );
      this.ctx.lineTo(
        this.baseRect.width - (this.panLeft + this.panRight),
        this.baseRect.height - this.panBottom + this.ctx.lineWidth
      );
      this.ctx.strokeStyle = this.axisColor;

      this.ctx.setLineDash([]);
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }

  drawGrids(): void {
    if (this.ctx && this.baseRect) {
      let step =
        Math.ceil(this.baseRect.width - (this.panLeft + this.panRight)) /
        this.ticksX.length;

      for (let i = 0; i < this.ticksX.length; i++) {
        this.ctx.beginPath();
        this.ctx.moveTo(Math.floor(this.panLeft + step * i) + 0.5, this.panTop);
        this.ctx.lineTo(
          Math.floor(this.panLeft + step * i) + 0.5,
          this.baseRect.height - this.panBottom
        );
        this.ctx.strokeStyle = this.gridColor + '33';
        this.ctx.setLineDash(this.dashLengths);
        this.ctx.stroke();
        this.ctx.closePath();
      }
    }
  }

  drawTickers(): void {
    if (this.ctx && this.baseRect) {
      this.ctx.beginPath();

      switch (this.tickerType) {
        case 0:
          break;
        case 1:
          let stepPixelX =
            Math.ceil(this.baseRect.width - (this.panLeft + this.panRight)) /
            this.ticksX.length;
          this.ctx.fillStyle = this.gridColor;
          for (let i = 0; i < this.ticksX.length; i++) {
            let date = new Date(this.ticksX[i] * 1000);
            let label = AppDate.stringFromDate(date, this.tickerFormat);
            let stringSize = this.ctx.measureText(label);
            this.ctx.fillText(
              label,
              this.panLeft + stepPixelX * i - stringSize.width / 2.0,
              this.baseRect.height -
                this.panBottom +
                stringSize.actualBoundingBoxAscent +
                5.0
            );
            this.ctx.stroke();
            this.ctx.closePath();
          }

          let coeY =
            (this.baseRect.height - this.panBottom - this.panTop) /
            (this.lmaxY - this.lminY);

          for (let i = 0; i < this.ticksY.length; i++) {
            this.ctx.beginPath();

            let label = sprintf('%5.0f', this.ticksY[i]);
            let stringSize = this.ctx.measureText(label);
            this.ctx.fillText(
              label,
              this.baseRect.width - this.panRight - 10.0,
              coeY * (this.lmaxY - this.ticksY[i]) +
                this.panTop +
                stringSize.actualBoundingBoxAscent / 2.0 -
                2.0
            );
            this.ctx.stroke();
            this.ctx.closePath();
          }

          break;
        default:
          break;
      }
      this.ctx.strokeStyle = this.axisColor;
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }

  drawSurface(): void {
    if (this.ctx && this.baseRect) {
      let coeX =
        (this.baseRect.width - this.panRight - this.panLeft) /
        (this.tickStepX * this.ticksX.length);
      let coeY =
        (this.baseRect.height - this.panBottom - this.panTop) /
        (this.lmaxY - this.lminY);

      var index = -1;
      var pX: number = 0.0;
      var pY: number = 0.0;

      this.ctx.beginPath();
      this.ctx.lineWidth = 0;

      this.ctx.fillStyle = this.surfaceColor;

      var start = true;
      var lastX = pX;
      var lastY = pY;
      var count = 0;

      for (let i = 0; i < this.keys.length; i++) {
        let p = this.keys[i];
        index += 1;

        pX = coeX * (p - this.lminX) + this.panLeft;

        if (isNaN(this.values[index])) {
          if (!start) {
            this.ctx.lineTo(lastX, lastY);
            this.ctx.closePath();
            if (count > 1) {
              this.ctx.fill();
            }
            count = 0;
            this.ctx.beginPath();
            this.ctx.lineWidth = 0;
            start = true;
          }
          continue;
        }
        pY = coeY * (this.lmaxY - this.values[index]) + this.panTop;
        count += 1;
        if (start) {
          var y = 0;
          if (this.rangeY.min <= 0 && this.rangeY.max >= 0) {
            y = coeY * this.lmaxY + this.panTop;
          } else {
            y =
              this.values[index] > 0
                ? this.baseRect.height - this.panBottom
                : 0 + this.panTop;
          }
          this.ctx.moveTo(pX, y);
          this.ctx.lineTo(pX, pY);
          start = false;
        }
        if (index == 0) {
          continue;
        }

        if (!isNaN(this.values[index - 1])) {
          lastX = pX;
          if (this.rangeY.min <= 0 && this.rangeY.max >= 0) {
            lastY = coeY * this.lmaxY + this.panTop;
          } else {
            lastY =
              this.values[index] > 0
                ? this.baseRect.height - this.panBottom
                : 0 + this.panTop;
          }
          this.ctx.lineTo(pX, pY);
        }
      }
      if (!start) {
        this.ctx.lineTo(lastX, lastY);
        this.ctx.closePath();
        if (count > 1) {
          this.ctx.fill();
        }
      }
    }
  }
  drawLines(): void {
    if (this.ctx && this.baseRect) {
      let coeX =
        (this.baseRect.width - this.panRight - this.panLeft) /
        (this.tickStepX * this.ticksX.length);
      let coeY =
        (this.baseRect.height - this.panBottom - this.panTop) /
        (this.lmaxY - this.lminY);

      var index = -1;
      var pX: number = 0.0;
      var pY: number = 0.0;

      for (let i = 0; i < this.keys.length; i++) {
        let p = this.keys[i];
        index += 1;
        this.ctx.beginPath();
        this.ctx.moveTo(pX, pY);

        //inizialize animation
        // var animation = new lineAnimation();
        // animation.x = pX;
        // animation.y = pY;

        pX = coeX * (p - this.lminX) + this.panLeft;

        if (isNaN(this.values[index])) {
          continue;
        }

        pY = coeY * (this.lmaxY - this.values[index]) + this.panTop;

        if (index == 0) {
          continue;
        }

        if (
          this.status.length > index &&
          this.status[index] &&
          this.status[index - 1]
        ) {
          this.ctx.strokeStyle = this.disabledColor;
        } else {
          this.ctx.strokeStyle = this.lineColor;
        }

        this.ctx.lineWidth = 2;

        if (isNaN(this.values[index - 1]) == false) {
          // let incrementX = (pX - animation.x) / 30;
          // let incrementY = (pY - animation.y) / 30;

          // animation.interval = setInterval(() => {

          // }, 5);
          this.ctx.lineTo(pX, pY);
          this.ctx.stroke();
          this.ctx.closePath();
        }
      }
    }
  }
  drawScatters(): void {
    if (this.ctx && this.baseRect) {
      let coeX =
        (this.baseRect.width - this.panRight - this.panLeft) /
        (this.tickStepX * this.ticksX.length);
      let coeY =
        (this.baseRect.height - this.panBottom - this.panTop) /
        (this.lmaxY - this.lminY);

      var index = -1;
      var pX: number = 0.0;
      var pY: number = 0.0;

      for (let i = 0; i < this.keys.length; i++) {
        this.ctx.beginPath();

        let p = this.keys[i];
        index += 1;

        if (index >= this.values.length) {
          break;
        }

        if (isNaN(this.values[index])) {
          continue;
        }

        if (p < this.lminX || p > this.lmaxX) {
          continue;
        }

        if (
          this.values[index] < this.lminY ||
          this.values[index] > this.lmaxY
        ) {
          continue;
        }

        pX = coeX * (p - this.lminX) + this.panLeft;
        pY = coeY * (this.lmaxY - this.values[index]) + this.panTop;

        this.ctx.fillStyle = this.scatterColor;

        var radius = 5.0;
        if (index == this.selected) {
          radius = 7.0;
          this.ctx.fillStyle = '#ffffff' + '4D';
        }

        this.ctx.arc(pX, pY, radius, 0, 2 * Math.PI);

        this.ctx.fill();
        this.ctx.closePath();

        this.ctx.beginPath();
        this.ctx.fillStyle =
          this.status.length > index && this.status[index] == true
            ? this.disabledColor
            : this.lineColor;
        if (index == this.selected) {
          this.ctx.fillStyle = '#ffffff';
        }
        this.ctx.arc(pX, pY, radius / 2 + 1, 0, 2 * Math.PI);
        this.ctx.fill();
        this.ctx.closePath();
      }
    }
  }

  private roundRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: {
      upperLeft: number;
      upperRight: number;
      lowerLeft: number;
      lowerRight: number;
    }
  ): void {
    if (ctx != null) {
      ctx.moveTo(x + radius.upperLeft, y);
      ctx.lineTo(x + width - radius.upperRight, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius.upperRight);
      ctx.lineTo(x + width, y + height - radius.lowerRight);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius.lowerRight,
        y + height
      );
      ctx.lineTo(x + radius.lowerLeft, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius.lowerLeft);
      ctx.lineTo(x, y + radius.upperLeft);
      ctx.quadraticCurveTo(x, y, x + radius.upperLeft, y);
    }
  }

  drawBalloon(point: Point, value: string, date: string) {
    if (this.ctx && this.baseRect) {
      let asvalue = this.ctx?.measureText(value);
      let asdate = this.ctx?.measureText(date);

      var bw = asvalue!.width > asdate!.width ? asvalue!.width : asdate!.width;
      bw += 10;

      var bx = point.x - bw / 2.0;
      if (bx < this.panLeft) {
        bx = this.panLeft;
      }
      if (bx > this.baseRect!.width - this.panRight - bw) {
        bx = this.baseRect!.width - this.panRight - bw;
      }
      let by = this.baseRect!.height - this.panBottom - 45;
      this.ctx.beginPath();
      this.roundRect(this.ctx, bx, by, bw, 33, {
        upperLeft: 5,
        upperRight: 5,
        lowerLeft: 5,
        lowerRight: 5,
      });
      this.ctx.fillStyle = '#ffffff';
      this.ctx.fill();
      this.ctx.closePath();

      this.ctx.beginPath();
      this.ctx.font = '13px Arial';
      this.ctx.fillStyle = 'black';
      this.ctx.fillText(value, bx + 3, by + 14, bw - 5);
      this.ctx.fillText(date, bx + 3, by + 29, bw - 5);
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }

  

  setRangeX(min: number, max: number) {
    if (min < max) {
      this.rangeX = { min: min, max: max };
      this.generate();
    }
  }

  setRangeY(min: number, max: number) {
    if (min < max) {
      this.rangeY = { min: min, max: max };
      this.generate();
    }
  }

  setStatus(status: boolean[]) {
    this.status = status;
  }

  static dateTimeToKey(date: Date): number {
    return Math.round(date.getTime() / 1000);
  }

  private generate() {
    //X ticks
    this.tickStepX = this.getTickStepDate(this.rangeX);
    this.ticksX = this.createTickVector(
      this.tickStepX,
      this.tickOriginX,
      this.rangeX
    );
    this.ticksX = this.trimTicks(this.rangeX, this.ticksX, false);
    this.lminX = this.ticksX[0];
    this.lmaxX = this.lminX + Number(this.ticksX.length) * this.tickStepX;

    //Y ticks
    this.tickStepY = this.getTickStep(this.rangeY);
    this.ticksY = this.createTickVector(
      this.tickStepY,
      this.tickOriginY,
      this.rangeY
    );
    this.ticksY = this.trimTicks(this.rangeY, this.ticksY, false);
    this.lminY = this.rangeY.min;
    this.lmaxY = this.rangeY.max;
  }

  private getTickStep(range: range): number {
    let exactStep = (range.max - range.min) / (Number(this.tickCountY) + 1e-10); // mTickCount ticks on average, the small addition is to prevent jitter on exact integers
    return this.cleanMantissa(exactStep);
  }

  private getTickStepDate(range: range): number {
    var result = (range.max - range.min) / (Number(this.tickCountX) + 1e-10); // mTickCount ticks on average, the small addition is to prevent jitter on exact integers
    if (result < 1) {
      result = this.cleanMantissa(result);
    } else if (result < 86400 * 30.4375 * 12) {
      // below a year
      result = this.pickClosest(result, [
        1.0,
        2.5,
        5.0,
        10.0,
        15.0,
        30.0,
        60.0,
        2.5 * 60,
        5.0 * 60,
        10.0 * 60,
        15.0 * 60,
        30.0 * 60,
        60.0 * 60, // second, minute, hour range
        3600.0 * 2,
        3600.0 * 3,
        3600.0 * 6,
        3600.0 * 12,
        3600.0 * 24, // hour to day range
        86400.0 * 2,
        86400.0 * 5,
        86400.0 * 7,
        86400.0 * 14,
        86400.0 * 30.4375,
        86400.0 * 30.4375 * 2,
        86400.0 * 30.4375 * 3,
        86400.0 * 30.4375 * 6,
        86400.0 * 30.4375 * 12,
      ]); // day, week, month range (avg. days per month includes leap years)
      if (result > 86400 * 30.4375 - 1) {
        // month tick intervals or larger
        //mDateStrategy = dsUniformDayInMonth
      } else if (result > 3600 * 24 - 1) {
        // day tick intervals or larger
        //mDateStrategy = dsUniformTimeInDay;
      }
    } else {
      // more than a year, go back to normal clean mantissa algorithm but in units of years
      let secondsPerYear: number = 86400.0 * 30.4375 * 12; // average including leap years
      result = this.cleanMantissa(result / secondsPerYear) * secondsPerYear;
      //mDateStrategy = dsUniformDayInMonth;
    }
    return result;
  }

  private createTickVector(
    tickStep: number,
    origin: number,
    range: range
  ): number[] {
    var result: number[] = [];
    let firstStep: number = Number(Math.floor((range.min - origin) / tickStep));
    let lastStep: number = Number(Math.ceil((range.max - origin) / tickStep));
    var tickCount: number = lastStep - firstStep + 1;

    if (tickCount < 0) {
      tickCount = 0;
    }

    for (let i = 0; i < tickCount; i++) {
      result.push(origin + Number(firstStep + i) * tickStep);
    }
    return result;
  }

  private trimTicks(range: range, ticks: number[], keepOneOutlier: boolean) {
    var lowFound: boolean = false;
    var highFound: boolean = false;
    var lowIndex = 0;
    var highIndex = -1;

    for (let i = 0; i < ticks.length; i++) {
      if (ticks[i] >= range.min) {
        lowFound = true;
        lowIndex = i;
        break;
      }
    }

    for (let i = ticks.length - 1; i >= 0; i--) {
      if (ticks[i] <= range.max) {
        highFound = true;
        highIndex = i;
        break;
      }
    }

    if (highFound && lowFound) {
      let trimFront = Math.max(0, lowIndex - (keepOneOutlier ? 1 : 0));
      let trimBack = Math.max(
        0,
        ticks.length - (keepOneOutlier ? 2 : 1) - highIndex
      );
      if (trimFront > 0 || trimBack > 0) {
        ticks = ticks.slice(trimFront, ticks.length - trimBack);
      }
      return ticks;
    }
    return [];
  }

  private cleanMantissa(input: number): number {
    var magnitude: number | null = Number();
    let temp = this.getMantissa(input, magnitude);
    magnitude = temp.magnitude;
    let mantissa = temp.mantissa;

    return this.pickClosest(mantissa, [1.0, 2.0, 2.5, 5.0, 10.0]) * magnitude!;
  }

  private pickClosest(target: number, candidates: number[]): number {
    if (candidates.length == 1) {
      return candidates[0]!;
    }

    var index = 0;
    for (let i = 0; i < candidates.length; i++) {
      let ele = candidates[i];
      if (target < ele) {
        break;
      }
      index += 1;
    }

    if (index >= candidates.length) {
      return candidates[index - 1];
    }

    if (index == 0) {
      return candidates[0]!;
    }

    return target - candidates[index - 1] < candidates[index] - target
      ? candidates[index - 1]
      : candidates[index];
  }

  private getMantissa(
    input: number,
    magnitude: number | null
  ): { magnitude: number | null; mantissa: number } {
    let mag = Math.pow(10.0, Math.floor(Math.log10(input)));
    if (magnitude != null) {
      magnitude = mag;
    }
    return { magnitude: magnitude, mantissa: input / mag };
  }
}

export class range {
  public max: number = 0;
  public min: number = 0;
}

// class lineAnimation {
//   public interval: any;
//   public x: number = 0;
//   public y: number = 0;
// }
