<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->
<div id="body" *ngIf="this.app.pages.types[5]">
  <div class="logo">
    <img src="../../assets/logo-archimede-b.png" alt="" />
  </div>
  <div class="central_block">
    <div>
      <p class="title">Account deleted</p>
    </div>

    <div class="form">
      <div>
        <p class="text" style="padding-bottom: 40px">
          Your account has been deleted
        </p>
      </div>
    </div>
  </div>
</div>
