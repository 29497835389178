/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppRoutingModule } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root',
})
export class DeviceManager {
  public device: Device = new Device();

  public batteries: Battery[] = [];
  public batteries_tot_num: number = 0;
  public battery_num: number = 0;

  public update: boolean = false;

  constructor(private http: HttpClient, public app: AppRoutingModule) {}

  public get_number_of_batteries(sn: string) {
    this.http
      .post<any>(this.app.server_domain + '/battery.php', {
        request: 'device_detail',
        token: this.app.token,
        sn: sn,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            //error
          } else {
            this.batteries_tot_num = result;
            for (let i = 0; i < this.batteries_tot_num; i++)
              this.batteries.push(new Battery());
            this.loadAllBatteries(this.device.sn);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
  public switch(response: string) {
    if (this.batteries_tot_num == 0) return;
    switch (response) {
      case 'left':
        if (this.battery_num == 0) {
          this.battery_num = this.batteries_tot_num - 1;
        } else {
          this.battery_num--;
        }
        break;
      case 'right':
        if (this.battery_num == this.batteries_tot_num - 1) {
          this.battery_num = 0;
        } else {
          this.battery_num++;
        }
        break;
    }
    this.get_battery_details(this.device.sn, this.battery_num);
  }

  public getCurrentBattery() {
    if (this.batteries.length - 1 < this.battery_num) return new Battery();
    return this.batteries[this.battery_num];
  }

  public loadAllBatteries(sn: string) {
    for (let i = 0; i < this.batteries_tot_num; i++) {
      this.get_battery_details(sn, i);
    }
  }

  public get_battery_details(sn: string, num_battery: number) {
    this.update = false;
    this.http
      .post<Battery>(this.app.server_domain + '/battery.php', {
        request: 'device_battery_detail',
        token: this.app.token,
        sn: sn,
        num_battery: num_battery,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            return;
          }
          this.batteries[num_battery] = result;
          this.update = true;
        },
        (error) => {
          console.error(error);
        }
      );
  }
  public updateValueForProperty(property: string, value: any, index: number) {
    switch (property) {
      case 'isOnline':
        if (index == -1) this.device.isOnline = value;
        if (index > -1 && index < this.batteries.length)
          this.batteries[index].isOnline = value;
        break;
      case 'fw_ver':
        if (index == -1) this.device.fw_ver = value;
        if (index > -1 && index < this.batteries.length)
          this.batteries[index].fw_ver = value;
        break;
      case 'rssi':
        if (index == -1) this.device.rssi = value;
        if (index > -1 && index < this.batteries.length)
          this.batteries[index].rssi = value;
        break;
    }
  }
  public resetBatteries() {
    this.batteries = [];
    this.battery_num = 0;
    this.batteries_tot_num = 0;
  }
}

export class Battery {
  public sn: string = '';
  public baseid: number = 0;
  public name: string = '';
  public soc: number = 0;
  public canbus_timeout: number = 0;
  public sw: number = 0;
  public sw2: number = 0;
  public current: number = 0;
  public count: string = '';
  public v_max: number = 0;
  public v_min: number = 0;
  public voltage: number = 0;
  public cycles: number = 0;
  public t_max: number = 0;
  public t_min: number = 0;
  public is_cluster: number = 0;
  public last_update: string = '';
  public rssi: string = '-';
  public fw_ver: string = '-';
  public isOnline: boolean = false;

  constructor() {}
}

export class Device {
  public alias: string = '';
  public sn: string = '';
  public is_online: boolean = false;
  public lat: number = 0;
  public lng: number = 0;
  public fw_ver: string = '-';
  public rssi: string = '-';
  public isOnline: boolean = false;

  constructor() {}
}

export class BatteryMethods {
  constructor() {}

  static dec2hex(decString: string, precision: number) {
    if (decString == '-') {
      return '-';
    }
    let decNum = parseInt(decString).toString(16);
    if (decNum.length < precision) {
      for (let i = decNum.length; i < precision; i++) {
        decNum = '0' + decNum;
      }
    }

    return '0x' + decNum.toUpperCase();
  }

  /**
   * Check if a Device is alarm, if device is offline is not alarmed
   * @param device
   * @returns true if device is alarmed, false otherwise
   */
  static check_battery_alarm(device: Battery) {
    /**
     * We apply mask 0x84ff (decimal: 34047) to device sw
     * If ((device.sw and 0x84ff) != 0x0) then device is in error
     */
    let mask: string[] = this.dec2bin(34047, 16);
    let sw = this.dec2bin(device.sw, 16);

    for (let i = 0; i < 16; i++) {
      if (sw[i] == '1' && mask[i] == '1') {
        return true;
      }
    }
    return false;
  }

  static dec2bin(number: number, precision: number) {
    let bin = Number(number).toString(2).split('').reverse();
    if (bin.length < precision) {
      for (let i = bin.length; i < precision; i++) {
        bin.push('0');
      }
    }
    return bin;
  }

  static is_cluster(sn: string) {
    const alphabet = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];
    let temp = sn.toUpperCase();

    for (let i = 0; i < alphabet.length; i++) {
      if (temp.includes(alphabet[i])) {
        return true;
      }
    }
    return false;
  }
}

export class DeviceMethods {
  constructor() {}

  static device_type(code: string): string {
    let path = '../../assets/icon/device-list/device_type/';
    switch (code) {
      case '00':
        path += 'wifi.router.svg';
        break;
      case '01':
        path += 'display.svg';
        break;
      case '02':
        path += 'battery.svg';
        break;
      case '03':
        path += 'apps.iphone.svg';
        break;
    }
    return path;
  }
  public device_type(code: string): string {
    return DeviceMethods.device_type(code);
  }

  static device_type_name(code: string) {
    switch (code) {
      case '00':
        return 'REWIND';
      case '01':
        return 'LINUX DEVICE';
      case '02':
        return 'BATTERY';
      case '03':
        return 'APPLICATION';
    }
    return 'NULL';
  }
  public device_type_name(code: string): string {
    return DeviceMethods.device_type_name(code);
  }

  static deviceLinux(code: string): boolean {
    return code == '01';
  }
  public deviceLinux(code: string): boolean {
    return DeviceMethods.deviceLinux(code);
  }
}
