/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
export class Map {
  public zoom = 12;
  public center: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0,
  };
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  };
  public markers: google.maps.Marker | any = [];
  constructor() {}

  addMarker(lat: number, lng: number, title: string) {
    this.markers.push({
      position: {
        lat: lat,
        lng: lng,
      },
      label: {
        color: 'red',
        //text: this.dashboard.device.alias,
      },
      title: title,
      options: { animation: google.maps.Animation.DROP },
    });
  }

  clearMap() {
    this.markers = [];
  }

  setMap() {
    let l = this.markers.length;
    let lat = 0;
    let lng = 0;
    let min_lat = 0;
    let min_lng = 0;
    let max_lat = 0;
    let max_lng = 0;

    for (let i = 0; i < l; i++) {
      if (min_lat == 0 && max_lat == 0) {
        min_lat = max_lat = this.markers[i].position.lat;
      }
      if (min_lng == 0 && max_lng == 0) {
        min_lng = max_lng = this.markers[i].position.lng;
      }
      if (this.markers[i].position.lat < min_lat) {
        min_lat = this.markers[i].position.lat;
      }
      if (this.markers[i].position.lng < min_lng) {
        min_lng = this.markers[i].position.lng;
      }
      if (this.markers[i].position.lng > max_lat) {
        max_lat = this.markers[i].position.lat;
      }
      if (this.markers[i].position.lng > max_lng) {
        max_lng = this.markers[i].position.lng;
      }
    }
    lat = (max_lat + min_lat) / 2;
    lng = (max_lng + min_lng) / 2;
    this.center = {
      lat: lat,
      lng: lng,
    };
    let grad = Math.max(max_lat - min_lat, max_lng - min_lng);
    let km = grad * 111.121;
    let zoom = Math.log2(40000 / (km / 2));
    this.zoom = zoom - 2;
    if (zoom > 10) {
      this.zoom = 10;
    }
  }
}
