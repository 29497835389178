/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from '@angular/core';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.css'],
})
export class WarningComponent implements OnInit, OnDestroy {
  @Input() warning: boolean = false;
  @Input() message: string[] = [];
  @Input() title: string = '';
  @Input() background_color: string = '';
  @Input() timeout: number = 0;
  public interval: any;

  @Output() response = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    if (this.timeout != 0) {
      this.interval = setInterval(() => {
        this.warning = false;
        this.response.emit(false);
      }, this.timeout * 1000);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
