import {
  Component,
  Input,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { StepBarUtility } from './step-bar-utility';

@Component({
  selector: 'app-annular-layer',

  template: ` <ng-content></ng-content>`,
  styles: [],
})
export class AnnularLayerComponent implements OnInit {
  private utility: StepBarUtility = new StepBarUtility(this.el, this.cdRef);
  public tintColor: string = 'green';
  public strokeColor: string = 'green';
  public tintErrorColor: string = 'red';
  public displayNumber: boolean = false;
  public step: number = 0;
  public annularDefaultColor: string = 'gray';
  public lineWidth: number = 3;
  public svg: SVGElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'svg'
  );
  private fullCircleLayer: SVGCircleElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'circle'
  );
  private centerCircleLayer: SVGCircleElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'circle'
  );
  private flagLayer: SVGImageElement | null = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'image'
  );
  private annularPath: SVGPathElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'circle'
  );
  private centerTextLayer: SVGTextElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'text'
  );
  private flagCGImage: string = '';
  private lineAnimationState = 'hidden';
  private isInitialized = false;

  public width: number = 20;
  public height: number = 20;

  private static originalScale = 'scale(1.0)';
  private static flagImageName =
    '../../assets/icon/step-icon/CYStepIndicator_ic_done_white.svg';
  private static flagErrorImageName =
    '../../assets/icon/step-icon/CYStepIndicator_ic_error_white.svg';

  // Inside your Angular component class
  private _isError: boolean = false;
  // You can define a setter method for the 'isError' property
  @Input() set isError(value: boolean) {
    let tmp = this._isError;
    this._isError = value;
    if (tmp != this._isError) {
      this.updateStatus();
      this.setImage(
        this._isError
          ? AnnularLayerComponent.flagErrorImageName
          : AnnularLayerComponent.flagImageName
      );
    }
  }

  // Inside your Angular component class
  private _isCurrent: boolean = false;
  // You can define a setter method for the 'isError' property
  @Input() set isCurrent(value: boolean) {
    this._isCurrent = value;
    if (value) {
      this._isFinished = false;
    }
    this.updateStatus();
  }

  // Inside your Angular component class
  private _isFinished: boolean = false;
  // You can define a setter method for the 'isError' property
  @Input() set isFinished(value: boolean) {
    let tmp = this._isFinished;
    this._isFinished = value;
    if (tmp != this._isFinished) {
      this.updateStatus();
      this.setImage(AnnularLayerComponent.flagImageName);
    }
  }

  private _showFlag: boolean = true;
  @Input() set showFlag(value: boolean) {
    this._showFlag = value;
    if (!this._showFlag) {
      this.flagLayer = null;
    } else {
      this.flagLayer = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'image'
      );
    }
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isInitialized = true;
  }

  public resizeAnnular(width: number, height: number) {
    this.svg.setAttribute('width', width.toString());
    this.svg.setAttribute('height', height.toString());
    this.utility.removeAllFromSuperLayer(this.svg);
    this.updateStatus();
    if (this._isFinished || this._isError) {
      this.utility.removeFromSuperLayer(this.svg, this.flagLayer);
      this.setImage(
        this._isError
          ? AnnularLayerComponent.flagErrorImageName
          : AnnularLayerComponent.flagImageName
      );
    }
  }

  private setImage(imageName: string): void {
    if (!this.isInitialized) {
      return;
    }
    const sideLength = Math.min(this.width, this.height);

    const circlesRadius = sideLength / 2 - this.lineWidth * 2;
    const pos = (sideLength - circlesRadius) / 2;
    const { flagLayer, svg } = this;

    if (flagLayer == null) return;

    flagLayer.setAttributeNS(null, 'height', circlesRadius.toString());
    flagLayer.setAttributeNS(null, 'width', circlesRadius.toString());
    flagLayer.setAttributeNS('http://www.w3.org/1999/xlink', 'href', imageName);
    flagLayer.setAttributeNS(null, 'x', pos.toString());
    flagLayer.setAttributeNS(null, 'y', pos.toString());
    flagLayer.setAttributeNS(null, 'visibility', 'visible');

    svg.appendChild(flagLayer);
  }

  private drawAnnularPath(): void {
    const {
      annularPath,
      svg,
      renderer,
      el,
      height,
      width,
      lineWidth,
      annularDefaultColor,
    } = this;

    const sideLength = Math.min(width, height);
    const circlesRadius = sideLength / 2 - lineWidth * 2; // 3 is the line width
    annularPath.setAttribute('cx', '50%');
    annularPath.setAttribute('cy', '50%');
    annularPath.setAttribute('r', circlesRadius.toString());
    annularPath.setAttribute('fill', 'transparent');
    annularPath.setAttribute('stroke', annularDefaultColor!);
    annularPath.setAttribute('stroke-width', lineWidth.toString());

    svg.appendChild(annularPath);

    svg.setAttribute('width', width.toString());
    svg.setAttribute('height', height.toString());
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

    renderer.appendChild(el.nativeElement, svg);
  }

  private drawText(): void {
    const sideLength = Math.min(this.width, this.height);

    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    text.textContent = this.step.toString();
    text.setAttribute('x', '50%');
    text.setAttribute('y', '58%'); // Adjust the vertical position as needed
    text.setAttribute('text-anchor', 'middle');
    text.setAttribute('dominant-baseline', 'middle');
    text.setAttribute('text-align', 'middle');

    const fontSize = sideLength * 0.65; // Adjust the font size as needed
    text.setAttribute('font-size', fontSize.toString());
    text.setAttribute('fill', this.annularDefaultColor!);

    this.annularPath!.style.stroke = this.annularDefaultColor;

    this.svg!.appendChild(text);
  }

  private drawCenterCircleAnimated(): void {
    const sideLength = Math.min(this.width, this.height);
    const circlesRadius =
      sideLength / 2 - this.lineWidth * 2 - sideLength * 0.15; // Adjust the radius as needed

    const { centerCircleLayer } = this;

    centerCircleLayer.setAttribute('cx', '50%');
    centerCircleLayer.setAttribute('cy', '50%');
    centerCircleLayer.setAttribute('r', circlesRadius.toString());

    centerCircleLayer.style.fill = this._isError
      ? this.tintErrorColor
      : this.tintColor;

    this.annularPath!.style.stroke = this.annularDefaultColor;

    this.svg!.appendChild(centerCircleLayer);
    this.animateCenter();
  }

  private animateCenter(): void {
    const { centerCircleLayer } = this;

    if (centerCircleLayer) {
      centerCircleLayer.style.transformOrigin = 'center';
      centerCircleLayer.style.transform = 'scale(0.8)';
      centerCircleLayer.style.transition = 'all 0.5s ease-in-out';

      setTimeout(() => {
        centerCircleLayer.style.transform = 'scale(1)';
      }, 0);
    }
  }

  private createTransformAnimationWithScale(x: number, y: number): Element {
    const animation = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'animateTransform'
    );
    animation.setAttribute('attributeName', 'transform');
    animation.setAttribute('attributeType', 'XML');
    animation.setAttribute('type', 'scale');
    animation.setAttribute('from', AnnularLayerComponent.originalScale);
    animation.setAttribute('to', `scale(${x} ${y})`);
    animation.setAttribute('begin', '0s');
    animation.setAttribute('dur', '0.5s');
    animation.setAttribute('fill', 'freeze');

    return animation;
  }

  private animateFullCircle(): void {
    const { fullCircleLayer } = this;
    if (fullCircleLayer) {
      fullCircleLayer.style.transformOrigin = 'center';
      fullCircleLayer.style.transform = 'scale(0.8)';
      fullCircleLayer.style.transition = 'all 0.5s ease-in-out';

      setTimeout(() => {
        fullCircleLayer.style.transform = 'scale(1)';
      }, 0);
    }
  }

  private drawFullCircleAnimated(): void {
    const sideLength = Math.min(this.width, this.height);
    const circlesRadius = sideLength / 2 - this.lineWidth * 2;
    const color = this._isError ? this.tintErrorColor : this.tintColor;

    const { fullCircleLayer } = this;

    fullCircleLayer.setAttribute('cx', '50%');
    fullCircleLayer.setAttribute('cy', '50%');
    fullCircleLayer.setAttribute('r', circlesRadius.toString());
    fullCircleLayer.setAttribute('stroke', color);
    fullCircleLayer.setAttribute('stroke-width', this.lineWidth.toString());
    fullCircleLayer.style.fill = color;

    this.annularPath!.style.stroke = this.annularDefaultColor;

    this.svg!.appendChild(fullCircleLayer);

    this.animateFullCircle();
  }

  public updateStatus(): void {
    const {
      isInitialized,
      _isFinished,
      _isError,
      annularPath,
      tintErrorColor,
      svg,
      fullCircleLayer,
      centerCircleLayer,
      tintColor,
      _isCurrent,
      annularDefaultColor,
      centerTextLayer,
      displayNumber,
      flagLayer,
    } = this;

    if (!isInitialized) {
      return;
    }
    if (_isFinished) {
      this.drawFullCircleAnimated();
      return;
    }

    if (_isError) {
      annularPath!.style.stroke = tintErrorColor || 'transparent';
      this.drawFullCircleAnimated();
      return;
    }
    this.utility.removeFromSuperLayer(svg, fullCircleLayer);
    this.drawAnnularPath();

    if (displayNumber) {
      this.utility.removeFromSuperLayer(svg, centerCircleLayer);
      annularPath!.style.stroke = _isCurrent
        ? tintColor || 'transparent'
        : annularDefaultColor || 'transparent';
      this.drawText();
      return;
    }
    this.utility.removeFromSuperLayer(svg, centerTextLayer);
    annularPath!.style.stroke = annularDefaultColor || 'transparent';

    if (_isCurrent) {
      this.utility.removeFromSuperLayer(svg, this.flagLayer);
      this.drawCenterCircleAnimated();
      return;
    }
    this.utility.removeFromSuperLayer(svg, flagLayer);
    this.utility.removeFromSuperLayer(svg, centerCircleLayer);
  }
}
