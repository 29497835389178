import { HomeMenuButtonComponent } from './../home-menu-button/home-menu-button.component';
/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { AppCollapsible } from '../../model/app.collapsible';
import { HomeComponent } from '../home/home.component';

@Component({
  selector: 'app-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.css'],
})
export class HomeMenuComponent implements OnInit, AfterViewInit {
  @Output() component = new EventEmitter<string>();

  public view_user_setup: boolean = false;

  constructor(public app: AppRoutingModule, public home: HomeComponent) {}
  ngAfterViewInit(): void {
    this.outnav();
  }

  ngOnInit(): void {}

  public nav() {
    const menu = document.getElementById('menu')! as HTMLDivElement;
    if (menu.style.width != '0px') {
      this.resize(0);
    } else {
      this.resize(220);
    }
  }

  public outnav() {
    let w = window.innerWidth;
    if (w >= 1200) {
      return;
    }
    this.resize(0);
  }

  public menu_resize_big_screen() {
    let w = window.innerWidth;
    if (w > 1200) {
      this.resize(220);
    } else {
      this.resize(0);
    }
  }

  public resize(px: number) {
    this.toggle_resize(px);
    this.menu_resize(px);
  }

  private toggle_resize(px: number) {
    const toggle = document.getElementById('toggle')! as HTMLDivElement;
    if (toggle) toggle.style.left = px + 'px';
  }

  private menu_resize(px: number) {
    const menu = document.getElementById('menu')! as HTMLDivElement;
    if (menu) {
      menu.style.width = px + 'px';
      menu.style.maxWidth = px + 'px';
    }
  }

  public change_menu(menu: string) {
    switch (menu) {
      case 'back':
        if (this.home.path[this.home.path.length - 1] == 'settings') {
          AppCollapsible.width_collapsible('preferences_options', 220, true);
          AppCollapsible.width_collapsible('first_menu', 220, false);
          this.component.emit('home');
        } else {
          this.component.emit('settings');
        }
        break;
      case 'back1':
        AppCollapsible.width_collapsible('battery_details', 220, true);
        AppCollapsible.width_collapsible('device_options', 220, false);
        break;
      case 'back2':
        AppCollapsible.width_collapsible('device_options', 220, true);
        AppCollapsible.width_collapsible('first_menu', 220, false);
        break;
      case 'back3':
        AppCollapsible.width_collapsible('advanced_tools', 220, true);
        AppCollapsible.width_collapsible('battery_details', 220, false);
        break;
      case 'back4':
        AppCollapsible.width_collapsible('admin_area_options', 220, true);
        AppCollapsible.width_collapsible('first_menu', 220, false);
        break;
      case 'settings':
        AppCollapsible.width_collapsible('preferences_options', 220, true);
        AppCollapsible.width_collapsible('first_menu', 220, false);
        break;
      case 'admin_area':
        AppCollapsible.width_collapsible('admin_area_options', 220, true);
        AppCollapsible.width_collapsible('first_menu', 220, false);
        break;
      case 'device_options':
        AppCollapsible.width_collapsible('device_options', 220, true);
        AppCollapsible.width_collapsible('first_menu', 220, false);
        break;
      case 'battery_details':
        AppCollapsible.width_collapsible('battery_details', 220, true);
        AppCollapsible.width_collapsible('device_options', 220, false);
        break;
      case 'advanced_tools':
        AppCollapsible.width_collapsible('advanced_tools', 220, true);
        AppCollapsible.width_collapsible('battery_details', 220, false);
        break;
    }
  }

  public backgroundUser() {
    if (this.home.user_type == 'Enduser') return '#343334';
    else if (this.home.user_type == 'Superuser') return 'rgb(85, 85, 127)';
    else if (this.home.user_type == 'Manufacturer') return 'rgb(170, 0, 0)';
    else return '#343334';
  }
}
