/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Command } from './command';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.css'],
})
export class DashboardMenuComponent implements OnInit {
  public background_color: string = '';
  @Input() mark_color: string = '';
  @Input() mark_type: string = 'back';

  @Input() icon_width: string[] = [];
  @Input() icon_height: string[] = this.icon_width;
  @Input() icon_margin: string[] = [];
  @Input() icon_padding: string[] = [];
  @Input() border_radius: string = '8px';

  @Input() button_active: string = 'single';
  /**
   * true-> one button is always active
   * false -> is possible to have all button unclick
   */
  @Input() least_one_active: boolean = false;

  @Input() view_labels: boolean = false;
  @Input() labels: string[] = [];
  @Input() labels_width: string[] = [];
  @Input() labels_height: string[] = [];

  @Output() response = new EventEmitter<Command>();

  @Input() string_buttons: string[] = [];
  @Input() buttons: boolean[] = [];
  @Input() is_active: boolean[] = [];

  @Input() icons: string[] = [];

  constructor() {}

  ngOnInit(): void {}

  /**
   * Change Output member 'response' when user click img of this html component
   * @param command string returned from html dashboard-menu.component when user (click)
   */
  public click(command: number) {
    if (this.check_least_one_active(command)) {
      return;
    }
    let com = !this.is_active[command];
    this.set_single();
    this.is_active[command] = com;
    this.response.emit(
      new Command(this.string_buttons[command], this.is_active)
    );
  }
  /**
   * Set false all button
   */
  private set_single() {
    if (this.button_active == 'single') {
      for (let i = 0; i < this.is_active.length; i++) {
        this.is_active[i] = false;
      }
    }
  }
  /**
   * If this.least_one_active == true the user want one button ever active
   * @param command
   * @returns True if the button that we click is already clicked, flase otherwise
   */
  private check_least_one_active(command: number) {
    if (this.least_one_active) {
      if (this.is_active[command]) {
        return true;
      }
    }
    return false;
  }
}
