<div class="central_block" *ngIf="this.view_option">
  <div>
    <button class="circle" id="circle">
      <img
        class="icon_close"
        src="../../../assets/icon/close.svg"
        (click)="this.close_set_user(this.home.user_type)"
      />
    </button>
  </div>
  <div class="form">
    <div *ngIf="!this.input_user">
      <table>
        <tr>
          <td colspan="2" class="filter_title">Set user</td>
        </tr>
        <tr>
          <td colspan="2" class="filter_subtitle">
            Select user to enable user's tools.
          </td>
        </tr>
        <tr>
          <td class="data_tool" (click)="this.changeUser('Enduser')">
            <p class="p_title">Enduser</p>
          </td>
        </tr>
        <tr>
          <td class="data_tool" (click)="this.changeUser('Superuser')">
            <p class="p_title">Superuser</p>
          </td>
        </tr>
        <tr>
          <td class="data_tool" (click)="this.changeUser('Manufacturer')">
            <p class="p_title">Manufacturer</p>
          </td>
        </tr>
      </table>
    </div>
    <div *ngIf="this.input_user">
      <table>
        <tr>
          <td>{{ this.nextUser }} password</td>
        </tr>
        <tr>
          <td>
            <input
              class="input"
              type="password"
              id="userpwd"
              autocomplete="new-password"
              aria-autocomplete="none"
            />
          </td>
        </tr>
      </table>
      <div>
        <button class="submit delete" (click)="this.input_user = false">
          CANCEL
        </button>
        <button class="submit" (click)="this.userpwd()">OK</button>
      </div>
    </div>
  </div>
</div>
<app-warning
  [title]="'Set user response'"
  [background_color]="this.warning_background_color"
  [message]="[this.warning_message]"
  [warning]="this.view_warning"
  (response)="this.view_warning = $event"
></app-warning>
