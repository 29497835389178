import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { sha256 } from 'js-sha256';
import { HomeComponent } from 'src/app/home/home.component';
@Component({
  selector: 'app-advanced-user',
  templateUrl: './advanced-user.component.html',
  styleUrls: ['./advanced-user.component.css'],
})
export class AdvancedUserComponent {
  public input_user: boolean = false;
  public nextUser: string = '';
  public superuserpwd =
    '7fe1f17e367403c245eae51193bf3f9c83c2dca87a61c1e25c28a8437dfec779';
  public manufacturerpwd =
    '0001eaf0483335df555ed7e30fa59ea720a7637eb125fe8ba126f523573ba8d3';
  public superuser: boolean = false;
  public manufacturer: boolean = false;

  public view_warning: boolean = false;
  public warning_background_color: string = '';
  public warning_message: string = '';

  @Output() close = new EventEmitter<boolean>();
  @Output() user = new EventEmitter<string>();

  private _view_option: boolean = false;
  @Input() set view_option(value: boolean) {
    this._view_option = value;
    this.input_user = false;
  }
  public get view_option() {
    return this._view_option;
  }

  constructor(public home: HomeComponent) {}

  public changeUser(user: string) {
    this.nextUser = user;
    if (user == 'Enduser') {
      this.user.emit(user);
      this.nextUser = '';
      this.set_user_pwd('Enduser');
    } else {
      this.input_user = true;
    }
  }
  public userpwd() {
    let pwd =
      'archi:' +
      (document.getElementById('userpwd')! as HTMLInputElement).value;
    pwd = sha256(pwd);
    if (this.nextUser == 'Superuser') {
      if (this.superuserpwd == pwd) {
        this.superuser = true;
        this.set_user_pwd('Superuser');
      } else {
        this.set_user_pwd(this.home.user_type);
        this.set_warning('rgba(255, 86, 86, 0.868)', 'Incorrect password.');
      }
    } else if (this.nextUser == 'Manufacturer') {
      if (this.manufacturerpwd == pwd) {
        this.manufacturer = true;
        this.set_user_pwd('Manufacturer');
      } else {
        this.set_user_pwd(this.home.user_type);
        this.set_warning('rgba(255, 86, 86, 0.868)', 'Incorrect password.');
      }
    } else {
      this.set_user_pwd('Enduser');
    }
  }
  private set_user_pwd(user: string) {
    this.user.emit(user);
    this.close_set_user(user);
  }
  private set_warning(color: string, label: string) {
    this.warning_background_color = color;
    this.warning_message = label;
    this.view_warning = true;
  }

  public close_set_user(user: string) {
    this.close.emit(false);
    this.input_user = false;
  }
}
