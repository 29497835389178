/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
export class SwitchType {
  public types: boolean[] = [];

  /**
   *
   * @param num_type number of possible types
   * @param default_true which type is default true (is possible null), value is between 0 to num_type - 1
   */
  constructor(num_type: number, default_true: number | null = null) {
    for (let i = 0; i < num_type; i++) {
      this.types.push(false);
    }
    if (default_true != null) {
      this.types[default_true] = true;
    }
  }
  private remove_types() {
    for (let i = 0; i < this.types.length; i++) this.types[i] = false;
  }

  public change_types(num_type: number | null = null) {
    this.remove_types();
    if (num_type != null) {
      this.types[num_type] = true;
    }
  }
}
