<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div class="block">
  <div>
    <p class="title">Device list</p>
  </div>
  <div id="title_menu">
    <div class="list_message">{{ this.dashboard_message }}</div>
    <app-dashboard-menu
      class="dashboard_menu"
      [mark_color]="'#50a17c'"
      [button_active]="'multi'"
      [string_buttons]="this.menu_string_option"
      [icon_width]="['24px', '25px', '25px', '25px']"
      [icon_height]="['24px', '26px', '26px', '22px']"
      [icon_margin]="[
        '2px 0px 2px 0px',
        '0px 3px 0px 0px',
        '0px 3px 0px 0px',
        '0px 3px 0px 0px'
      ]"
      [icon_padding]="[
        '0px 0px 0px 0px',
        '0px 0px 0px 0px',
        '0px 0px 0px 0px',
        '1px 0px 3px 0px'
      ]"
      [buttons]="this.menu_options"
      [icons]="this.menu_icons"
      [is_active]="this.is_active"
      (response)="this.command($event)"
    ></app-dashboard-menu>
    <div id="search_devices_list">
      <img
        class="search"
        src="../../assets/icon/device-list/device_list_menu/search.svg"
        alt=""
      />
      <input
        id="search_devices_list_input"
        (input)="this.update_map()"
        class="input"
        type="text"
        placeholder="Search for a device"
      />
    </div>

    <app-device-options
      *ngIf="this.view_option"
      [name]="this.view_option_name"
      [sn]="this.view_option_sn"
      [option_type_input]="this.option_type"
      [option]="this.option"
      (close)="this.view_option = $event"
    ></app-device-options>
    <app-add-device
      [view]="this.add_device"
      (close)="this.add_device = $event"
    ></app-add-device>
  </div>
  <div class="fixed_table" id="device_list_fixed_table">
    <div id="devices_list_map" class="vertical_content_collapsed">
      <google-map
        width="100%"
        height="50vh"
        [center]="this.map.center"
        [zoom]="this.map.zoom"
      >
        <map-marker
          *ngFor="let marker of this.map.markers"
          [position]="marker.position"
          [label]="marker.label"
          [title]="marker.title"
          [options]="marker.options"
        >
        </map-marker>
      </google-map>
    </div>
    <table class="list">
      <thead>
        <tr>
          <th class="small_td">Device</th>
          <th>Name</th>
          <th class="sn">SN</th>
          <th class="small_td">Online</th>
          <th class="extra_small_td"></th>
        </tr>
      </thead>
      <tbody id="device_list_body">
        <tr *ngFor="let device of this.devices_list_filter(); let i = index">
          <td
            class="small_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
            (click)="
              this.home.change_component('battery_details');
              this.updateDevice(device);
              this.home.menu.change_menu('battery_details')
            "
          >
            <img
              class="icon"
              src="{{
                this.dev_methods.device_type(device.sn.substring(0, 2))
              }}"
              alt=""
              srcset=""
            />
          </td>
          <td
            class="device_name"
            id="alias"
            (click)="
              this.home.change_component('battery_details');
              this.updateDevice(device);
              this.home.menu.change_menu('battery_details')
            "
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ device.alias }}
          </td>
          <td
            class="sn"
            (click)="
              this.home.change_component('battery_details');
              this.updateDevice(device);
              this.home.menu.change_menu('battery_details')
            "
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ device.sn }}
          </td>
          <td
            class="small_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
            (click)="
              this.home.change_component('battery_details');
              this.updateDevice(device);
              this.home.menu.change_menu('battery_details')
            "
          >
            <img
              class="icon"
              *ngIf="!device.is_online"
              src="../../assets/icon/device-list/offline_red.svg"
              alt=""
              srcset=""
            />
            <img
              class="icon"
              *ngIf="device.is_online"
              src="../../assets/icon/device-list/online_green.svg"
              alt=""
              srcset=""
            />
          </td>
          <td
            class="extra_small_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            <img
              class="open_icon"
              [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
              src="../../assets/icon/arrow/left.svg"
              alt=""
              srcset=""
              (click)="this.get_row_option(device.alias, device.sn)"
            />
          </td>
          <div
            id="{{ device.alias }}_{{ device.sn }}_collapsed"
            class="horizontal_content_collapsed"
          >
            <td style="background: gray">
              <img
                class="icon"
                src="../../assets/icon/device-list/ellipsis.svg"
                alt=""
                srcset=""
                (click)="
                  this.option = this.view_option = true;
                  this.view_option_name = device.alias;
                  this.view_option_sn = device.sn;
                  this.option_type = ''
                "
                style="cursor: pointer"
                [ngStyle]="{
                  'pointer-events': this.view_option ? 'none' : 'all'
                }"
              />
            </td>
            <td style="background: #50a17c">
              <img
                class="icon"
                src="../../assets/icon/device-list/share.svg"
                alt=""
                srcset=""
                (click)="
                  this.view_option = true;
                  this.option = false;
                  this.view_option_name = device.alias;
                  this.view_option_sn = device.sn;
                  this.option_type = 'share'
                "
                style="cursor: pointer"
                [ngStyle]="{
                  'pointer-events': this.view_option ? 'none' : 'all'
                }"
              />
            </td>
            <td style="background: rgb(255, 86, 86)">
              <img
                class="icon"
                src="../../assets/icon/device-list/delete.svg"
                alt=""
                srcset=""
                (click)="
                  this.view_option = true;
                  this.option = false;
                  this.view_option_name = device.alias;
                  this.view_option_sn = device.sn;
                  this.option_type = 'delete'
                "
                style="cursor: pointer"
                [ngStyle]="{
                  'pointer-events': this.view_option ? 'none' : 'all'
                }"
              />
            </td>
          </div>
        </tr>
      </tbody>
    </table>
  </div>
</div>
