<div class="title_console" (window:resize)="this.consoleAvailable()">
  <p>Console</p>
</div>
<div [ngStyle]="{ display: this.console_available ? 'block' : 'none' }">
  <div>
    <div class="console_message" style="top: 75px">Name: {{ this.name }}</div>
    <div class="console_message">Target: {{ this.target }}</div>
    <app-dashboard-menu
      class="console_menu"
      [mark_color]="'#50a17c'"
      [button_active]="'single'"
      [string_buttons]="this.menu_string_option"
      [icon_width]="['25px', '25px']"
      [icon_height]="['25px', '25px']"
      [icon_margin]="['0px 8px 0px 0px', '0px 40px 0px 0px']"
      [icon_padding]="['0px 0px 0px 0px', '0px 0px 0px 0px']"
      [labels]="['Upload', 'Download']"
      [labels_width]="['54px', '90px']"
      [labels_height]="['18px', '18px']"
      [view_labels]="true"
      [buttons]="this.menu_options"
      [icons]="this.menu_icons"
      [is_active]="this.is_active"
      (response)="this.command($event)"
    ></app-dashboard-menu>
  </div>

  <div class="terminal_container">
    <div
      style="width: 930px; height: 400px; min-height: 360px"
      #terminalcontainer
    ></div>
  </div>
  <div class="console_message" style="top: 535px">
    Info: {{ this.xterm!.console_version }}
  </div>
</div>
<div
  *ngIf="!this.console_available"
  class="console_message"
  style="left: 50%; top: 40%; margin-left: -213.5px"
>
  Console is not available when screen width is less than 1200px
</div>
