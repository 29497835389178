/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

export class AppCollapsible {
  constructor() {}

  static height_collapsible(collapsible: string, content: string) {
    var coll = document.getElementById(collapsible);
    if (coll != null) {
      var con = document.getElementById(content);
      if (con != null) {
        if (con.style.maxHeight != '') {
          con.style.maxHeight = '';
        } else {
          con.style.maxHeight = con.scrollHeight + 'px';
        }
      }
    }
  }
  static width_collapsible(
    content: string,
    max_width: number,
    option: boolean,
    v: number = 0.2
  ) {
    var con = document.getElementById(content);
    //start with open div
    if (!option) {
      if (con != null) {
        con.style.transition = 'max-width ' + v + 's ease-out';
        if (con.style.maxWidth != 0 + 'px') {
          con.style.maxWidth = 0 + 'px';
        } else {
          con.style.maxWidth = max_width + 'px';
        }
      }
      return;
    }
    //start with close div
    if (con != null) {
      con.style.transition = 'max-width ' + v + 's ease-out';
      if (con.style.maxWidth != '') {
        con.style.maxWidth = '';
      } else {
        con.style.maxWidth = max_width + 'px';
      }
    }
  }
}
